export default {
  name: "sticky",

  mounted(element, binding) {
    const aside = binding.instance.$refs.aside;
    const stickyOffsetTop = binding.value;
    const elementBottomPoint = element.offsetTop + element.offsetHeight;
    const asideHeight = aside.offsetHeight;

    window.addEventListener("scroll", () => {
      const scrolled = window.scrollY;
      if (element.getBoundingClientRect().top < stickyOffsetTop && scrolled < elementBottomPoint - asideHeight) {
        aside.classList.add("_fixed");
      }
      if (scrolled > elementBottomPoint - asideHeight - stickyOffsetTop) {
        aside.classList.remove("_fixed");
        aside.classList.add("_absolute");
      }
      if (scrolled < elementBottomPoint - asideHeight - stickyOffsetTop) {
        aside.classList.add("_fixed");
        aside.classList.remove("_absolute");
      }
      if (element.getBoundingClientRect().top > stickyOffsetTop) {
        aside.classList.remove("_fixed");
      }
    });
  },
  updated(element, binding) {
    const aside = binding.instance.$refs.aside;
    const stickyOffsetTop = binding.value;
    const elementBottomPoint = element.offsetTop + element.offsetHeight;
    const asideHeight = aside.offsetHeight;

    window.addEventListener("scroll", () => {
      const scrolled = window.scrollY;
      if (element.getBoundingClientRect().top < stickyOffsetTop && scrolled < elementBottomPoint - asideHeight) {
        aside.classList.add("_fixed");
      }
      if (scrolled > elementBottomPoint - asideHeight - stickyOffsetTop) {
        aside.classList.remove("_fixed");
        aside.classList.add("_absolute");
      }
      if (scrolled < elementBottomPoint - asideHeight - stickyOffsetTop) {
        aside.classList.add("_fixed");
        aside.classList.remove("_absolute");
      }
      if (element.getBoundingClientRect().top > stickyOffsetTop) {
        aside.classList.remove("_fixed");
      }
    });
  },
};
