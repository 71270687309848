<template>
  <form
    action="#"
    class="modal-auth__form"
    @submit.prevent="send"
  >
    <div class="modal-auth__form-grid">
      <form-input
        v-model="data.family"
        label="Фамилия"
      />

      <form-input
        v-model="data.name"
        label="Имя"
      />

      <form-input
        v-if="!noSurname"
        v-model="data.surname"
        label="Отчество"
      />

      <form-check
        v-model="noSurname"
      >
        Нет отчества
      </form-check>
      
      <form-datepicker 
        v-model="data.birthday"
        placeholder="Дата рождения"
        flow="birthday"
      />
      <!-- <form-input
        v-model="data.birthday"
        label="Дата рождения"
      /> -->

      <form-input
        v-model="data.phone"
        label="Телефон"
        type="tel"
      />

      <form-input
        v-model="data.email"
        label="Email"
        type="email"
      />

      <form-input
        v-model="data.password"
        label="Пароль"
        type="password"
      />

      <form-check v-model="agree">
        Я согласен на обработку персональных данных и согласен с политикой обработки персональных данных
      </form-check>
    </div>

    <app-button
      :disabled="!agree"
      :sending="loading"
      type="submit"
    >
      Отправить заявку
    </app-button>
  </form>
</template>

<script>
import { reactive, ref } from 'vue';
import { useNotification } from '@kyvg/vue3-notification';

import api from '@/plugins/api';

import FormInput from '@/components/form/FormInput.vue';
import FormDatepicker from '@/components/form/FormDatepicker.vue';
import FormCheck from '@/components/form/FormCheck.vue';
import AppButton from '@/components/ui/AppButton.vue';

export default {
  components: {
    FormInput,
    FormDatepicker,
    FormCheck,
    AppButton,
  },
  emits: ['go-to'],
  setup(props, { emit }) {
    const loading = ref(false);
    const { notify } = useNotification();

    const data = reactive({
      name: '',
      family: '',
      surname: '',
      birthday: '',
      phone: '',
      email: '',
      password: '',
    });

    const noSurname = ref(false);
    const agree = ref(false);

    const send = () => {
      loading.value = true;

      if (noSurname.value) {
        data.surname = '';
      }

      api.post('/api/clientRegister', data).then(({data}) => {
        if (data.errors) {
          notify({
            title: 'Ошибка!',
            text: 'Не заполнены обязательные поля',
            type: 'error',
          });
        } else {
          notify({
            title: 'Успех!',
            text: 'Регистрация прошла успешно. Пожалуйста, авторизуйтесь',
            type: 'success',
          });

          emit('go-to', 'auth');
        }
      }).finally(() => {
        loading.value = false;
      });
    };

    return { loading, data, noSurname, agree, send };
  },
};
</script>
