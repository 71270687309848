<template>
  <section>
    <div
      id="mapBlock"
      class="shadow map-detail"
      style="width: 100%"
    >
      <div class="map-detail__actions" />
      <div
        v-if="isLaptop"
        class="map-detail__backdrop"
        :class="{ _show: showMapMobile && curCard }"
        @click="clickOnMap"
      >
        <div class="map-detail__estate-card-close">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <EstateCard
        v-if="curCard"
        :key="estateCardKey"
        class="map-detail__estate-card"
        :class="{ '_is-showing': showMapMobile, 'map-detail__estate-card--mobile': isLaptop }"
        :is-small="true"
        :is-mobile="isLaptop"
        :map="true"
        :card="curCard"
        :show-mobile-map="showMobileMap"
        :from-map="true"
      />
    </div>
  </section>
</template>

<script>
import { loadYmap } from "vue-yandex-maps";
import settings from "@/plugins/yandex.js";
import EstateCard from "@/components/EstateCard.vue";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { mapActions, mapState } from "pinia";

/* eslint-disable*/
export default {
  name: "MapMainFilterYandex",
  components: {
    EstateCard
  },
  props: {
    showMapMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cards: [],
      estateCardKey: 0,
      curCard: null,
      showObjects: false,
      mapSettings: {
        zoom: 12
      },
      clusterPlacemarks: [],

      isLaptop: false
    };
  },
  computed: {
    ...mapState(useFilterStore, ["point", "pointZoom", "backToTheMap"])
  },
  methods: {
    ...mapActions(useFilterStore, ["load", "saveCardPoint", "setToLastPoint", "setFalseBackToTheMap"]),
    clustererFactory() {
      const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
        `<div class="clusterPoints">{{ properties.geoObjects.length }}</div>`
      );

      const clusterIcons = [
        {
          size: [40, 40],
          offset: [-20, -20]
        },
        {
          size: [60, 60],
          offset: [-30, -30],
          shape: {
            type: "Circle",
            coordinates: [0, 0],
            radius: 30
          }
        }
      ];
      const clusterNumbers = [100];

      const clusterer = new ymaps.Clusterer({
        clusterDisableClickZoom: true,
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
        clusterIconContentLayout: MyIconContentLayout,
        clusterIcons: clusterIcons,
        clusterNumbers: clusterNumbers,
        minClusterSize: 2
      });

      clusterer.options.set({
        hasBalloon: false,
        hasHint: true,
        hintContent: "hintContent va",
        gridSize: 80,
        clusterDisableClickZoom: true
      });

      const refreshVisibleList = (e) => {
        const activePlacemark = e.get("target");
        const clusterObjects = activePlacemark.properties.get("geoObjects");
        if (clusterObjects) {
          if (this.$root.map._zoom > settings.zoomAfterClickCluster) {
            this.openBalloon(activePlacemark, clusterObjects);
          } else {
            this.clustererClick(e);
          }
        } else {
          this.handleClick(activePlacemark);
        }
      };
      const onClusterEvent = (e) => {
        refreshVisibleList(e);
      };

      clusterer.events.add("click", onClusterEvent);
      return clusterer;
    },
    clustererClick(e) {
      this.$root.map.setCenter(
        e._sourceEvent.originalEvent.originalEvent.originalEvent.target._data.geoObject.geometry._coordinates
      );
      const currentZoom = this.$root.map._zoom;
      if (currentZoom <= settings.zoomAfterClickCluster) {
        this.$root.map.setZoom(currentZoom + 3);
      }
    },
    placemarkFactory(card) {
      if (card.location.latitude && card.location.longitude) {
        let squareLayout = ymaps.templateLayoutFactory.createClass("<div class=\"map-point\"></div>");
        let myPlacemark = new ymaps.Placemark(
          [Number(card.location.latitude), Number(card.location.longitude)],
          {
            hintContent: card.title
          },
          {
            iconLayout: squareLayout,
            iconShape: {
              type: "Rectangle",
              coordinates: [
                [-25, -25],
                [25, 25]
              ]
            }
          }
        );
        return myPlacemark;
      }
    },
    setMapCenterByFirstClusterPoint(clusterer) {
      this.$root.map.setCenter(clusterer._objects[Object.keys(clusterer._objects)[0]].geoObject.geometry._coordinates);
      this.$root.map.setZoom(16);
    },
    async toggleObjects() {
      if (this.$root.map) {
        this.$root.map.geoObjects.removeAll();
      }
      const objects = await this.load(this.$route, "FOR_MAP");
      if (objects.data.data.length !== 0) {
        this.cards = objects.data.data;
        const arrPlacemarks = objects.data.data.map((card) => this.placemarkFactory(card)).filter((card) => card);
        const clusterer = this.clustererFactory();
        clusterer.add(arrPlacemarks);
        if (clusterer._objectsCounter === 1) {
          this.setMapCenterByFirstClusterPoint(clusterer);
        } else if (clusterer._objectsCounter > 1) {
          const bounds = clusterer.getBounds();
          const dif1 = bounds[1][0] - bounds[0][0];
          const dif2 = bounds[1][1] - bounds[0][1];
          const diffLimit = 0.04;
          if ((dif1 < diffLimit) || (dif2 < diffLimit)) {
            this.setMapCenterByFirstClusterPoint(clusterer);
          } else {
            this.$root.map.setBounds(bounds);
          }
        }
        this.$root.map.geoObjects.add(clusterer);
        this.setToLastPoint();
      }
    },
    setToLastPoint() {
      if (this.backToTheMap) {

        this.$root.map.setCenter(this.point);
        this.$root.map.setZoom(this.pointZoom);
        this.setFalseBackToTheMap();
      }
    },
    hasDuplicates(arr) {
      return new Set(arr).size !== arr.length;
    },

    handleClick(e) {
      const currentIndex = this.cards.findIndex(
        (el) =>
          Number(el.location.latitude) === e.geometry._coordinates[0] &&
          Number(el.location.longitude) === e.geometry._coordinates[1] &&
          el.title === e.properties._data.hintContent
      );
      this.curCard = this.cards[currentIndex];
      this.estateCardKey += 1;
      this.$emit("changeCurCard");

      this.saveCardPoint([e.geometry._coordinates[0], e.geometry._coordinates[1]], this.$root.map._zoom);
    },
    clickOnMap() {

      this.setFalseBackToTheMap();
      this.$emit("changeCurCard");
      this.curCard = null;
      this.$root.map.balloon.close();
    },
    init() {
      let zoom = new ymaps.control.ZoomControl({
        options: {
          position: {
            right: 20,
            top: 200
          }
        }
      });
      let myMap = new ymaps.Map("mapBlock", {
        center: [60.04024, 30.215571],
        zoom: this.mapSettings.zoom,
        controls: [zoom]
      });
      myMap.events.add("click", this.clickOnMap);
      this.$root.map = myMap;

      if (!this.backToTheMap) {
        this.toggleObjects();
      }
      this.emitter.on("showCard", this.showCard);
    },
    showCard(e) {

      const currentIndex = this.cards.findIndex(
        (el) =>
          Number(el.location.latitude) === Number(e.children[0].innerText) &&
          Number(el.location.longitude) === Number(e.children[1].innerText) &&
          el.title === e.innerText
      );
      this.curCard = this.cards[currentIndex];
      this.estateCardKey += 1;
      this.saveCardPoint(
        [
          Number(e.children[0].innerText),
          Number(e.children[1].innerText)
        ],
        this.$root.map._zoom
      );
    },
    openBalloon(activePlacemark, points) {
      let html = points
        .map((el) => {
          return (
            "<div class='cardList__item' onclick='window.emitter.emit(`showCard`,this)'>" +
            el.properties._data.hintContent +
            `<div class='coord_hide'>${el.geometry._coordinates[0]}</div>` +
            `<div class='coord_hide'>${el.geometry._coordinates[1]}</div>` +
            "</div>"
          );
        })
        .join("");
      this.$root.map.balloon.open(activePlacemark.geometry._coordinates, html);
    }
  },
  async mounted() {
    await loadYmap({ ...settings, debug: true });
    ymaps.ready(this.init);
    const mediaQuery = window.matchMedia("(max-width: 639px)");

    if (mediaQuery.matches) {
      this.isLaptop = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.map-detail {
  $that: &;
  z-index: 1;
  position: relative;
  height: 732px;
  overflow: hidden;
  box-sizing: border-box;

  @media (max-width: 1280px) {
    height: 100% !important;
  }

  &__estate-card {
    z-index: 9999;
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
    /*  pointer-events: none;*/
    position: absolute;
    top: 0;
    right: 0;
    width: 336px;
    min-height: 482px;
    height: auto;

    &._is-showing {
      opacity: 1;
      pointer-events: all;
    }

    @include laptop {
      /*     top: 38px;*/
    }

    &--mobile {
      @include phone {
        width: 100%;
        top: auto;
        bottom: 0;
        transform: translate3d(0, 100%, 0);
        transition: opacity 0.25s 0.1s, transform 0.3s ease-out;

        &._is-showing {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__backdrop {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.411);

    opacity: 0;
    visibility: hidden;

    &._show {
      opacity: 1;
      visibility: visible;
    }

    & .map-detail__estate-card-close {
      position: absolute;
      right: 22px;
      top: 30px;
      width: 12px;
      height: 12px;
    }
  }

  &__info {
    margin-top: 10px;
  }

  &__info-mobile {
    position: absolute;
    bottom: 18px;
  }
}

section {
  @media (max-width: 1280px) {
    height: 100%;
  }
}
</style>
