<template>
  <section class="news-section">
    <div class="news-section__inner">
      <app-title class="news-section__title">
        Новости
      </app-title>
      <swiper
        v-if="swiperInit"
        class="news-section__swiper"
        :modules="modules"
        :autoplay="true"
        :speed="800"
        :slides-per-view="1"
        :pagination="{
          el: '._pagination',
          clickable: true,
        }"
        effect="creative"
        :creative-effect="{
          prev: {
            opacity: [0],
          },
          next: {
            opacity: [0],
          },
        }"
      >
        <swiper-slide
          v-for="(newsItem, index) in newsList"
          :key="index"
        >
          <NewsCard :news="newsItem" />
        </swiper-slide>
        <div class="swiper-pagination _pagination" />
      </swiper>
      <NewsList
        v-else
        :news-list="newsList"
      />
      <div class="news-section__link">
        <AppButton
          to="/news"
          :secondary="true"
        >
          Все новости
        </AppButton>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, EffectCreative, Autoplay } from "swiper";
import AppButton from "@/components/ui/AppButton.vue";
import NewsList from "@/components/NewsList.vue";
import NewsCard from "@/components/NewsCard.vue";

export default {
  name: "NewsSection",
  components: {
    Swiper,
    SwiperSlide,
    AppButton,
    NewsList,
    NewsCard,
  },

  setup() {
    const newsList = ref([]);

    onMounted(async () => {
      axios
        .get("/api/news", {
          params: {
            limit: 4,
          },
        })
        .then(({ data }) => {
          newsList.value = data.data;
        });
    });

    return {
      newsList,
      modules: [Pagination, Autoplay, EffectCreative],
    };
  },

  data() {
    return {
      swiperInit: false,
    };
  },

  mounted() {
    this.onSwiperInit();
  },

  methods: {
    onSwiperInit() {
      const media = window.matchMedia("(max-width:639px)");
      const listener = (event) => (this.swiperInit = event.matches);
      listener(media);

      media.addEventListener("change", listener);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-section {
  padding-top: 64px;
  padding-bottom: 128px;
  background-color: $color_grey-2;
  overflow: hidden;

  @include laptop {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  &__inner {
    @extend %container;
  }

  &__title {
    margin-bottom: 43px;

    @include laptop {
      margin-bottom: 20px;
    }

    @include tablet {
      &:after {
        display: none !important;
      }
    }
  }

  &__swiper {
    .swiper-pagination {
      margin-top: 22px;

      @include flex-center;
    }
  }

  &__link {
    margin-top: 45px;

    @include flex-center;

    @include tablet {
      margin-top: 30px;
    }
  }
}
</style>
