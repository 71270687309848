<template>
  <section
    ref="hero"
    class="hero"
    :class="classes"
  >
    <div class="hero__inner">
      <h1
        v-if="h1Seo || heroTitle"
        ref="heroTitle"
        class="hero__title"
      >
        {{ h1Seo !== null ? h1Seo : heroTitle }}
      </h1>
      <AppBreadcrumbs
        v-if="!mainPage"
        :crumbs="crumbs"
      />
      <ul
        v-if="mainPage"
        class="hero__buttons-list"
      >
        <li
          v-for="(item, index) in buttonsList"
          :key="index"
          class="hero__buttons-list-item"
        >
          <TransitionFade>
            <ButtonHero
              v-if="item.link"
              :to="item.link"
            >
              {{ item.name }}
            </ButtonHero>
          </TransitionFade>
        </li>
      </ul>
    </div>
    <div class="hero__background-image">
      <img
        v-if="mainPage && heroImage"
        ref="heroImage"
        :src="heroImage"
        alt=""
      >
      <img
        v-else-if="!mainPage && heroImage"
        ref="heroImage"
        :src="heroImage"
        alt=""
      >
      <img
        v-else
        ref="heroImage"
        :src="heroImage"
        alt=""
      >
    </div>
  </section>
</template>

<script>
import ButtonHero from "@/components/ui/ButtonHero.vue";
import AppBreadcrumbs from "@/components/ui/AppBreadcrumbs.vue";
import { computed, reactive, inject } from "vue";

// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
import TransitionFade from "@/components/transitions/TransitionFade.vue";

// gsap.registerPlugin(ScrollTrigger);

export default {
  name: "TheHero",
  components: { ButtonHero, AppBreadcrumbs, TransitionFade },

  props: {
    crumbs: {
      type: Array,
      default: () => [],
    },
    mainPage: {
      type: Boolean,
      default: false,
    },
    heroTitle: {
      type: String,
      default: null,
    },
    heroImage: {
      type: String,
      default: () => "",
    },
    buttonsList: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const h1Seo = inject("h1Seo", null);

    props = reactive(props);

    return {
      classes: computed(() => ({
        "_is-main": props.mainPage,
      })),
      h1Seo,
    };
  },

  mounted() {
    // this.heroAnimation();
  },

  methods: {
    /*     heroAnimation() {
          const mediaQuery = window.matchMedia("max-width: 1023px");
          const element = this.$refs.heroImage;

          if (!element || mediaQuery.matches) return;

          const trigger = this.$refs.hero;

          const title = this.$refs.heroTitle;

          const tl = gsap.timeline({
            ease: "sine",
            scrollTrigger: {
              trigger: trigger,
              start: "top top",
              end: "bottom top",
              scrub: true,
              force3D: true,
              delay: 0.4,
              onStart: () => {
                ScrollTrigger.refresh();
              },
              onToggle: (self) => {
                if (self.isActive) {
                  element.classList.add("will-change");
                } else {
                  element.classList.remove("will-change");
                }
              },
            },
          });

          tl.from(element, {
            scale: 1.12,
          });

          gsap.from(title, {
            autoAlpha: 0,
            delay: 0.3,
            duration: 0.5,
          });
        }, */
  },
};
</script>
<style lang="scss" scoped>
.hero {
  $that: &;

  position: relative;

  .breadcrumbs {
    align-self: flex-start;
  }

  &__inner {
    @extend %container;
    @include flex-center;

    flex-direction: column-reverse;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    min-height: 428px;
    padding-top: 156px;
    padding-bottom: 56px;
    flex: none;

    @include laptop {
      padding-top: 126px;
    }

    @include tablet {
      min-height: calc(428 / 1023 * 100vw);
      padding-top: calc(186 / 1023 * 100vw);
      padding-top: 94px;
      padding-bottom: 76px;
    }

    @include phone {
      align-items: flex-start;
      justify-content: flex-end;
      min-height: 216px;
    }

    ._is-main & {
      min-height: calc(742 / 1920 * 100vw);
      padding-top: calc(258 / 1920 * 100vw);
      padding-bottom: 56px;
      flex-direction: column;
      justify-content: flex-start;

      @media (min-width: 1920px) {
        min-height: 742px;
        padding-top: 258px;
      }

      @include laptop {
        min-height: 494px;
        padding-top: 176px;
      }

      @include phone {
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding-top: 126px;
        padding-bottom: 40px;
      }
    }
  }

  &__background-image {
    position: relative;
    overflow: hidden;

    @include absolute-fill;

    img {
      width: 100%;
      height: 100%;

      @include object-fit(cover, center);
      // @include lazy-loaded;
    }

    &::after {
      content: "";

      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 39.27%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

      @include absolute-fill;
    }
  }

  &__title {
    color: $white;
    text-align: center;
    line-height: 72px;
    max-width: 1040px;
    font-weight: 400;
    font-size: 50px;

    ._is-main & {
      font-weight: 700;
      font-size: 64px;
      line-height: 72px;

      @include laptop {
        font-size: 40px;
        line-height: 48px;
      }

      @include phone {
        font-size: 31px;
        line-height: 36px;
        font-weight: 700;
        max-width: 284px;
      }
    }

    @include laptop {
      font-size: 40px;
      line-height: 48px;
    }

    @include phone {
      font-weight: 400;
      font-size: 26px;
      line-height: 28px;
      text-align: left;
    }
  }

  &__buttons-list {
    padding-top: 12px;
    flex-wrap: wrap;
    width: 760px;

    @include flex-center;

    @include laptop {
      width: 590px;
      padding-top: 6px;
    }

    @include phone {
      width: 300px;
      padding-top: 0;
    }
  }

  &__buttons-list-item {
    @include flex-center;

    flex: 0 0 25%;
    padding-top: 16px;

    @include phone {
      flex: 0 0 50%;
      padding-top: 32px;
    }

    .button-hero {
      margin: 0 8px;
    }
  }
}
</style>
