const settings = {
  apiKey: "2d296d07-bc2c-440f-ae05-2118d2c76a53",
  lang: "ru_RU",
  coordorder: "latlong",
  enterprise: false,
  version: "2.1",
  zoomAfterClickCluster: 16,
};

export default settings;
