<template>
  <div
    :id="`room-${card.id}`"
    ref="estateCard"
    class="estate-card"
    :class="[classes, { '_is-small': allCardsAreSmall }]"
  >
    <AppLabel v-if="card.deal_status && card.deal_status.title === 'Переуступка'" class="estate-card__label" />
    <EstateCardSlider
      :key="random"
      :random="random"
      :image-list="card.images"
      class="estate-card__slider"
      @click="estateShow(card.category.id, card.alias)"
    />
    <div class="estate-card__info">
      <div v-if="card.location.latitude" v-show="!isSmall" class="estate-card__hover-buttons">
        <ButtonLocation @click="openMap = true" />
        <teleport v-if="openMap" to="body">
          <AppModal v-model="openMap">
            <ModalMap :coords="[+card.location.latitude, +card.location.longitude]" :title="card.title" />
          </AppModal>
        </teleport>
        <ButtonLike v-if="card.is_zhk !== 1" :alias="card.alias" :is-liked="isLiked" @like-card="handleLike()" />
      </div>
      <router-link
        v-if="!overseas"
        ref="cardLink"
        v-clamp="2"
        class="estate-card__info-name"
        :class="{ _mobile: isMobile }"
        :to="routeObject"
        @click="toCard(card.id)"
      >
        {{ card.title }}
      </router-link>
      <span v-else class="estate-card__info-name" :class="{ _mobile: isMobile }">
        {{ card.title }}
      </span>
      <div class="estate-card__info-address">
        <span v-if="card.location" v-clamp="2">{{
          setFullAddress(card.location.region, card.location["sub-locality-name"], card.location.address)
        }}</span>

        <ButtonLike
          v-if="card.is_zhk !== 1"
          v-show="isSmall"
          :alias="card.alias"
          :is-liked="isLiked"
          class="estate-card__like-button"
          @like-card="handleLike()"
        />
        <CancelDeleteCard :is-show="isShow" :reference="$refs.estateCard" @return-like="returnLike" />
      </div>
      <SubwayList
        v-if="!isSmall"
        v-show="card.location.metro"
        :subway-list="card.location.metro"
        class="estate-card__subway-list"
      />
      <div v-if="!isSmall" class="estate-card__description">
        <div v-html="card.description" v-clamp="2"/>
      </div>
      <router-link
        v-if="!isSmall"
        ref="cardLink"
        class="estate-card__go"
        :to="routeObject"
      >
        Читать далее
      </router-link>
    </div>

    <div class="estate-card__price-block">
      <div ref="cardPrice" class="estate-card__price">
        <template v-if="card.price">
          <span v-if="card.currency === 'USD'" v-currency:usd="card.price" />
          <span v-else v-currency="card.price" />
        </template>
      </div>

      <EstateActions
        v-if="!map"
        class="estate-card__actions"
        :button-exchange="card.type === 'Продажа' ? true : false"
      />

      <AppButton
        v-if="map"
        ref="cardLink"
        class="estate-card__go-button"
        :class="{ _fill: isMobile }"
        :to="{
          name: setRoute(card.category.id),
          params: { alias: card.alias },
        }"
        :secondary="true"
        @click="showMobileMap"
      >
        Подробнее
      </AppButton>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, reactive, ref, provide } from "vue";
import EstateActions from "./EstateActions.vue";
import EstateCardSlider from "./EstateCardSlider.vue";
import SubwayList from "./ui/SubwayList.vue";
import ButtonLike from "./ui/ButtonLike.vue";
import ButtonLocation from "./ui/ButtonLocation.vue";
import AppLabel from "./ui/AppLabel.vue";
import AppButton from "./ui/AppButton.vue";
import { useFavoritesStore } from "@/store/store.js";
import CancelDeleteCard from "@/components/CancelDeleteCard.vue";
import AppModal from "./ui/AppModal.vue";
import ModalMap from "./ModalMap.vue";
import { mapActions } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";

export default {
  name: "EstateCard",
  components: {
    CancelDeleteCard,
    EstateActions,
    EstateCardSlider,
    SubwayList,
    ButtonLike,
    ButtonLocation,
    AppLabel,
    AppButton,
    AppModal,
    ModalMap,
  },

  provide() {
    return {
      cardLink: computed(() => this.cardLink),
    };
  },

  props: {
    isSmall: {
      type: Boolean,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    map: {
      type: Boolean,
      default: null,
    },
    card: {
      type: Object,
      default: null,
    },
    doUpdate: {
      type: Boolean,
      default: true,
    },
    overseas: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const route = useRoute();
    const store = useFavoritesStore();
    props = reactive(props);
    const isShow = ref(false);
    provide("isShowCancelModal", isShow);
    const isLiked = computed(() => store.getIsLiked(props.card.alias));

    function handleLike() {
      if (route.path === "/favorites" && isLiked) {
        isShow.value = true;
        store.addFavorites(props.card.alias);
      } else {
        store.addFavorites(props.card.alias);
      }
    }

    function returnLike() {
      handleLike();
      isShow.value = false;
    }

    return {
      classes: computed(() => ({
        "_is-small": props.isSmall,
      })),
      handleLike,
      isLiked,
      isShow,
      returnLike,
    };
  },

  data() {
    return {
      allCardsAreSmall: false,
      isHoverOn: false,
      random: 1,
      openMap: false,
      formPage: null,
    };
  },
  mounted() {
    this.goSmall();
    this.emitter.on("updateSlider", this.updateSlider);
    this.$nextTick(() => {
      this.random = Math.random();
    });
    this.cardLink = this.$refs.cardLink.$el.href;
  },
  beforeUnmount() {
    this.emitter.off("updateSlider", this.updateSlider);
  },
  updated() {},
  methods: {
    ...mapActions(useFilterStore, ["saveScrollForEstateList"]),
    toCard(id) {
      this.saveScrollForEstateList(id);
    },
    updateSlider() {
      if (this.doUpdate) {
        this.random = Math.random();
      }
    },
    goSmall() {
      const media = window.matchMedia("(max-width:1023px)");
      const listener = (event) => (this.allCardsAreSmall = event.matches);
      listener(media);
      media.addEventListener("change", listener);
    },

    setRoute(id) {
      let name;
      switch (true) {
        case id == 1 || id == 3 || id == 4 || id == 5:
          name = "Детальная загородной";
          break;
        case id == 7 || id == 8:
          name = "Детальная вторички";
          break;
        case id == 12:
          name = "Детальная коммерческой";
          break;
        case id == 99:
          name = 'Страница помещения заграничной недвижимости';
          break;
        default:
          break;
      }
      return name;
    },

    setFullAddress(region, subLocalityName, address) {
      let fullAddress = "";
      const arrayAddress = [];

      arrayAddress.push(region, subLocalityName, address);

      arrayAddress.forEach((item, i) => {
        if (item && i !== arrayAddress.length - 1) {
          fullAddress = `${fullAddress} ${item}, `;
        }
        if (item && i === arrayAddress.length - 1) {
          fullAddress = fullAddress + item;
        }
      });

      return fullAddress;
    },
    estateShow(id, alias) {
      if (!this.allCardsAreSmall) {
        this.$router.push({
          name: this.setRoute(id),
          params: {
            alias: alias,
          },
        });
      } else {
        return;
      }
    },
  },
  computed: {
    routeObject() {
      return !this.overseas ? {
        name: this.setRoute(this.card.category.id),
        params: { alias: this.card.alias },
      } : {
        name: this.setRoute(this.card.category.id),
        params: {
          alias: 'georgia',
          id: this.card.alias,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/* С таблета делаем две колонки у лонга */
.estate-card {
  min-height: 281px;
  $that: &;
  background-color: $white;
  height: 100%;
  position: relative;
  text-align: left;
  display: flex;
  border: 1px solid #dcdfe1;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;

  &._is-small {
    flex-direction: column;
  }

  @include tablet {
    display: block;
  }

  &:hover {
    box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  &__label {
    position: absolute;
    top: 20px;
    left: 20px;

    @include laptop {
      top: 14px;
      left: 14px;
    }
  }

  &__slider {
    flex: none;
    width: calc(452 / 1920 * 100vw);
    min-width: 292px;
    cursor: pointer;

    @media (min-width: 1920px) {
      width: 452px;
    }

    @include tablet {
      min-height: 212px;
      min-width: 100%;
    }

    ._is-small & {
      width: 100%;
      min-width: 100%;
      min-height: auto;
    }
  }

  &__info {
    position: relative;
    flex: 1 1 0%;
    padding: 40px calc(132 / 1920 * 100vw) 32px 40px;

    ._is-small & {
      padding: 16px 20px;

      @include laptop {
        padding: 14px 16px 0;
      }
    }

    .map & {
      flex: none;
    }

    .mobile-filters & {
      padding: 30px 16px 0;

      &::before {
        content: "";
        width: 30px;
        height: 4px;
        border-radius: 2px;
        background-color: $color_grey-15;
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }

    @media (min-width: 1920px) {
      padding: 40px 132px 32px 40px;
    }

    @include desktop {
      padding: calc(20 / 1535 * 100vw) calc(132 / 1920 * 100vw) 32px calc(20 / 1535 * 100vw);
    }

    @include laptop {
      padding: 16px;
    }
  }

  &__info-name {
    overflow: hidden;
    color: $color_dark-90;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 16px;
    padding-right: 88px;
    transition: color 0.25s ease-out;
    display: inline-block;

    @include laptop {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    @include hover {
      color: $color_primary;
    }

    &._mobile {
      height: auto !important;
    }

    ._is-small & {
      height: calc(26px * 2 + 6px);
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      padding-bottom: 6px;
      border-bottom: 1px solid #d9d9d9;

      @include laptop {
        font-size: 18px;
      }
    }
  }

  &__info-address {
    position: relative;
    color: $color_dark-50;
    font-size: 16px;
    line-height: 24px;

    @include laptop {
      font-size: 14px;
      line-height: 18px;
    }

    ._is-small & {
      color: $color_dark-90;
      padding-top: 8px;
      font-size: 14px;
      line-height: 18px;
      padding-right: 58px;
    }
  }

  &__subway-list {
    padding-top: 18px;

    @include laptop {
      padding-top: 8px;
    }

    @include tablet {
      display: none;
    }
  }

  &__description {
    line-height: 24px;
    padding-top: 16px;

    > div {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include laptop {
      font-size: 14px;
      line-height: 18px;
      padding-top: 10px;
    }

    @include tablet {
      display: none !important;
    }
  }

  &__go {
    padding-top: 16px;
    display: block;
    color: $color_primary;

    @include laptop {
      font-size: 14px;
      line-height: 18px;
      padding-top: 3px;
    }

    @include tablet {
      display: none !important;
    }
  }

  &__go-button {
    width: 100%;
    margin-top: 24px;

    &._fill {
      background: #f4691b;
      color: #fff;
    }
  }

  &__price-block {
    width: calc(318 / 1920 * 100vw);
    min-width: 246px;
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #d9d9d9;

    @media (min-width: 1920px) {
      width: 318px;
    }

    @include desktop {
      padding: calc(20 / 1535 * 100vw);
    }

    @include laptop {
      padding: 16px;
    }

    @include tablet {
      min-width: 100%;
      width: 100%;
      border-left: 0;
    }

    ._is-small & {
      display: block;
      width: 100%;
      padding: 16px 20px;
      border-left: none;

      @include laptop {
        padding: 14px 16px;
      }
    }
  }

  &__price {
    transition: opacity 0.25s 0.2s ease;
    text-align: right;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    @include laptop {
      font-size: 20px;
      line-height: 26px;
    }

    ._is-small & {
      text-align: left;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;

      @include laptop {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  &__actions {
    padding-top: 8px;

    ._is-small & {
      position: static;
      overflow: hidden;
      height: auto;
      opacity: 1;
      pointer-events: all;
      position: static;
      pointer-events: none;
      transition: max-height 0.3s 0.1s ease-out, opacity 0.25s 0.1s ease;
      box-shadow: 10px 70px 100px rgba(0, 0, 0, 0.1);

      @media (hover: hover) {
        padding: 0 20px 20px;
        border: 1px solid $white;
        z-index: 100;
        position: absolute;
        width: calc(100% + 2px);
        left: -1px;
        bottom: 0;
        transform: translate3d(0, 100%, 0);
        transform-origin: top;
        background-color: $white;
        height: 0;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
      }

      @include laptop {
        height: 100%;
        max-height: 100%;
        opacity: 1;
        pointer-events: all;

        @media (hover: hover) {
          height: auto;
          max-height: 0;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    #{$that}._is-small:hover & {
      height: auto;
      max-height: 156px;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__like-button {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 7px;
    padding-right: 16px;
    opacity: 0;
    transition: opacity 0.3s 0.15s ease;
    pointer-events: none;

    #{$that}:hover & {
      opacity: 1;
      pointer-events: all;
    }

    &._is-liked {
      opacity: 1;
      pointer-events: all;
    }

    @include laptop {
      opacity: 1;
      pointer-events: all;

      @media (hover: hover) {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &__hover-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 40px;
    padding-right: 32px;

    * {
      flex: none;
      transform: translate3d(-16px, 0, 0);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.25s ease, transform 0.3s ease-out;

      &:first-child {
        @include tablet {
          display: none;
        }
      }

      &._is-liked {
        opacity: 1;
        pointer-events: all;
        transform: translate3d(0, 0, 0);
      }

      @include laptop {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        pointer-events: all;
      }
    }

    * + * {
      margin-left: 20px;
    }

    @for $i from 0 through 10 {
      *:nth-child(#{$i + 1}) {
        transition-delay: 0.14s * $i;
      }
    }

    #{$that}:hover & {
      * {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        pointer-events: all;
      }
    }

    &._mobile {
      padding: 0;
      padding-bottom: 8px;
      display: flex;
      justify-content: flex-end;
      position: relative;

      @media (min-width: 1280px) {
        display: none;
      }

      @include tablet {
        display: none;
      }
    }

    @include desktop {
      padding-top: calc(20 / 1535 * 100vw);
      padding-right: calc(20 / 1535 * 100vw);
    }

    @include laptop {
      display: none;
    }

    @include tablet {
      display: flex;
      padding-top: 16px;
      padding-right: 16px;
    }
  }
}
</style>
