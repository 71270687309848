<template>
  <section class="new-estate">
    <div class="new-estate__inner">
      <div class="new-estate__header">
        <app-title class="new-estate__title">
          Новостройки
        </app-title>
        <div class="new-estate__nav-swiper">
          <app-svg
            id="arrow-left"
            class="_prev"
            width="15"
            height="31"
          />
          <app-svg
            id="arrow-right"
            class="_next"
            width="15"
            height="31"
          />
        </div>
      </div>

      <div
        v-if="!newEstateList.length"
        class="new-estate__loader"
      >
        <LoaderImage class="new-estate__loader-item" />
        <LoaderImage class="new-estate__loader-item" />
        <LoaderImage class="new-estate__loader-item" />
        <LoaderImage class="new-estate__loader-item" />
      </div>

      <swiper
        class="new-estate__swiper"
        :breakpoints="{
          320: { slidesPerView: 1, centerdSlides: true },
          640: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
          1280: { slidesPerView: 4 },
        }"
        :pagination="{
          el: '.new-estate__pagination',
          dynamicBullets: true,
        }"
        :speed="1000"
        :modules="modules"
        :navigation="{ nextEl: '._next', prevEl: '._prev' }"
      >
        <swiper-slide
          v-for="(item, index) in newEstateList"
          :key="index"
          class="new-estate__slide"
        >
          <EstateNewCard
            :is-small="true"
            :card="item"
            do-update="doUpdate"
          />
        </swiper-slide>
      </swiper>

      <div class="new-estate__pagination _pagination" />

      <AppButton
        class="new-estate__action"
        to="/new-estate"
        :secondary="true"
      >
        Все новостройки
      </AppButton>
    </div>
  </section>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import AppButton from "@/components/ui/AppButton.vue";
import EstateNewCard from "./EstateNewCard.vue";
import LoaderImage from "./ui/LoaderImage.vue";

export default {
  name: "ReviewsSection",
  components: {
    Swiper,
    SwiperSlide,
    AppButton,
    EstateNewCard,
    LoaderImage
  },

  props: {
    newEstateList: {
      type: Array,
      default: () => []
    },
    doUpdate: {
      type: Boolean,
      default: true
    }
  },

  setup() {
    return {
      modules: [Navigation, Pagination]
    };
  }
};
</script>

<style lang="scss" scoped>
.new-estate {
  $that: &;

  overflow: hidden;
  padding: 64px 0;

  @include laptop {
    padding: 48px 0;
  }

  @include phone {
  }

  &__inner {
    @extend %container;

    text-align: center;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__title {
  }

  &__nav-swiper {
    flex: none;
    padding-left: 24px;
    display: flex;
    color: $color_primary;

    * {
      flex: none;
      cursor: pointer;
      transition: color 0.25s ease, transform 0.25s ease;

      &.swiper-button-disabled {
        color: $color_grey-15;
      }

      &:hover {
        transform: scale(1.2);
      }
    }

    *:first-child {
      margin-right: 56px;
    }

    @include phone {
      display: none;
    }
  }

  &__loader {
    display: flex;
    margin: 0 -16px;

    @include desktop {
      margin: 0 -10px;
    }

    @include phone {
      margin: 0;
    }
  }

  &__loader-item {
    padding: 0 16px;
    flex: 0 0 25%;
    height: 382px;

    @include desktop {
      padding: 0 10px;
      height: 406px;
    }

    @include laptop {
      flex: 0 0 33.333%;
      height: 368px;

      &:nth-child(n + 4) {
        display: none;
      }
    }

    @include tablet {
      flex: 0 0 50%;
    }

    @include phone {
      flex: 1 1 100%;
      height: 100%;
      height: 368px;

      & + & {
        display: none;
      }
    }

    @include phone-s {
      height: 320px;
    }
  }

  &__swiper {
    overflow: visible;
    margin: 0 -16px;

    @include desktop {
      margin: 0 -10px;
    }

    @include phone {
      margin: 0;
    }
  }

  &__slide {
    padding: 0 16px;
    opacity: 0;
    transition: opacity 0.25s 0.5s ease;

    &.swiper-slide-active {
      opacity: 1;
      transition: opacity 0.25s ease;

      & + #{$that}__slide {
        opacity: 1;
        transition: opacity 0.25s ease;

        @include phone {
          opacity: 0;
        }
      }

      & + #{$that}__slide + #{$that}__slide {
        opacity: 1;
        transition: opacity 0.25s ease;

        @include tablet {
          opacity: 0;
        }
      }

      & + #{$that}__slide + #{$that}__slide + #{$that}__slide {
        opacity: 1;
        transition: opacity 0.25s ease;

        @include tablet {
          opacity: 0;
        }
      }
    }

    @include desktop {
      padding: 0 10px;
    }

    @include phone {
      padding: 0;
    }
  }

  &__pagination {
    padding: 12px 0;

    @media (min-width: 640px) {
      display: none;
    }
  }

  &__action {
    display: inline-block;
    margin: 64px auto 0;

    @include laptop {
      margin: 30px auto 0;
    }
  }
}
</style>
