<template>
  <span class="label">
    <slot>Строящийся дом</slot>
  </span>
</template>

<script>
export default {
  name: "AppLabel",
};
</script>

<style lang="scss" scoped>
.label {
  display: inline-block;
  z-index: 4;
  padding: 6px 16px;
  color: $white;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: rgba(35, 41, 48, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 15px;
}
</style>
