<template>
  <p
    v-if="!codeSent"
    class="modal-auth__subtitle"
  >
    Укажите телефон, к которому привязан личный кабинет
  </p>

  <form
    action="#"
    class="modal-auth__form"
    @submit.prevent="codeSent ? send() : getCode()"
  >
    <div class="modal-auth__form-grid">
      <form-input
        v-if="!codeSent"
        v-model="data.phone"
        label="Телефон"
        type="tel"
      />
      <template v-else>
        <form-input
          v-model="data.code"
          label="Код"
        />
        <form-input
          v-model="data.password"
          label="Пароль"
          type="password"
        />
      </template>
    </div>

    <app-button
      type="submit"
      :sending="loading"
    >
      {{ codeSent ? 'Сбросить пароль' : 'Выслать код' }}
    </app-button>
  </form>
</template>

<script>
import { ref, reactive } from 'vue';
import api from '@/plugins/api';
import { useNotification } from '@kyvg/vue3-notification';

import FormInput from '@/components/form/FormInput.vue';
import AppButton from '@/components/ui/AppButton.vue';

export default {
  components: {
    FormInput,
    AppButton,
  },
  emits: ['success'],
  setup(props, { emit }) {
    const loading = ref(false);
    const codeSent = ref(false);
    const { notify } = useNotification();

    const data = reactive({
      phone: '',
    });

    const getCode = () => {
      loading.value = true;

      api.post('/api/getSmsProfile', data)
        .then(({ data }) => {
          if (data.error) {
            notify({
              title: 'Ошибка!',
              text: data.error,
              type: 'error',
            });
          } else {
            notify({
              text: 'Пожалуйста, введите код из СМС и новый пароль',
            });

            data.code = '';
            data.password = '';
            codeSent.value = true;
          }
        })
        .catch(() => {})
        .finally(() => {
          loading.value = false;
        });
    };

    const send = () => {
      loading.value = true;

      api.post('/api/changePasswordProfile', data)
        .then(({data}) => {
          if (data.error) {
            notify({
              title: 'Ошибка!',
              text: data.error,
              type: 'error',
            });
          } else {
            notify({
              title: 'Успех',
              text: 'Пароль успешно изменен. Пожалуйста, авторизуйтесь с новым паролем',
              type: 'success',
            });

            emit('go-to', 'auth');
          }
        })
        .finally(() => {
          loading.value = false;
        });
    }

    return {
      loading,
      codeSent,
      data,
      getCode,
      send,
    };
  },
};
</script>
