import { wait } from "@/plugins/helpers.js";

const setInterface = {
  async setCurrentCity(city, route, prop) {
    this.query.currentCity = city;
    if (!(prop === "NOT_SET_SUBLOCAL")) {
      this.query.currentSubLocal = [];
    }
    const res = await this.load(route, "WITH_CITY");

    this.setSubLocal(res);
  },
  setSubLocal(res) {
    if (res.data.filters.sub_locality.length !== 0) {
      this.query.subLocalitiesAreDisabled = false;
      this.query.currentSubLocalRange = res.data.filters.all_sub_localities;
    } else {
      this.query.subLocalitiesAreDisabled = true;
    }
  },
  setCurrentSubLocal(subLocal) {
    this.query.currentSubLocal.push(subLocal);
  },
  deleteCurrentSubLocal(subLocal) {
    this.query.currentSubLocal = this.query.currentSubLocal.filter((el) => el !== subLocal);
  },
  toggleFilters() {
    this.query.showAllFilters = !this.query.showAllFilters;
  },
  closeMobileFilters() {
    this.query.filterMobileMenuIsOpen = false;
    document.querySelector("body").style = "overflow: auto";
  },
  toggleMap(event) {
    if (event.target.checked) {
      this.query.showMap = true;
    } else {
      this.query.showMap = false;
      document.querySelector(".estate-list__toggle-map input").checked = false;
    }
  },
  setMinMaxPriceValues(values) {
    this.query.minPriceValue = values[0];
    this.query.maxPriceValue = values[1];
  },
  setMinMaxFloorValues(values) {
    this.query.minFloorValue = values[0];
    this.query.maxFloorValue = values[1];
  },

  toggleNotLastFloorAction() {
    this.query.notLastFloor ? (this.query.notLastFloor = 0) : (this.query.notLastFloor = 1);
    this.query.lastFloor = 0;
  },
  toggleLastFloorAction() {
    this.query.lastFloor ? (this.query.lastFloor = 0) : (this.query.lastFloor = 1);
    this.query.notLastFloor = 0;
  },
  toggleNotFirstFloorAction() {
    this.query.notFirstFloor ? (this.query.notFirstFloor = 0) : (this.query.notFirstFloor = 1);
  },

  setMinMaxSquareValues(values) {
    this.query.minSquareValue = values[0];
    this.query.maxSquareValue = values[1];
  },
  setMinMaxAreaValues(values) {
    this.query.minAreaValue = values[0];
    this.query.maxAreaValue = values[1];
  },
  setMinMaxRoadDistanceValues(values) {
    this.query.minRoadDistanceValue = values[0];
    this.query.maxRoadDistanceValue = values[1];
  },
  setMinMaxKitchenValues(values) {
    this.query.minKitchenValue = values[0];
    this.query.maxKitchenValue = values[1];
  },
  setNewFlatValue() {
    this.query.newFlat ? (this.query.newFlat = 0) : (this.query.newFlat = 1);
  },
  setNewIsSNTValue() {
    this.query.isSNT ? (this.query.isSNT = 0) : (this.query.isSNT = 1);
  },
  setNewIsIZHSValue() {
    this.query.isIZHS ? (this.query.isIZHS = 0) : (this.query.isIZHS = 1);
  },
  setNewIsDNPValue() {
    this.query.isDNP ? (this.query.isDNP = 0) : (this.query.isDNP = 1);
  },
  setCurrentRoomList(index) {
    this.query.currentRoomListRange[index].checked = !this.query.currentRoomListRange[index].checked;
    this.setCurrentRoomListValues();
  },
  setCurrentRoomListStudio() {
    this.query.currentRoomListRange[0].checked = !this.query.currentRoomListRange[0].checked;
    this.setCurrentRoomListValues();
  },
  setCurrentRoomListValues() {
    this.query.currentRoomListValues = this.query.currentRoomListRange
      .map((el) => {
        if (el.checked) {
          return el.value;
        }
      })
      .filter((el) => el !== undefined);
  },
  setClearRoomsNewFlatIsSNTIsDNPIsIZHS() {
    this.query.currentSubLocal = [];
    this.query.currentRoomListRange.forEach((room, i, arr) => {
      arr[i].checked = false;
    });
    this.query.lastFloor = 0;
    this.query.notLastFloor = 0;
    this.query.notFirstFloor = 0;
    this.query.newFlat = 0;
    this.query.isSNT = 0;
    this.query.isDNP = 0;
    this.query.isIZHS = 0;
  },
  setFurnish(value) {
    this.query.furnishList.forEach((el, i) => {
      if (el.value === value) {
        this.query.furnishList[i].checked = true;
        this.query.currentFurnish = el.value;
      } else {
        this.query.furnishList[i].checked = false;
      }
    });
    if (value === 3) {
      this.query.currentFurnish = [];
    }
  },
  setClearFurnish() {
    this.query.furnishList.forEach((el, i, arr) => {
      if (i === 0) {
        arr[i].checked = true;
      } else {
        arr[i].checked = false;
      }
    });
  },
  setQuartal(val) {
    this.query.offerDeadlines.forEach((el, i) => {
      const deadline = el.built_year + "_" + el.ready_quarter;
      if (deadline === val) {
        this.query.offerDeadlines[i].checked = !this.query.offerDeadlines[i].checked;
      }
    });
    const deadlines = [];
    this.query.offerDeadlines.forEach((el) => {
      if (el.checked) {
        deadlines.push(el.built_year + "_" + el.ready_quarter);
      }
    });
    this.query.currentDeadlines = deadlines;
  },
  setPurpose(title) {
    const purposes = [];
    this.query.purposes.forEach((el, i) => {
      if (this.query.purposes[i].title === title) {
        this.query.purposes[i].checked = !this.query.purposes[i].checked;
      }
      if (this.query.purposes[i].checked) {
        purposes.push(el.title);
      }
    });
    this.query.purposesParams = purposes;
  },
  setCurrentZhkId() {
    this.query.currentZhk = this.query.offerZhkList.filter((el) => el.checked).map((el) => el.id);
  },
  setCurrentZhk(zhk) {
    this.query.offerZhkList.forEach((el, i) => {
      if (el.title === zhk) {
        this.query.offerZhkList[i].checked = true;
      }
    });
    this.setCurrentZhkId();
  },
  deleteCurrentZhk(zhk) {
    this.query.offerZhkList.forEach((el, i) => {
      if (el.title === zhk) {
        this.query.offerZhkList[i].checked = false;
      }
    });
    this.setCurrentZhkId();
  },
  setRoute(path) {
    this.query.route = path;
  },
  setPage(page) {
    this.query.page = page;
  },
  setSort(payload) {
    this.query.sortBy = payload[0];
    this.query.orderBy = payload[1];
  },
  saveScrollTo() {
    this.scrollY = window.scrollY;
  },
  saveScrollForEstateList(id) {
    this.currentCardId = id;

    this.listScrollY = window.scrollY;
    this.backToTheList = true;
  },
  async checkScroll() {
    if (this.backToTheList) {
      await wait(1000);
      const elem = document.getElementById(`room-${this.currentCardId}`);

      window.scrollTo({
        top: elem ? elem.offsetTop : this.listScrollY,
        left: "",
        behavior: "smooth",
      });
      this.backToTheList = false;
    }
  },
  saveCardPoint(point, zoom) {
    this.pointZoom = zoom;
    this.point = point;
    this.backToTheMap = true;
  },
  setFalseBackToTheMap() {
    this.backToTheMap = false;
  },
  setCloseMaps() {
    this.query.showMobileMap = false;
    this.query.showMap = false;
  },
};
export default setInterface;
