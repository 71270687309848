<template>
  <LayoutDefault />
</template>

<script>
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import { useScriptTag } from '@vueuse/core'

export default {
  components: { LayoutDefault },
  setup() {
    useScriptTag(
    'https://vats493643.megapbx.ru/callback.js?uid=a0d91259-2f6a-4e72-90b1-4bd1a83a358b',
    (el) => {
      // do something
    },
  )
  }
};
</script>

<style lang="scss"></style>
