export default {
  name: "date",

  mounted(element) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const date = new Date(element.innerHTML);
    const localDate = date.toLocaleDateString("ru", options);

    element.innerHTML = localDate;
  },
};
