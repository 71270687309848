<template>
  <nav
    class="nav"
    @click="toFirstPage"
  >
    <ul class="nav__list">
      <li
        v-for="navItem in navList"
        :key="navItem.name"
        class="nav__item"
      >
        <button
          class="nav__go"
          @click="clearFilters(navItem.link)"
        >
          {{ navItem.name }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import router from "@/router/index.js";
import { mapActions } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";

export default {
  props: {
    navList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    origin() {
      return window.location.origin;
    }
  },
  methods: {
    ...mapActions(useFilterStore, ["setCloseMaps"]),
    toFirstPage() {
      const pagination = document.querySelector(".page-pagination");
      if (!pagination) return;
      switch (this.$route.name) {
        case "Вторичная недвижимость":
        case "Новостройки":
        case "Загородная":
        case "Коммерческая":
          pagination.getElementsByClassName("page-pagination__button")[0].click();
          break;
        default:
          break;
      }
    },
    async clearFilters(path) {
      await router.push({ path: path, query: {} });
      this.setCloseMaps();
      this.emitter.emit("clear-filter", "FROM_MAIN");
    }
  }
};
</script>

<style lang="scss" scoped>
.nav {
  $that: &;

  margin: 0 auto;

  &__list {
    display: flex;
    justify-content: center;
    padding-top: 2px;
  }

  &__item {
    & + & {
      padding-left: 28px;

      @include laptop {
        padding-left: 18px;
      }
    }
  }

  &__go {
    position: relative;
    color: $white;
    font-family: $font_title;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    transition: text-shadow 0.6s 0.1s cubic-bezier(0.22, 0.61, 0.36, 1);

    @include desktop {
      font-size: 12px;
    }

    @include laptop {
      font-size: 11px;
    }

    @include hover {
      text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 50px rgba(255, 255, 255, 0.8), 0 0 35px rgba(255, 255, 255, 0.6),
      0 0 76px rgba(255, 255, 255, 0.4), 0 0 37px rgba(255, 255, 255, 0.5), 0 0 78px rgba(255, 255, 255, 0.4),
      0 0 39px rgba(255, 255, 255, 0.3), 0 0 80px rgba(255, 255, 255, 0.2), 0 0 35px rgba(255, 255, 255, 0.1);
    }

    &.router-link-active {
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: $color_primary;
        position: absolute;
        left: 0;
        bottom: -35px;

        @include laptop {
          bottom: -37px;
        }
      }
    }
  }
}
</style>
