<template>
  <AppAccordion
    :is-filter-head="isFilterHead"
    :is-filter-map="isFilterMap"
    class="filter-square _filter-head-336"
    :arrow="true"
  >
    <template #header>
      <span v-html="title" />
    </template>
    <div class="filter-square__body">
      <div class="filter-square__value-list">
        <span>{{ minSquareValue }} м <sup>2</sup> </span> -
        <span>{{ maxSquareValue }} м <sup>2</sup> </span>
      </div>

      <FormRange ref="formRange" v-model="squareRange" :min="minSquareRange" :max="maxSquareRange" />
    </div>
  </AppAccordion>
</template>

<script>
import FormRange from "../form/FormRange.vue";
import AppAccordion from "@/components/ui/AppAccordion.vue";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { mapActions, mapState } from "pinia";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterSquare",
  components: { FormRange, AppAccordion },
  props: {
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    isFilterMap: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Площадь, м<sup>2</sup>",
    },
  },
  computed: {
    ...mapState(useFilterStore, ["minSquareValue", "maxSquareValue", "minSquareRange", "maxSquareRange"]),
    squareRange: {
      async set(values) {
        this.setMinMaxSquareValues(values);
        await setParamsInUrl(this, this.$route.query, {
          area: {
            min: values[0],
            max: values[1],
          },
        });
        this.emitter.emit("load-with-params");
      },
      get() {
        return [this.minSquareValue, this.maxSquareValue];
      },
    },
  },
  methods: {
    ...mapActions(useFilterStore, ["setMinMaxSquareValues", "returnRequiredParams"]),
  },
};
</script>

<style lang="scss">
._area-metars-map-filter {
  & .accordion__header {
    padding-right: 7px !important;
    padding-left: 13px !important;
  }
}
</style>

<style lang="scss" scoped>
.filter-square {
  $that: &;

  min-width: 292px;
  display: flex;
  flex-direction: column;
  color: $white;

  &__body {
    min-width: 334px;
    background-color: $color_dark-90;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    padding: 20px 22px 42px;

    @include tablet {
      padding: 16px 16px 0;
    }

    @include phone {
      min-width: 100%;
      max-width: 100%;
      padding: 16px 10px 0;
    }
  }

  &__value-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    span {
      display: inline-block;
      width: 120px;
      border: 1px solid $color_dark-40;
      border-radius: 4px;
      padding: 10px;
      text-align: left;

      @include flex-center;

      @include phone {
        width: 96px;
      }
    }
  }
}
</style>
