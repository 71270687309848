<template>
  <div 
    ref="formInput"
    class="form-input"
    :class="classes"
  >
    <div class="form-input__inner">
      <label 
        v-if="label"
        class="form-input__label"
        :class="{
          '_fixed-label': isFixedLabel
        }"
        :for="name"
      >{{ label }}</label>
      
      <VueDatePicker 
        v-model="inputValue"
        :enable-time-picker="hasTimePicker"
        locale="ru"
        :dark="isDark"
        :flow="flowArray"
        :auto-apply="true"
        format="dd.MM.yyyy"
      >
        <template #dp-input="{value}">
          <input
            v-bind="$attrs"
            :value="value"
            type="text"
            :placeholder="placeholder"
            class="form-input__field"
            @focus="onFocus"
            @blur="onBlur"
          >
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import "@vuepic/vue-datepicker/dist/main.css";

import { reactive, computed } from 'vue';

export default {
  name: "FormDatepicker",

  components: {
    VueDatePicker
  },

  props: {
    name: {
      type: String,
      default: "DateName"
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDark: {
      type: Boolean,
      default: false
    },
    hasTimePicker: {
      type: Boolean,
      default: false
    },
    flow: {
      type: String,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  setup(props) {
    props = reactive(props);

    const flowArray = computed(() => {
      if (props.flow === 'birthday') return ['year', 'month', 'calendar']

      return []
    });

    return {
      classes: computed(() => ({
        "_is-dark": props.isDark,
      })),
      flowArray,
    };
  },

  data() {
    return {
      inputValue: "",
      isFixedLabel: false
    }
  },

  watch: {
    inputValue(newVal) {
      this.$emit('update:modelValue', newVal ? newVal.toLocaleDateString('ru-RU') : "")
    }
  },

  mounted() {
    if (this.value) {
      this.inputValue = this.value;
      this.isFixedLabel = true;
    }
  },
  
  methods: {
    onFocus() {
      this.isFixedLabel = true;

      this.$refs.formInput.classList.remove("_error");
    },

    onBlur(event) {
      const inputValue = event.currentTarget.value;

      if (!inputValue) {
        this.isFixedLabel = false;
      }
    },
  }
}
</script>

<style lang="scss">
.form-input {
  $that: &;

  position: relative;

  &__inner {
    position: relative;
    // overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $color_dark-40;
      transition: background-color 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1);

      ._is-dark & {
        background-color: transparent;
      }
    }

    &::after {
      // content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: #f36421;
      transition: width 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1),
        background-color 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1);

      #{$that}:focus-within & {
        width: 100%;
      }

      ._is-dark & {
        background-color: $color-grey-15;
      }
    }

    ._error & {
      &::before,
      &::after {
        background-color: $color_primary;
      }
    }
  }

  &__label {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    padding-left: 16px;
    color: $color_dark-50;
    pointer-events: none;
    will-change: transform;
    transition: transform 0.3s ease, background-color 0.3s ease;
    transform-origin: top left;

    ._is-dark & {
      color: $color_dark-40;
    }

    &._fixed-label {
      transform: scale(0.75) translateY(-18px);
    }
  }

  &__field {
    width: 100%;
    padding: 0;
    border: none;
    transition: color 0.3s ease;
    background-color: $color-grey-2;

    &::placeholder {
      opacity: 1 !important;
      color: #a5abb0;
    }

    ._is-dark & {
      background-color: $color_dark-70;
      color: $white;
    }

    #{$that}._error & {
      color: $color_primary;
    }
  }

  &__error {
    color: $color_primary;
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    opacity: 0;
    visibility: hidden;

    ._error & {
      opacity: 1;
      visibility: visible;
    }
  }

  .dp__theme_dark {
  --dp-background-color: #40464c;
  --dp-text-color: #fff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: #fff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: $color_primary;
  --dp-primary-disabled-color: #61a8ea;
  --dp-primary-text-color: #f36421;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: $color-white;
  --dp-menu-border-color: #2d2d2d;
  --dp-border-color-hover: #aaaeb7;
  --dp-border-color-focus: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-disabled-color-text: #969BA0;
  --dp-scroll-bar-background: $white;
  --dp-scroll-bar-color: #f36421;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-marker-color: #e53935;
  --dp-tooltip-color: #3e3e3e;
  --dp-highlight-color: rgb(0 92 178 / 20%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
  --dp-range-between-border-color: var(--dp-hover-color, #fff);
}

.dp__theme_light {
  --dp-background-color: #f9fafa;
  --dp-text-color: #212121
  ;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: $color_primary;
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #f36421;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #fff;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #fff;
  --dp-border-color-focus: #fff;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: $white;
  --dp-scroll-bar-color: #f36421;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #969BA0;
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}
}

.error-enter-active {
  transition: opacity 0.5s, transform 0.5s;
}

.error-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}

.error-enter {
  opacity: 0;
  transform: translate(8px, 0);
}

.error-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translate(0, 2px);
}


</style>