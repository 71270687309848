<template>
  <button class="button-user" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <app-svg
      :id="hovered ? 'user-hovered' : 'user'"
      width="18"
      height="22"
    />
  </button>
</template>

<script setup>
import { ref } from 'vue';

const hovered = ref(false);
</script>

<style scoped lang="scss">
.button-user {
  padding: 16px 18px;
  border: 1px solid #969ba0;
  margin-left: 20px;

  &:hover {
    border-color: #F4691B;
  }
}
</style>
