<template>
  <AppAccordion
    :is-filter-head="isFilterHead"
    :is-filter-map="isFilterMap"
    class="filter-kitchen _filter-head-336"
    :arrow="true"
  >
    <template #header>
      <span v-html="title" />
    </template>
    <div class="filter-kitchen__body">
      <div class="filter-kitchen__value-list">
        <span>{{ minKitchenValue }} м <sup>2</sup> </span> -
        <span>{{ maxKitchenValue }} м <sup>2</sup> </span>
      </div>

      <FormRange
        ref="formRange"
        v-model="KitchenRange"
        :min="minKitchenRange"
        :max="maxKitchenRange"
      />
    </div>
  </AppAccordion>
</template>

<script>
import FormRange from "../form/FormRange.vue";
import AppAccordion from "@/components/ui/AppAccordion.vue";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { mapActions, mapState } from "pinia";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterKitchen",
  components: { FormRange, AppAccordion },

  props: {
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    isFilterMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useFilterStore, ["minKitchenValue", "maxKitchenValue", "minKitchenRange", "maxKitchenRange"]),
    KitchenRange: {
      async set(values) {
        this.setMinMaxKitchenValues(values);
        await setParamsInUrl(this, this.$route.query, {
          kitchenSpace: {
            min: values[0],
            max: values[1],
          },
        });
        this.emitter.emit("load-with-params");
      },
      get() {
        return [this.minKitchenValue, this.maxKitchenValue];
      },
    },
  },
  data() {
    return {
      title: "Кухня, м<sup>2</sup>",
      squareRange: [this.minRange, this.maxRange],
    };
  },
  methods: {
    ...mapActions(useFilterStore, ["setMinMaxKitchenValues", "returnRequiredParams"]),
  },
};
</script>

<style lang="scss">
._kitchen-filter-map {
  padding-left: 0 !important;
  padding-right: 0 !important;

  & .accordion__header {
    padding-right: 7px !important;
    padding-left: 13px !important;
  }
}
</style>

<style lang="scss" scoped>
.filter-kitchen {
  $that: &;

  min-width: 292px;
  display: flex;
  flex-direction: column;
  color: $white;

  &__body {
    min-width: 334px;
    background-color: $color_dark-90;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    padding: 20px 22px 42px;

    @include tablet {
      padding: 16px 16px 0;
    }
  }

  &__value-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    span {
      display: inline-block;
      width: 120px;
      border: 1px solid $color_dark-40;
      border-radius: 4px;
      padding: 10px;
      text-align: left;

      @include flex-center;
    }
  }
}
</style>
