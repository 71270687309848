<template>
  <AppAccordion
    :is-filter-head="isFilterHead"
    :is-filter-map="isFilterMap"
    class="filter-floor _filter-head-336"
    :arrow="true"
  >
    <template #header>
      {{ title }}
    </template>
    <div class="filter-floor__body">
      <div class="filter-floor__value-list">
        <span>{{ minFloorValue }} этаж </span> -
        <span>{{ maxFloorValue }} этаж </span>
      </div>

      <FormRange ref="formRange" v-model="floorRangeTest" :min="minFloorRange" :max="maxFloorRange" />
      <FormCheck :checked="notLastFloorCheck" class="filter-floor__check" @update:modelValue="toggleNotLastFloor">
        Не последний
      </FormCheck>
      <FormCheck :checked="lastFloorCheck" class="filter-floor__check" @update:modelValue="toggleLastFloor">
        Только последний
      </FormCheck>
      <FormCheck :checked="notFirstFloorCheck" class="filter-floor__check" @update:modelValue="toggleNotFirstFloor">
        Не первый
      </FormCheck>
    </div>
  </AppAccordion>
</template>

<script>
import FormRange from "../form/FormRange.vue";
import FormCheck from "../form/FormCheck.vue";
import AppAccordion from "@/components/ui/AppAccordion.vue";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { mapActions, mapState } from "pinia";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterFloor",
  components: { FormRange, FormCheck, AppAccordion },

  props: {
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    isFilterMap: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useFilterStore, [
      "minFloorValue",
      "maxFloorValue",
      "minFloorRange",
      "maxFloorRange",
      "notLastFloorCheck",
      "lastFloorCheck",
      "notFirstFloorCheck",
    ]),
    floorRangeTest: {
      async set(values) {
        this.setMinMaxFloorValues(values);
        await setParamsInUrl(this, this.$route.query, {
          floor: {
            min: values[0],
            max: values[1],
          },
        });
        this.emitter.emit("load-with-params");
      },
      get() {
        return [this.minFloorValue, this.maxFloorValue];
      },
    },
  },
  data() {
    return {
      title: "Этажность",
    };
  },

  methods: {
    ...mapActions(useFilterStore, [
      "query",
      "updateResultAfterConfigureFilter",
      "setMinMaxFloorValues",
      "toggleLastFloorAction",
      "toggleNotLastFloorAction",
      "toggleNotFirstFloorAction",
      "returnRequiredParams",
    ]),
    async toggleLastFloor() {
      this.toggleLastFloorAction();

      await setParamsInUrl(this, this.$route.query, {
        lastFloor: this.lastFloorCheck ? 1 : 0,
        notLastFloor: 0,
      });
      this.emitter.emit("load-with-params");
    },
    async toggleNotLastFloor() {
      this.toggleNotLastFloorAction();
      await setParamsInUrl(this, this.$route.query, {
        notLastFloor: this.notLastFloorCheck ? 1 : 0,
        lastFloor: 0,
      });
      this.emitter.emit("load-with-params");
    },
    async toggleNotFirstFloor() {
      this.toggleNotFirstFloorAction();
      await setParamsInUrl(this, this.$route.query, {
        notFirstFloor: this.notFirstFloorCheck ? 1 : 0,
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>
<style lang="scss">
._floor-filter-map {
  padding-left: 0 !important;
  padding-right: 0 !important;

  & .accordion__header {
    padding-right: 7px !important;
    padding-left: 13px !important;
  }
}
</style>
<style lang="scss" scoped>
.filter-floor {
  $that: &;

  min-width: 292px;
  display: flex;
  flex-direction: column;
  color: $white;

  &__body {
    min-width: 334px;
    background-color: $color_dark-90;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    padding: 20px 22px;

    @include tablet {
      padding: 16px 16px 0;
    }
  }

  &__value-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    span {
      display: inline-block;
      width: 120px;
      border: 1px solid $color_dark-40;
      border-radius: 4px;
      padding: 10px;
      text-align: left;

      @include flex-center;
    }
  }

  &__check {
    display: flex;
    margin-top: 24px;
  }
}
</style>
