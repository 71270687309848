<template>
  <div
    class="button-like"
    :class="{ '_is-liked': isLiked }"
    @click="$emit('like-card')"
  >
    <slot v-if="slotBefore" />
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="heart"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7233 20.8375C9.20993 19.3532 6.87181 17.6063 4.75076 15.628C3.25956 14.2033 2.12432 12.4664 1.43201 10.5504C0.186166 6.83367 1.64139 2.5787 5.71393 1.31947C7.85429 0.658261 10.1919 1.03617 11.9955 2.33497C13.7998 1.03775 16.1365 0.659975 18.277 1.31947C22.3496 2.5787 23.8153 6.83367 22.5694 10.5504C21.8771 12.4664 20.7419 14.2033 19.2507 15.628C17.1296 17.6063 14.7915 19.3532 12.2781 20.8375L12.0059 21L11.7233 20.8375Z"
        stroke="currentColor"
        stroke-linecap="round"
        fill="transparent"
        stroke-linejoin="round"
      />

      <g
        class="group"
        fill="none"
        fill-rule="evenodd"
        transform="translate(0 0)"
      >
        <circle
          class="main-circ"
          fill="#ff8635"
          opacity="0"
          cx="29.5"
          cy="29.5"
          r="1.5"
        />
        <g
          class="particle_7"
          opacity="0"
          transform="translate(7 6)"
        >
          <circle
            class="oval_1"
            fill="#9CD8C3"
            cx="2"
            cy="6"
            r="2"
          />
          <circle
            class="oval_2"
            fill="#8CE8C3"
            cx="5"
            cy="2"
            r="2"
          />
        </g>

        <g
          class="particle_6"
          opacity="0"
          transform="translate(0 28)"
        >
          <circle
            class="oval_1"
            fill="#CC8EF5"
            cx="2"
            cy="7"
            r="2"
          />
          <circle
            class="oval_2"
            fill="#91D2FA"
            cx="3"
            cy="2"
            r="2"
          />
        </g>

        <g
          class="particle_3"
          opacity="0"
          transform="translate(52 28)"
        >
          <circle
            class="oval_2"
            fill="#9CD8C3"
            cx="2"
            cy="7"
            r="2"
          />
          <circle
            class="oval_1"
            fill="#8CE8C3"
            cx="4"
            cy="2"
            r="2"
          />
        </g>

        <g
          class="particle_2"
          opacity="0"
          transform="translate(44 6)"
        >
          <circle
            class="oval_2"
            fill="#CC8EF5"
            cx="5"
            cy="6"
            r="2"
          />
          <circle
            class="oval_1"
            fill="#CC8EF5"
            cx="2"
            cy="2"
            r="2"
          />
        </g>

        <g
          class="particle_5"
          opacity="0"
          transform="translate(14 50)"
        >
          <circle
            class="oval_1"
            fill="#91D2FA"
            cx="6"
            cy="5"
            r="2"
          />
          <circle
            class="oval_2"
            fill="#91D2FA"
            cx="2"
            cy="2"
            r="2"
          />
        </g>

        <g
          class="particle_4"
          opacity="0"
          transform="translate(35 50)"
        >
          <circle
            class="oval_1"
            fill="#F48EA7"
            cx="6"
            cy="5"
            r="2"
          />
          <circle
            class="oval_2"
            fill="#F48EA7"
            cx="2"
            cy="2"
            r="2"
          />
        </g>

        <g
          class="particle_1"
          opacity="0"
          transform="translate(24)"
        >
          <circle
            class="oval_1"
            fill="#9FC7FA"
            cx="2.5"
            cy="3"
            r="2"
          />
          <circle
            class="oval_2"
            fill="#9FC7FA"
            cx="7.5"
            cy="2"
            r="2"
          />
        </g>
      </g>
    </svg>
    <slot v-if="slotAfter" />
  </div>
</template>

<script>
import { useFavoritesStore } from "@/store/store.js";
import { computed } from "vue";

export default {
  name: "ButtonLike",

  props: {
    slotBefore: {
      type: Boolean,
      default: false,
    },
    slotAfter: {
      type: Boolean,
      default: false,
    },
    alias: {
      type: String,
    },
  },
  setup(props) {
    const store = useFavoritesStore();
    let isLiked = false;
    isLiked = computed(() => store.getIsLiked(props.alias));
    return { isLiked };
  },
};
</script>

<style lang="scss" scoped>
.button-like {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  transition: lor 0.25s ease-out;
  color: $color_dark-40;
  cursor: pointer;

  svg {
    margin: 0 9px;
    overflow: visible;
  }

  &:hover {
    color: $color_primary;
  }

  .group {
    transform: translate(-16px, -18px);
  }

  .heart {
    transform-origin: center;
    animation: animateHeartOut 0.3s linear forwards;
  }

  .main-circ {
    transform-origin: 29.5px 29.5px;
  }

  &._is-liked {
    color: $color_primary;

    .heart {
      transform: scale(0.2);
      fill: $color_primary;
      animation: animateHeart 0.3s linear forwards 0.25s;
    }

    .main-circ {
      transition: all 2s;
      animation: animateCircle 0.3s linear forwards;
      opacity: 1;
    }

    .particle_1 {
      opacity: 1;
      transition: 0.1s all 0.3s;

      .oval_1 {
        transform: scale(0) translate(0, -30px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }

      .oval_2 {
        transform: scale(0) translate(10px, -50px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }

    .particle_2 {
      opacity: 1;
      transition: 0.1s all 0.3s;

      .oval_1 {
        transform: scale(0) translate(30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }

      .oval_2 {
        transform: scale(0) translate(60px, -15px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }

    .particle_3 {
      opacity: 1;
      transition: 0.1s all 0.3s;

      .oval_1 {
        transform: scale(0) translate(30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }

      .oval_2 {
        transform: scale(0) translate(60px, 10px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }

    .particle_4 {
      opacity: 1;
      transition: 0.1s all 0.3s;

      .oval_1 {
        transform: scale(0) translate(30px, 15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }

      .oval_2 {
        transform: scale(0) translate(40px, 50px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }

    .particle_5 {
      opacity: 1;
      transition: 0.1s all 0.3s;

      .oval_1 {
        transform: scale(0) translate(-10px, 20px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }

      .oval_2 {
        transform: scale(0) translate(-60px, 30px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }

    .particle_6 {
      opacity: 1;
      transition: 0.1s all 0.3s;

      .oval_1 {
        transform: scale(0) translate(-30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }

      .oval_2 {
        transform: scale(0) translate(-60px, -5px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }

    .particle_7 {
      opacity: 1;
      transition: 0.1s all 0.3s;

      .oval_1 {
        transform: scale(0) translate(-30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }

      .oval_2 {
        transform: scale(0) translate(-55px, -30px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }

    .particle_2 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }

    .particle_3 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }

    .particle_4 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }

    .particle_5 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }

    .particle_6 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }

    .particle_7 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
  }

  @keyframes animateCircle {
    40% {
      transform: scale(10);
      opacity: 1;
      fill: #dd4688;
    }
    55% {
      transform: scale(11);
      opacity: 1;
      fill: #d46abf;
    }
    65% {
      transform: scale(12);
      opacity: 1;
      fill: #cc8ef5;
    }
    75% {
      transform: scale(13);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.5;
    }
    85% {
      transform: scale(17);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.2;
    }
    95% {
      transform: scale(18);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.1;
    }
    100% {
      transform: scale(19);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0;
    }
  }

  @keyframes animateHeart {
    0% {
      transform: scale(0.2);
    }
    40% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes animateHeartOut {
    0% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
