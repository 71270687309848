<!-- <FormTextArea
      name="message"
      v-model="form.message"
      label="Ваше сообщение"
    /> -->

<template>
  <div
    ref="formTextarea"
    class="form-textarea"
    :class="classes"
  >
    <div class="form-textarea__inner">
      <label
        v-if="label"
        class="form-textarea__label"
        :class="{
          '_fixed-label': isFixedLabel,
        }"
        :for="name"
      >{{ label }}</label>
      <textarea
        v-bind="$attrs"
        :id="name"
        v-model="inputValue"
        :name="name"
        class="form-textarea__field"
        type="text"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </div>

    <transition
      name="error"
      appear
    >
      <div class="form-textarea__error">
        Вы точно не хотите ничего сказать?
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "FormTextArea",

  props: {
    name: {
      type: String,
      default: "inputName",
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    isDark: {
      type: [String, Boolean],
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_is-dark": props.isDark,
      })),
    };
  },

  data() {
    return {
      inputValue: "",
      isFixedLabel: false,
    };
  },

  mounted() {
    if (this.value) {
      this.inputValue = this.value;
      this.isFixedLabel = true;
    }
  },

  methods: {
    handleFocus() {
      this.isFixedLabel = true;

      this.$refs.formTextarea.classList.remove("_error");
    },

    handleBlur(event) {
      const inputValue = event.currentTarget.value;

      if (!inputValue) {
        this.isFixedLabel = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-textarea {
  $that: &;

  position: relative;

  &__inner {
    overflow-y: hidden;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $color_body;

      ._is-dark & {
        background-color: transparent;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $color_primary;
      transition: opacity 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1), transform 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1);

      ._is-dark & {
        background-color: $color-grey-15;
      }
    }

    &:focus-within {
      &::after {
        opacity: 1;
        transform: translateX(100%);
      }
    }

    ._error & {
      &::before,
      &::after {
        background-color: $color_primary;
      }
    }
  }

  &__label {
    position: absolute;
    padding-left: 16px;
    top: 16px;
    left: 0;
    width: 100%;
    color: $color_dark-50;
    pointer-events: none;
    transform: translateZ(0);
    transition: transform 0.3s ease;
    transform-origin: top left;

    ._is-dark & {
      color: $color_dark-40;
    }

    &._fixed-label {
      transform: scale(0.75) translateY(-16px);
    }
  }

  &__field {
    padding: 32px 16px 16px;
    width: 100%;
    height: 148px;
    border: 0;
    background-color: $color_grey-2;

    ._is-dark & {
      background-color: $color_dark-70;
      color: $white;
    }
  }

  &__error {
    color: $color_primary;
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    opacity: 0;
    visibility: hidden;

    ._error & {
      opacity: 1;
      visibility: visible;
    }
  }

  .error-enter-active {
    transition: opacity 0.5s, transform 0.5s;
  }

  .error-leave-active {
    transition: opacity 0.25s, transform 0.25s;
  }

  .error-enter {
    opacity: 0;
    transform: translate(8px, 0);
  }

  .error-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translate(0, 2px);
  }
}
</style>
