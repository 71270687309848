<template>
  <AppAccordion class="filter-furnish" :arrow="true" :is-filter-head="isFilterHead" :is-filter-map="isFilterMap">
    <template #header>
      <span>{{ title }}</span>
    </template>

    <div class="filter-furnish__body">
      <FormRadio
        v-for="(furnish, index) in query.furnishList"
        :key="index"
        class="filter-furnish__option"
        name="furnish"
        :checked="furnish.checked"
        :value="furnish.value"
        @change.prevent=""
        @click.prevent="setFurnishHandle(furnish)"
      >
        {{ furnish.title }}
      </FormRadio>
    </div>
  </AppAccordion>
</template>

<script>
import AppAccordion from "@/components/ui/AppAccordion.vue";
import FormRadio from "../form/FormRadio.vue";
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterFurnish",
  components: { AppAccordion, FormRadio },

  props: {
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    isFilterMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useFilterStore, ["query"]),
  },

  data() {
    return {
      title: "Отделка",
      key: 1,
    };
  },

  methods: {
    ...mapActions(useFilterStore, ["setFurnish", "returnRequiredParams"]),
    async setFurnishHandle(item) {
      this.setFurnish(item.value);
      await setParamsInUrl(this, this.$route.query, {
        furnish: this.query.currentFurnish,
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style lang="scss">
._furnish-filter-map {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;

  & .accordion__header {
    padding-right: 7px !important;
    padding-left: 13px !important;
  }
}
</style>

<style lang="scss" scoped>
.filter-furnish {
  $that: &;

  * + * {
    margin-top: 10px;
  }

  hr {
    margin-bottom: 0;
    border: none;
    height: 1px;
    color: $color-dark-70;
    background-color: $color-dark-70;
  }

  &__body {
    min-width: 224px;
    background-color: $color_dark-90;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    padding: 20px 22px;

    @include tablet {
      padding: 16px 16px 0;
    }
  }

  &__option {
    width: 100%;
  }
}
</style>
