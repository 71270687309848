<template>
  <AppAccordion
    class="filter-category"
    :arrow="true"
    :is-filter-head="isFilterHead"
    :is-filter-map="isFilterMap"
  >
    <template #header>
      <span>{{ currentCategoryOnLateralPage }}</span>
    </template>

    <div class="filter-category__body">
      <FormRadio
        v-for="(category, index) in query.mainCategories"
        :key="index"
        class="filter-category__option"
        name="property"
        :value="category.id"
        :checked="category.checked"
        @change.prevent=""
        @click.prevent="changeCategory(category)"
      >
        {{ category.title }}
      </FormRadio>
    </div>
  </AppAccordion>
</template>

<script>
import AppAccordion from "@/components/ui/AppAccordion.vue";
import FormRadio from "../form/FormRadio.vue";
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterCategory",
  components: { AppAccordion, FormRadio },

  props: {
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    isFilterMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useFilterStore, ["query", "currentCategoryOnMain", "currentCategoryOnLateralPage"]),
  },

  methods: {
    ...mapActions(useFilterStore, ["setCategoryRefactor", "returnRequiredParams"]),
    async changeCategory(category) {
      await setParamsInUrl(this, this.$route.query, {
        category: [category.id],
        page: 1,
      });
      await this.setCategoryRefactor(category, this.$route);
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-category {
  $that: &;

  * + * {
    margin-top: 10px;
  }

  hr {
    margin-bottom: 0;
    border: none;
    height: 1px;
    color: $color-dark-70;
    background-color: $color-dark-70;
  }

  &__body {
    min-width: 224px;
    background-color: $color_dark-90;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    padding: 20px 22px;

    @include tablet {
      padding: 16px 16px 0;
    }
  }

  &__option {
    width: 100%;
  }
}
</style>
