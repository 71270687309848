import focus from "@/directives/focus";
import clamp from "@/directives/clamp";
import magnet from "@/directives/magnet";
import fixed from "@/directives/fixed";
import sticky from "@/directives/sticky";
import curtain from "@/directives/curtain";
import date from "@/directives/date";
import phone from "@/directives/phone";
import currency from "@/directives/currency";

export default [clamp, curtain, focus, magnet, fixed, sticky, date, currency, phone];
