import { defineStore } from "pinia";
import getters from "@/store/filters/getters.js";
import state from "@/store/filters/initialState.js";
import refactorActions from "@/store/filters/paramsAndFetchData.js";
import setInterfaceFromQuery from "@/store/filters/setInterfaceFromQuery.js";
import getPathDependingByCategory from "@/store/filters/getPathDependingByCategory.js";
import setRanges from "@/store/filters/setRanges.js";
import setInterface from "@/store/filters/setInterface.js";

export const useFilterStore = defineStore({
  id: "filterState",
  state,
  getters,
  actions: {
    ...refactorActions,
    ...setInterfaceFromQuery,
    ...getPathDependingByCategory,
    ...setRanges,
    ...setInterface,
  },
});
