export default {
  name: "clamp",

  mounted(element, binding) {
    const lines = binding.value;

    element.style = `-webkit-line-clamp: ${lines}; -webkit-box-orient: vertical;`;
    /*overflow: hidden; display: -webkit-box;`; */

    /*    const readMore = binding.arg;

      if (readMore) {
        let readMore = document.createElement("span");
        readMore.classList.add("_read-more");
        let text = "ЧИТАТЬ ПОЛНОСТЬЮ";
        readMore.innerHTML = text;
        element.after(readMore);
      } */
  },
};
