<template>
  <div class="burger-menu-nav">
    <ul class="burger-menu-nav__list">
      <li
        v-for="(item, index) in burgerList"
        :key="index"
        class="burger-menu-nav__item"
      >
        <a 
          v-if="item.link.includes('http')"
          :href="item.link" 
          target="_blank"
          class="burger-menu-nav__go"
        >
          {{ item.name }}
        </a>
        <router-link
          :to="item.link"
          class="burger-menu-nav__go"
          @click="$emit('link-clicked')"
          v-else
        >
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BurgerMenuNav",

  props: {
    burgerList: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["link-clicked"],
};
</script>

<style lang="scss" scoped>
.burger-menu-nav {
  $that: &;

  position: fixed;
  top: 106px;
  left: 0;
  transition: transform 0.35s 0.15s ease-out;
  transform: translate3d(-100%, 0, 0);
  z-index: 99;

  &._is-open {
    transform: translate3d(0, 0, 0);
  }

  ._secondary & {
    top: 89px;
  }

  &__list {
    min-width: 416px;
    height: 242px;
    padding: 38px 56px;
    backdrop-filter: blur(10px);
    background: rgba(35, 41, 48, 0.45);
  }

  &__item {
    & + & {
      margin-top: 16px;
    }
  }

  &__go {
    color: $white;
    font-size: 16px;
    line-height: 24px;
    transition: text-shadow 0.6s 0.1s cubic-bezier(0.22, 0.61, 0.36, 1);

    &:hover {
      text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 50px rgba(255, 255, 255, 0.8), 0 0 35px rgba(255, 255, 255, 0.6),
        0 0 76px rgba(255, 255, 255, 0.4), 0 0 37px rgba(255, 255, 255, 0.5), 0 0 78px rgba(255, 255, 255, 0.4),
        0 0 39px rgba(255, 255, 255, 0.3), 0 0 80px rgba(255, 255, 255, 0.2), 0 0 35px rgba(255, 255, 255, 0.1);
    }

    &.router-link-active {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: $color_primary;
      text-underline-offset: 8px;
    }
  }
}
</style>
