<template>
  <FormCheck
    v-if="query.type === 1"
    ref="buildingHouse"
    :is-filter-head="true"
    :checked="flatChecked"
    class="filters__filter _filter-head"
    :is-dark="true"
    :digital="true"
    @update:modelValue="setNewFlatValueToggle"
  >
    Строящийся дом
  </FormCheck>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import FormCheck from "@/components/form/FormCheck.vue";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterNewFlat",
  components: { FormCheck },
  computed: {
    ...mapState(useFilterStore, ["newFlat", "flatChecked", "query"]),
  },
  methods: {
    ...mapActions(useFilterStore, ["setNewFlatValue", "returnRequiredParams"]),
    async setNewFlatValueToggle() {
      this.setNewFlatValue();
      await setParamsInUrl(this, this.$route.query, {
        newFlat: this.flatChecked ? 1 : 0,
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>
<style lang="scss">
._flat-filter-map {
  padding-left: 13px !important;
}
</style>
<style scoped></style>
