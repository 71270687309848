<template>
  <div class="new-estate-card" :class="[classes, { '_is-small': allCardsAreSmall }]">
    <EstateCardSlider
      :key="random"
      :image-list="card.images"
      class="new-estate-card__slider"
      @click="estateShow(card.alias)"
    />

    <div class="new-estate-card__info">
      <div class="new-estate-card__info-head">
        <router-link
          v-if="card.alias"
          ref="cardLink"
          v-clamp="2"
          class="new-estate-card__info-name"
          :to="{
            name: 'Детальная новостройки',
            params: { alias: card.alias },
          }"
          @click="toCard"
        >
          {{ card.title }}
        </router-link>
        <div class="new-estate-card__info-when">
          Срок сдачи
          <span v-if="card.built_year">{{ replaceNumberQuarter(card.ready_quarter) }} кв. {{ card.built_year }}г.</span>
        </div>
        <div v-if="!isSmall && card.building_type" class="new-estate-card__info-material">
          Тип Дома <span>{{ card.building_type.title }}</span>
        </div>
      </div>
      <SubwayList v-if="card.location.metro" :subway-list="card.location.metro" class="new-estate-card__subway-list" />
      <div v-if="!isSmall" v-clamp="2" class="new-estate-card__description">
        <div
          v-if="card.alias"
          v-html="card.description"
        />
        <router-link
          v-if="!isSmall"
          ref="cardLink"
          class="new-estate-card__go"
          :to="{ name: 'Детальная новостройки', params: { alias: card.alias } }"
        >
          Читать далее
        </router-link>
      </div>

      <div v-if="card.location.latitude" v-show="!isSmall" class="new-estate-card__hover-buttons">
        <ButtonLocation @click="openMap = true" />
        <teleport v-if="openMap" to="body">
          <AppModal v-model="openMap">
            <ModalMap :coords="[+card.location.latitude, +card.location.longitude]" :title="card.title" />
          </AppModal>
        </teleport>
      </div>
    </div>

    <div class="new-estate-card__price-block">
      <div class="new-estate-card__mortgage">
        Ипотека <span>от</span> <span>7%</span>
        <app-svg id="mortgage" width="24" height="21" />
      </div>

      <div ref="cardPrice" class="new-estate-card__price">
        <span>от </span>
        <span v-if="card.price" v-currency="card.price" />
      </div>

      <EstateActions
        v-if="!isSmall && !map && card.alias"
        :is-new-estate="true"
        :alias="card.alias"
        :is-zhk="1"
        class="new-estate-card__actions"
      />
    </div>

    <EstateActions
      v-if="isSmall && !map"
      :form-page="card.title"
      :is-new-estate="true"
      :is-zhk="1"
      class="new-estate-card__actions"
    />

    <AppButton
      v-if="map"
      ref="cardLink"
      class="new-estate-card__go-button"
      :to="{ name: 'Детальная вторички', params: { alias: card.alias } }"
      :secondary="true"
    >
      Подробнее
    </AppButton>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import EstateActions from "./EstateActions.vue";
import EstateCardSlider from "./EstateCardSlider.vue";
import SubwayList from "./ui/SubwayList.vue";
import ButtonLocation from "./ui/ButtonLocation.vue";
import AppModal from "./ui/AppModal.vue";
import AppButton from "./ui/AppButton.vue";
import ModalMap from "./ModalMap.vue";
import { mapActions } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";

export default {
  name: "EstateNewCard",
  components: {
    EstateActions,
    EstateCardSlider,
    SubwayList,
    ButtonLocation,
    AppModal,
    AppButton,
    ModalMap,
  },

  provide() {
    return {
      cardLink: computed(() => this.cardLink),
    };
  },

  props: {
    card: {
      type: Object,
      default: () => {},
    },
    isSmall: {
      type: Boolean,
      default: null,
    },
    alias: {
      type: String,
      default: () => "",
    },
    map: {
      type: Boolean,
      default: null,
    },
    doUpdate: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        "_is-small": props.isSmall,
      })),
    };
  },

  data() {
    return {
      allCardsAreSmall: false,
      isHoverOn: false,
      openMap: false,
      formPage: null,
      random: 1,
    };
  },

  mounted() {
    this.goSmall();
    this.cardLink = this.$refs.cardLink.$el.href;
    this.emitter.on("updateSlider", this.updateSlider);
  },
  beforeUnmount() {
    this.emitter.off("updateSlider", this.updateSlider);
  },
  methods: {
    ...mapActions(useFilterStore, ["saveScrollForEstateList"]),
    toCard() {
      this.saveScrollForEstateList();
    },
    updateSlider() {
      if (this.doUpdate) {
        this.random = Math.random();
      }
    },
    goSmall() {
      const media = window.matchMedia("(max-width:1023px)");
      const listener = (event) => (this.allCardsAreSmall = event.matches);
      listener(media);

      media.addEventListener("change", listener);
    },

    replaceNumberQuarter(number) {
      let quarter;
      number == 1 ? (quarter = "I кв.") : "";
      number == 2 ? (quarter = "II кв.") : "";
      number == 3 ? (quarter = "III кв.") : "";
      number == 4 ? (quarter = "IV кв.") : "";
      return quarter;
    },

    estateShow(alias) {
      if (!this.allCardsAreSmall) {
        this.$router.push({
          name: "Детальная новостройки",
          params: {
            alias: alias,
          },
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* С таблета делаем две колонки у лонга */
.new-estate-card {
  $that: &;

  position: relative;
  text-align: left;
  display: flex;
  border: 1px solid #dcdfe1;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  background-color: $white;

  .map & {
    background-color: $white;
  }

  @include tablet {
    display: block;
  }

  &:hover {
    box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  &._is-small {
    display: block;
  }

  &__slider {
    flex: none;
    width: calc(452 / 1920 * 100vw);
    min-width: 292px;
    cursor: pointer;

    @media (min-width: 1920px) {
      width: 452px;
    }

    @include tablet {
      min-height: 212px;
      min-width: 100%;
    }

    ._is-small & {
      width: 100%;
      min-width: 100%;
      min-height: auto;
    }
  }

  &__info {
    position: relative;
    flex: 1 1 0%;
    padding: 40px calc(82 / 1920 * 100vw) 32px 40px;

    ._is-small & {
      padding: 16px 20px;

      @include laptop {
        padding: 14px 16px 0;
      }
    }

    @media (min-width: 1920px) {
      padding: 40px 132px 32px 40px;
    }

    @include desktop {
      padding: 30px calc(20 / 1535 * 100vw) 132px 30px calc(20 / 1535 * 100vw);
    }

    @include laptop {
      padding: 16px;
    }
  }

  &__info-head {
    display: flex;
    justify-content: space-between;

    ._is-small & {
      padding-bottom: 12px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  &__info-name {
    overflow: hidden;
    color: $color_dark-90;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    // padding-bottom: 16px;
    transition: color 0.25s ease-out;

    @include hover {
      color: $color_primary;
    }

    a {
      transition: color 0.25s ease-out;

      &:hover {
        color: $color_orange-70;
      }
    }

    @include desktop {
      font-size: 26px;
      line-height: 32px;
    }

    @include laptop {
      font-size: 20px;
      line-height: 26px;
      padding-bottom: 10px;
    }

    ._is-small & {
      height: calc(26px * 2);
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;

      @include laptop {
        font-size: 18px;
      }
    }
  }

  &__info-when,
  &__info-material {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    color: $color_dark-50;
    flex: none;
    margin-left: 28px;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 16px;

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      display: block;
      color: $color_dark-90;

      @include desktop {
        font-size: 16px;
      }
    }

    @include laptop {
      margin-bottom: 10px;
      margin-left: 22px;
    }

    @include phone {
      display: none;
    }

    ._is-small & {
      margin-left: 12px;
      padding-bottom: 6px;
      flex-direction: column;
      justify-content: flex-start;
      text-align: right;
      font-weight: 400;

      span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__info-material {
    @include tablet {
      display: none;
    }
  }

  &__info-address {
    position: relative;
    color: $color_dark-50;
    font-size: 16px;
    line-height: 24px;

    @include laptop {
      font-size: 14px;
      line-height: 18px;
    }

    ._is-small & {
      color: $color_dark-90;
      padding-top: 8px;
      font-size: 14px;
      line-height: 18px;
      padding-right: 58px;
    }
  }

  &__subway-list {
    @include laptop {
      padding-top: 8px;
    }
  }

  &__description {
    line-height: 24px;
    padding-top: 24px;

    a {
      transition: color 0.25s ease-out;

      &:hover {
        color: $color_dark-50;
      }
    }

    > div {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include laptop {
      font-size: 14px;
      line-height: 18px;
      padding-top: 10px;
    }

    @include tablet {
      display: none !important;
    }
  }

  &__go {
    padding-top: 16px;
    display: block;
    color: $color_primary;

    @include laptop {
      font-size: 14px;
      line-height: 18px;
      padding-top: 3px;
    }

    @include tablet {
      display: none !important;
    }
  }

  &__price-block {
    width: calc(318 / 1920 * 100vw);
    min-width: 246px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #d9d9d9;

    @media (min-width: 1920px) {
      width: 318px;
    }

    @include desktop {
      padding: 30px calc(20 / 1535 * 100vw);
    }

    @include laptop {
      padding: 16px;
    }

    @include tablet {
      min-width: 100%;
      width: 100%;
      border-left: 0;
    }

    ._is-small & {
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      padding: 16px 20px;
      border-left: none;

      @include laptop {
        padding: 14px 16px;
      }
    }
  }

  &__price {
    transition: opacity 0.25s 0.2s ease;
    text-align: right;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    span:first-child {
      font-weight: 400;
      font-size: 18px;
    }

    @include laptop {
      font-size: 20px;
      line-height: 26px;
    }

    ._is-small & {
      text-align: left;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;

      @include desktop {
        font-size: 18px;
      }
    }
  }

  &__mortgage {
    text-align: right;

    span {
      font-weight: 700;
    }

    svg {
      margin-left: 8px;
      display: inline-block;
      margin-bottom: 5px;
    }

    ._is-small & {
      flex: none;
      color: $color_dark-50;
      font-size: 14px;
      padding-left: 12px;

      svg {
        display: none;
      }

      span {
        color: $color_dark-90;
      }

      span:first-child {
        font-weight: 400;
      }
    }
  }

  &__actions {
    padding-top: 8px;

    ._is-small & {
      padding: 0 20px 20px;
      position: static;
      overflow: hidden;
      height: auto;
      opacity: 1;
      pointer-events: all;
      position: static;
      pointer-events: none;
      transition: max-height 0.3s 0.1s ease-out, opacity 0.25s 0.1s ease;
      box-shadow: 10px 70px 100px rgba(0, 0, 0, 0.1);

      @media (hover: hover) {
        border: 1px solid $white;
        z-index: 100;
        position: absolute;
        width: calc(100% + 2px);
        left: -1px;
        bottom: 0;
        transform: translate3d(0, 100%, 0);
        transform-origin: top;
        background-color: $white;
        height: 0;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
      }

      @include laptop {
        height: 100%;
        max-height: 100%;
        opacity: 1;
        pointer-events: all;

        @media (hover: hover) {
          height: auto;
          max-height: 0;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    #{$that}._is-small:hover & {
      height: auto;
      max-height: 98px;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__hover-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 40px;
    padding-right: 32px;

    * {
      flex: none;
      transform: translate3d(-16px, 0, 0);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.25s ease, transform 0.3s ease-out;

      &:first-child {
        @include tablet {
          display: none;
        }
      }

      &._is-liked {
        opacity: 1;
        pointer-events: all;
        transform: translate3d(0, 0, 0);
      }

      @include laptop {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        pointer-events: all;
      }
    }

    * + * {
      margin-left: 20px;
    }

    @for $i from 0 through 10 {
      *:nth-child(#{$i + 1}) {
        transition-delay: 0.14s * $i;
      }
    }

    #{$that}:hover & {
      * {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        pointer-events: all;
      }
    }

    &._mobile {
      padding: 0;
      padding-bottom: 8px;
      display: flex;
      justify-content: flex-end;
      position: relative;

      @media (min-width: 1280px) {
        display: none;
      }

      @include tablet {
        display: none;
      }
    }

    @include desktop {
      padding-top: 30px;
      padding-right: calc(20 / 1535 * 100vw);
    }

    @include laptop {
      display: none;
    }

    @include tablet {
      display: flex;
      padding-top: 16px;
      padding-right: 16px;
    }
  }

  &__go-button {
    width: 88%;
    margin: 24px auto;
    display: block;
  }
}
</style>
