<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item">
        <div class="breadcrumbs__go">
          <router-link to="/">
            Главная
          </router-link>
        </div>
        <app-svg
          id="arrow-right"
          class="breadcrumbs__arrow"
          width="6"
          height="10"
        />
      </li>
      <li
        v-if="crumbsIsFull"
        class="breadcrumbs__item"
      >
        <app-svg
          id="ellipsis"
          width="12"
          height="4"
        />
        <app-svg
          id="arrow-right"
          style="margin-left: 6px"
          width="6"
          height="10"
        />
      </li>
      <li
        v-for="(crumb, index) in crumbs"
        :key="index"
        class="breadcrumbs__item"
      >
        <div class="breadcrumbs__go">
          <router-link :to="crumb.path">
            {{ crumb.name }}
          </router-link>
        </div>
        <app-svg
          v-if="index != crumbs.length - 1"
          id="arrow-right"
          class="breadcrumbs__arrow"
          width="6"
          height="10"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "AppBreadcrumbs",

  props: {
    crumbs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      crumbsIsFull: false,
    };
  },

  // watch: {
  //   "$route.path"() {
  //     this.checkPath();
  //   },
  // },

  // mounted() {
  //   this.checkPath();
  // },

  // methods: {
  //   checkPath() {
  //     this.crumbs = this.$route.matched;

  //     let pathArray = this.$route.path.split("/");
  //     pathArray.shift();

  //     // if (pathArray.length < 2) {
  //     // } else if (pathArray.length === 2) {
  //     // } else {
  //     //   this.crumbsIsFull = true;
  //     // }
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.breadcrumbs {
  $that: &;

  margin-bottom: 54px;

  @include laptop {
    margin-bottom: 74px;
  }

  @include tablet {
    display: none;
  }

  &__list {
    display: flex;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    & + & {
      margin-left: 6px;
    }

    &:last-child {
      #{$that}__go {
        pointer-events: none;
      }
    }
  }

  &__go {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: $color_dark-40;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 6px;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $color_dark-50;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: opacity 0.25s ease-out;
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  &__arrow {
    color: $color_grey-15;
  }
}
</style>
