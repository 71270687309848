import { parse } from "@/plugins/helpers.js";

const setInterfaceFromQuery = {
  setInterfaceState(route) {
    const query = parse(route.query);
    // для слайдеров установим значения в setRanges => setRangeForSlider
    // queryName stateName
    this.setStateIfExistParamsInUrl(
      [
        ["type", "type"],
        ["isZhk", "isZhk"],
        ["newFlat", "newFlat"],
        ["lastFloor", "lastFloor"],
        ["notLastFloor", "notLastFloor"],
        ["notFirstFloor", "notFirstFloor"],
        ["isSNT", "isSNT"],
        ["isIZHS", "isIZHS"],
        ["isDNP", "isDNP"],
      ],
      query
    );
    this.setCity(query, route);
    this.setSubLocality(query);
    this.setRooms(query);
    this.setFurnishState(query);
    this.setDeadlinesState(query);
    this.setZhkState(query);

    if (query.category) {
      this.setCategoryState(query.category[0]);
    }

    this.setTypeBuyOrRent(query.type);
  },
  setFurnishState(query) {
    if (query.furnish) {
      this.setFurnish(query.furnish);
    }
  },
  setZhkState(query) {
    if (query.ids) {
      this.query.offerZhkList.forEach((el, i) => {
        query.ids.forEach((id) => {
          if (el.id === id) {
            this.query.offerZhkList[i].checked = true;
          }
        });
      });
      this.setCurrentZhkId();
    }
  },
  setDeadlinesState(query) {
    if (query.deadlines) {
      query.deadlines.forEach((deadline) => {
        this.setQuartal(deadline);
      });
    }
  },
  setRooms(query) {
    if (query.rooms) {
      this.query.currentRoomListRange.forEach((room, i) => {
        this.query.currentRoomListRange[i].checked = query.rooms.includes(room.value);
      });
      this.query.currentRoomListValues = query.rooms;
    }
  },
  setSubLocality(query) {
    if (query.subLocality && query.subLocality.length !== 0) {
      this.query.subLocalitiesAreDisabled = false;
      this.query.currentSubLocal = query.subLocality;
    }
  },
  async setCity(query, route) {
    if (query.city) {
      await this.setCurrentCity(query.city, route, "NOT_SET_SUBLOCAL");
    }
  },
  setStateIfExistParamsInUrl(paramsArr, query) {
    paramsArr.forEach((param) => {
      const existParam = query.hasOwnProperty(param[0]);
      if (existParam) {
        const value = JSON.parse(query[param[0]]);
        this.query[param[1]] = value;
      }
    });
  },
  setCategoryState(id) {
    this.query.category = [id];
    if (id) {
      this.query.mainCategories.forEach((el, i) => {
        const isEqual = el.id === id;
        isEqual ? (this.query.mainCategories[i].checked = true) : (this.query.mainCategories[i].checked = false);
      });

      this.query.categoriesList.forEach((el, i) => {
        const isEqual = el.id === id;
        isEqual ? (this.query.categoriesList[i].checked = true) : (this.query.categoriesList[i].checked = false);
      });
    }
  },
  async setCategoryRefactor(category, route) {
    this.query.page = 1;
    this.query.category = [category.id];
    this.setCategoryState(category.id);

    this.setCurrentEstateTypeByCategory(category.id);
    this.closeAdditionalFiltersIfCommerseSelected(category.id);
    await this.getRangeForCategory(route);
  },
  setTypeBuyOrRent(payload) {
    this.query.type = payload;
  },
};
export default setInterfaceFromQuery;
