<!-- <PagePagination
        :total-pages="11"
        :total="113"
        :per-page="10"
        :current-page="currentPage"
        :go-to-first-page="true"
        :go-to-last-page="true"
        @pagechanged="onPageChange"
/> -->

<!-- Для родителя: -->

<!--
    data() {
      return {
        currentPage: 1,
      }
    },

    methods: {
      onPageChange(page) {

        this.currentPage = page;
      }
    },
-->

<template>
  <ul class="page-pagination">
    <li
      v-show="currentPage >= 3"
      class="page-pagination__item"
    >
      <button
        class="page-pagination__button"
        type="button"
        :disabled="isInFirstPage"
        aria-label="Go to first page"
        @click="onClickFirstPage"
      >
        1
      </button>
    </li>

    <li
      v-show="currentPage !== 1"
      class="page-pagination__item"
    >
      <button
        class="page-pagination__button"
        type="button"
        :disabled="isInFirstPage"
        aria-label="Go to previous page"
        @click="onClickPreviousPage"
      >
        <app-svg
          id="arrow-left"
          class="page-pagination__arrow"
          width="6"
          height="12"
        />
      </button>
    </li>

    <li
      v-for="(page, index) in pages"
      :key="index"
      class="page-pagination__item"
    >
      <button
        class="page-pagination__button"
        type="button"
        :disabled="page.isDisabled"
        :class="{ '_is-active': isPageActive(page.name) }"
        :aria-label="`Go to page number ${page.name}`"
        @click="onClickPage(page.name)"
      >
        {{ page.name }}
      </button>
    </li>

    <li
      v-show="currentPage < totalPages"
      class="page-pagination__item"
    >
      <button
        class="page-pagination__button"
        type="button"
        :disabled="isInLastPage"
        aria-label="Go to next page"
        @click="onClickNextPage"
      >
        <app-svg
          id="arrow-right"
          class="page-pagination__arrow"
          width="6"
          height="12"
        />
      </button>
    </li>

    <li
      v-show="currentPage <= totalPages - 4"
      class="page-pagination__item"
    >
      <button
        class="page-pagination__button"
        type="button"
        :disabled="isInLastPage"
        aria-label="Go to last page"
        @click="onClickLastPage"
      >
        {{ totalPages }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PagePagination",

  props: {
    goToFirstPage: {
      type: Boolean,
      default: false,
    },
    goToLastPage: {
      type: Boolean,
      default: false,
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },

  emits: ["pagechanged"],

  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        // return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },

    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },

    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },

    isInFirstPage() {
      return this.currentPage === 1;
    },

    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },

  methods: {
    onClickFirstPage() {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", 1);
    },

    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },

    onClickPage(page) {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", page);
    },

    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },

    onClickLastPage() {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", this.totalPages);
    },

    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-pagination {
  list-style-type: none;

  &__item {
    display: inline-block;
  }

  &__button {
    @include flex-center;

    position: relative;
    font-family: "IBM Plex Sans", sans-serif;
    width: 48px;
    height: 48px;
    font-size: 14px;
    line-height: 18px;
    color: $color_dark-50;
    transition: background-color 0.25s ease-out;

    &._is-active {
      pointer-events: none;
      background-color: $color_grey-2;
      color: $color_dark-90;

      &::after {
        content: "";
        width: 16px;
        height: 2px;
        background-color: $color_primary;
        border-radius: 1px;
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }

    &:hover {
      background-color: $color_grey-2;
    }

    @include phone {
      width: 40px;
      height: 40px;
    }
  }

  &__arrow {
    color: $color_dark-90;
  }
}
</style>
