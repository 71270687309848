<template>
  <VueNextSelect
    :model-value="selectedZhkItems"
    multiple
    class="filter-zhk"
    :class="{
      _open: multiSelectOpen,
      'select-all': selectedZhkItems.length === currentZhkOptions.length - 1,
    }"
    :max-height="396"
    :options="currentZhkOptions"
    @selected="onSelect"
    @removed="onRemove"
    @click="onMultiSelect"
    @closed="onMultiSelectClosed"
  >
    <template #label="{ selected }">
      <template v-if="selected.length">
        <span>{{ selected[0] }}</span>
        <span>{{ `${selected.length > 1 ? `+ ${selected.length - 1}` : ""}` }}</span>
        <span class="select-icon" />
      </template>
      <template v-else-if="selectedZhkItems.length">
        <span>
          {{ `${selectedZhkItems[0]} ` }}
        </span>
        <span>{{ ` ${selectedZhkItems.length > 1 ? `+ ${selectedZhkItems.length - 1}` : ""}` }}</span>
        <span class="select-icon" />
      </template>
      <template v-else> <span>Жилой Комплекс</span> <span class="select-icon" /> </template>
    </template>
    <template #dropdown-item="{ option }">
      <span :class="{ _active: selectedZhkItems.includes(option) }">
        {{ option }}
      </span>
    </template>
  </VueNextSelect>
</template>

<script>
import { useFilterStore } from "@/store/filters/filterStore.js";
import { mapActions, mapState } from "pinia";
import { setParamsInUrl } from "@/plugins/helpers.js";

import VueNextSelect from "vue-next-select";

export default {
  name: "FilterZHK",
  components: { VueNextSelect },
  data() {
    return {
      multiSelectOpen: false,
    };
  },
  computed: {
    ...mapState(useFilterStore, ["currentZhkOptions", "selectedZhkItems", "query"]),
  },
  methods: {
    ...mapActions(useFilterStore, ["setCurrentZhk", "deleteCurrentZhk", "returnRequiredParams"]),
    onMultiSelectClosed() {
      this.multiSelectOpen = false;
    },
    onMultiSelect() {
      if (
        event.target.parentElement.parentElement.className === "vue-select-header" ||
        event.target.parentElement.className === "vue-select-header" ||
        event.target.className === "vue-select-header"
      ) {
        this.multiSelectOpen = this.multiSelectOpen ? false : true;
      }
    },
    async onSelect(zhk) {
      this.setCurrentZhk(zhk);
      await setParamsInUrl(this, this.$route.query, {
        ids: this.query.currentZhk,
      });
      this.emitter.emit("load-with-params");
    },
    async onRemove(zhk) {
      this.deleteCurrentZhk(zhk);
      await setParamsInUrl(this, this.$route.query, {
        ids: this.query.currentZhk,
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style lang="scss" scoped>

._select-map-filter {
	
}
.select-icon {
  height: 35px;
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;

  position: absolute;
  width: 40px;
  right: -7px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;

  &::before {
    content: "";
    margin: 0;
    border: 0;
    width: 1px;
    height: 6px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-55deg) translate(-50%, -50%);
  }

  &::after {
    content: "";
    border: 0;
    width: 1px;
    height: 6px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(55deg) translate(-50%, -50%);
  }
}
</style>
