<template>
  <div class="call-request-modal">
    <h3 class="call-request-modal__title">
      {{ title }}
    </h3>

    <form
      ref="feedbackForm"
      class="call-request-modal__form"
      @submit.prevent="submitForm"
    >
      <FormInput
        :key="updateKey"
        v-model="form.name"
        class="call-request-modal__input"
        :class="{ _error: invalid && !form.name }"
        name="name"
        label="Ваше имя*"
        @blur="onInput"
      />
      <FormInput
        :key="updateKey"
        v-model="form.email"
        type="email"
        class="call-request-modal__input"
        name="email"
        label="Ваш email"
      />
      <FormInput
        :key="phoneKey"
        v-model="form.phone"
        class="call-request-modal__input"
        type="tel"
        name="phone"
        label="Контактный телефон*"
        :class="{ _error: invalid && !form.phone }"
        @blur="onPhoneInput"
      />

      <AppButton
        class="call-request-modal__action"
        :to-send="true"
        :sending="sending"
      >
        Отправить
      </AppButton>
      <FormCheck
        v-model="formIsCheck"
        :class="{ _error: invalid && !checkIsValid }"
        class="call-request-modal__check"
        @click="onChange"
      >
        Даю согласие на обработку
        <a
          href="#"
          target="_blank"
        >персональных данных </a>
      </FormCheck>
      <!--   <div v-if="invalid" class="_invalid">Заполните все поля!</div> -->
    </form>
  </div>
</template>

<script>
import FormInput from "@/components/form/FormInput.vue";
import FormCheck from "@/components/form/FormCheck.vue";
import AppButton from "@/components/ui/AppButton.vue";

export default {
  name: "ModalCallRequest",
  components: { FormCheck, FormInput, AppButton },

  props: {
    offer: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Оставить заявку",
    },
    nameIsValid: {
      type: String,
      default: null,
    },
    phoneIsValid: {
      type: String,
      default: null,
    },
    checkIsValid: {
      type: Boolean,
      default: null,
    },
    cardLink: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      form: {
        page: window.location.href,
        message: this.cardLink !== null ? this.cardLink : "",
      },
      invalid: false,
      errors: [],
      formIsCheck: false,
      updateKey: 0,
      phoneKey: 0,
      sending: false,
    };
  },

  methods: {
    isValid() {
      const nameIsValid = !!this.form.name;
      const phoneIsValid = !!this.form.phone;
      const checkIsValid = !!this.formIsCheck;

      if (nameIsValid && phoneIsValid && checkIsValid) return true;
    },

    onChange() {
      this.formIsCheck = !this.formIsCheck;
    },

    onInput(event) {
      const inputValue = event.target.value;
      const inputLength = inputValue.length;

      if (inputLength < 1) {
        this.form.name = null;
      }
    },

    onPhoneInput(event) {
      const inputValue = event.target.value;
      const inputLength = inputValue.length;

      if (inputLength < 18) {
        this.phoneKey += 1;
        this.form.phone = null;
      }
    },

    submitForm() {
      let api;
      this.offer ? (api = "/api/sendFeedback?type=offer") : (api = "/api/sendFeedback?type=request");
      if (this.isValid()) {
        this.errors = [];
        this.invalid = false;
        this.sending = true;
        this.axios
          .post(api, this.form)
          .then((response) => {
            if (response.status) {
              let form = this.form;

              form = {};

              Object.keys(form).forEach(function (key) {
                form[key] = "";
              });

              this.formIsCheck = false;
              this.sending = false;
              this.clearForm();
              this.updateKeyValue();
              this.phoneKey += 1;
              this.form.phone = null;
              this.form.name = null;
              this.$emit("form-success-sent");
            }
          })
          .catch((error) => {
            this.sending = false;
            /* this.errors = error.response.data.errors; */
          });
      } else {
        this.invalid = true;
      }
    },

    clearForm() {
      const form = this.$refs.feedbackForm;
      const formInputs = form.querySelectorAll("._fixed-label");

      form.reset();

      formInputs.forEach((input) => {
        input.classList.remove("_fixed-label");
      });
    },

    updateKeyValue() {
      this.updateKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.call-request-modal {
  $that: &;

  padding: 68px 104px;

  @include tablet {
    padding: 58px;
  }

  @include phone {
    padding: 38px 28px;
  }

  @include phone {
    padding: 28px 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #141c1a;
    margin-bottom: 28px;
  }

  &__caption {
    color: #141c1a;
    text-align: center;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__form {
  }

  &__input {
    & + & {
      margin-top: 8px;
    }
  }

  &__action {
    margin-top: 28px;
  }

  &__check {
    width: 100%;
    margin-top: 16px;
  }

  ._invalid {
    color: red;
    padding-top: 8px;
  }
}
</style>
