<template>
  <div ref="sorting" class="sorting">
    <div class="sorting__head">
      <div @click="toggleMenu" class="sorting__head-icon">
        <app-svg id="sorting" width="16" height="16" />
      </div>

      <div class="sorting__head-name">
        Сортировка: <span>{{ label }}</span>
      </div>
    </div>

    <div class="sorting__body">
      <ul class="sorting__list">
        <li
          v-for="(item, index) in listOptions"
          :key="item"
          class="sorting__list-item"
          :data-value="item.sortBy"
          @click="clickOption(item, index)"
        >
          <span> {{ item.title }}</span>
        </li>
      </ul>
    </div>

    <Teleport to="body">
      <div class="sorting__body-mobile" :class="{ _open: menuIsOpen }">
        <div @click="toggleMenu" class="sorting__body-backdrop"></div>
        <div class="sorting__body-inner">
          <div class="sorting__body-top">
            <div @click="toggleMenu" class="sorting__body-close">
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 11L0.999999 6L5 1" stroke="#F4691B" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Назад
            </div>
            <div class="sorting__body-title">Сортировать</div>
          </div>

          <ul class="sorting__list">
            <li
              v-for="(item, index) in listOptions"
              :key="item"
              class="sorting__list-item"
              :data-value="item.sortBy"
              @click="clickOption(item, index)"
            >
              <span> {{ item.title }}</span>

              <FormRadio name="form" :checked="query.sortBy === item.sortBy && query.orderBy === item.orderBy" />
            </li>
          </ul>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import TransitionFade from "../transitions/TransitionFade.vue";
import FormRadio from "../form/FormRadio.vue";

import { mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";

export default {
  name: "AppSorting",
  components: { TransitionFade, FormRadio },
  computed: {
    ...mapState(useFilterStore, ["query"]),
  },

  emits: ["sorting"],

  data() {
    return {
      label: "По умолчанию",
      menuIsOpen: false,
      listOptions: [
        { title: "По умолчанию", sortBy: null, orderBy: null },
        { title: "Цена по возрастанию", sortBy: "price", orderBy: "asc" },
        { title: "Цена по убыванию", sortBy: "price", orderBy: "desc" },
        { title: "Площадь по возрастанию", sortBy: "area", orderBy: "asc" },
        { title: "Площадь по убыванию", sortBy: "area", orderBy: "desc" },
      ],
    };
  },

  mounted() {
    this.$refs.sorting.classList.add("_is-visible");
  },

  methods: {
    toggleMenu() {
      this.menuIsOpen = !this.menuIsOpen;

      const body = document.querySelector("body");
      const phone = window.matchMedia("(max-width:639px)");

      if (phone.matches) {
        body.classList.add("_overflow-hidden");
      }

      if (this.menuIsOpen) return;
      body.classList.remove("_overflow-hidden");
    },

    clickOption(item) {
      this.label = item.title;
      const sortBy = item.sortBy;
      const orderBy = item.orderBy;

      this.sort(sortBy, orderBy);
      // this.menuIsOpen = false;

      // document.querySelector("body").classList.remove("_overflow-hidden");
    },

    sort(sortBy, orderBy) {
      /*     this.axios.get('/api/getOffersList', {
            params: { sortBy: sortBy, orderBy: orderBy }
          })
            .then(res => {

              // this.options.filtersList = res.data;

            }) */

      const sorting = [sortBy, orderBy];

      this.$emit("sorting", sorting);
    },
  },
};
</script>

<style scope lang="scss">
.sorting {
  $that: &;

  position: relative;
  transition: opacity 0.25s 1.5s ease-out;
  opacity: 0;
  cursor: pointer;

  &:hover {
    background-color: $color_grey-2;

    .sorting__body {
      opacity: 1;
      visibility: visible;
    }

    @include laptop {
      background-color: transparent;
    }
  }

  &._is-visible {
    opacity: 1;
  }

  &__head {
    padding: 4px 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.25s ease;

    @include laptop {
      padding: 0;
      justify-content: end;
    }
  }

  &__head-icon {
    flex: none;
    margin-right: 10px;

    @include laptop {
      padding: 16px;
      margin-right: 0;
      background-color: #f4f4f4;
    }
  }

  &__head-name {
    font-size: 16px;
    line-height: 24px;

    span {
      font-weight: 700;
      color: $color_primary;
    }

    @include laptop {
      display: none;
    }
  }

  &__body {
    max-width: 338px;
    width: 100%;
    position: absolute;
    background-color: $white;
    z-index: 9999;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease, visibility 0.25s ease;

    @include laptop {
      right: 0;
      width: 278px;
    }
    @include phone {
      display: none !important;
    }

    &-mobile {
      display: none;
      @include phone {
        display: block;
        position: fixed;
        z-index: 100;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        background: rgba(35, 41, 48, 0.5);
        backdrop-filter: blur(6px);

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        transition: all 0.4s ease-in-out;
        &._open {
          opacity: 1;
          visibility: visible;
        }

        & .sorting__list {
          width: 100%;
          padding: 0 !important;
          margin: 0 !important;

          &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 44px;
            padding: 10px 16px;

            position: relative;

            &::after {
              content: "";
              display: block;
              position: absolute;
              left: -16px;
              bottom: 0;
              width: 100vw;
              height: 1px;
              background: #ebedee;
            }
          }
        }
      }
    }

    &-backdrop {
      width: 100%;
      height: auto;
      flex: 1;
    }
    &-inner {
      padding: 17px 16px 44px;
      background: #ffffff;
      box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
        0px 6px 8px rgba(35, 41, 48, 0.07);
    }
    &-top {
      margin-bottom: 16px;
      position: relative;
    }
    &-top,
    &-close {
      display: flex;
      align-items: center;
    }
    &-close {
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.009em;
      text-transform: uppercase;
      color: #f4691b;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      & svg {
        margin-right: 16px;
      }
    }
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #232930;
      flex: 1;
      text-align: center;
    }
  }

  &__list {
    padding: 18px 0;

    @include laptop {
      width: 338px;
    }
  }

  &__list-item {
    transition: background-color 0.25s ease;
    padding: 6px 40px;
    cursor: pointer;

    &:hover {
      background: #fdf0e7;
    }
  }
}
</style>
