<template>
  <section class="reviews-section">
    <!-- <pre>{{ reviewsList }}</pre> -->

    <div
      v-if="reviewsPage"
      class="reviews-section__inner"
    >
      <div class="reviews-section__head">
        <app-title
          :h1="true"
          :no-icon="true"
        >
          {{ h1Seo !== null ? h1Seo : "Отзывы" }}
        </app-title>

        <AppButton
          class="reviews-section__action"
          @click="isOpenWriteReviewModal = true"
        >
          Оставить отзыв
        </AppButton>
      </div>

      <ul
        v-if="reviewsList"
        :key="componentKey"
        class="reviews-section__list"
      >
        <li
          v-for="(review, index) in reviewsList"
          :key="index"
          class="reviews-section__list-item"
        >
          <ReviewsSectionCard :review="review" />
        </li>
      </ul>

      <div v-else>
        <span>Отзывы не найдены.</span>
      </div>

      <div class="reviews-section__pages">
        <AppButton
          v-if="totalPages > 1"
          :secondary="true"
          @click="showMore"
        >
          Показать еще
        </AppButton>
        <PagePagination
          v-if="totalPages > 1"
          :total-pages="totalPages"
          :total="total"
          :per-page="perPage"
          :current-page="currentPage"
          @pagechanged="onPageChange"
        />
      </div>
    </div>

    <div
      v-else
      class="reviews-section__inner"
    >
      <div class="reviews-section__header">
        <app-title class="reviews-section__title">
          Отзывы наших клиентов
        </app-title>
        <div class="reviews-section__nav-swiper">
          <app-svg
            id="arrow-left"
            class="_prev"
            width="15"
            height="31"
          />
          <app-svg
            id="arrow-right"
            class="_next"
            width="15"
            height="31"
          />
        </div>
      </div>

      <swiper
        class="reviews-section__swiper"
        :breakpoints="{
          320: { slidesPerView: 1, centerdSlides: true },
          640: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }"
        :speed="1000"
        :modules="modules"
        :navigation="{ nextEl: '._next', prevEl: '._prev' }"
        :pagination="{
          el: '.reviews-section__pagination',
          dynamicBullets: true,
        }"
      >
        <swiper-slide
          v-for="(review, index) in reviewsList"
          :key="index"
          class="reviews-section__slide"
        >
          <ReviewsSectionCard :review="review" />
        </swiper-slide>
      </swiper>
      <div class="reviews-section__pagination _pagination" />
      <div class="reviews-section__bottom">
        <AppButton
          class="reviews-section__action"
          @click="isOpenWriteReviewModal = true"
        >
          Оставить отзыв
        </AppButton>
        <div class="reviews-section__link">
          <AppLink to="/reviews">
            Все отзывы
          </AppLink>
        </div>
      </div>
    </div>

    <teleport
      v-if="isOpenWriteReviewModal"
      to="body"
    >
      <AppModal v-model="isOpenWriteReviewModal">
        <ModalWriteReview @form-success-sent="formSuccessSent" />
      </AppModal>
    </teleport>
    <teleport
      v-if="isOpenPopupSuccessfully"
      to="body"
    >
      <PopupSuccessfully
        title="Отзыв успешно оптравлен!"
        text="Спасибо за обратную связь."
        text-second="Ваше мнение очень важно для нас!"
        @popup-close="isOpenPopupSuccessfully = false"
      />
    </teleport>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import ReviewsSectionCard from "@/components/ReviewsSectionCard.vue";
import AppButton from "@/components/ui/AppButton.vue";
import AppModal from "./ui/AppModal.vue";
import AppLink from "@/components/ui/AppLink.vue";
import ModalWriteReview from "./ModalWriteReview.vue";
import PopupSuccessfully from "@/components/PopupSuccessfully.vue";
import PagePagination from "@/components/ui/PagePagination.vue";

export default {
  name: "ReviewsSection",
  components: {
    Swiper,
    SwiperSlide,
    ReviewsSectionCard,
    AppButton,
    AppModal,
    AppLink,
    ModalWriteReview,
    PopupSuccessfully,
    PagePagination,
  },

  inject: ["h1Seo"],

  props: {
    reviewsPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      componentKey: 0,
      currentPage: 1,
      perPage: 9,
      total: 0,
      totalPages: 0,
      isOpenWriteReviewModal: false,
      isOpenPopupSuccessfully: false,
      reviewsList: [],
      modules: [Navigation, Pagination],
      agent: this.$route.query.agent,
    };
  },

  watch: {
    "$route.query.page"() {
      this.getReviews(this.$route.query.page !== undefined ? this.$route.query.page : 1);
      this.componentKey += 1;
    },
  },

  beforeMount() {
    this.getReviews(this.$route.query.page !== undefined ? this.$route.query.page : 1);
  },

  methods: {
    onPageChange(page) {
      this.$router.push(
        page == 1
          ? {
              name: "Отзывы",
            }
          : {
              name: "Отзывы",
              query: {
                page: page,
              },
            }
      );
    },

    getReviews(page) {
      this.axios
        .get("/api/reviews", {
          params: {
            page: page,
            limit: this.perPage,
            agent: this.$route.query.agent,
          },
        })
        .then(({ data }) => {
          this.reviewsList = data.data;
          this.currentPage = data.meta.current_page;
          this.total = data.meta.total;
          this.totalPages = Math.ceil(this.total / this.perPage);
        });
    },

    showMore() {
      this.perPage += 9;
      this.getReviews();
    },

    formSuccessSent() {
      this.isOpenPopupSuccessfully = true;
      this.isOpenWriteReviewModal = false;

      setTimeout(() => {
        this.isOpenPopupSuccessfully = false;
      }, 4000);
    },
  },
};
</script>

<style lang="scss" scoped>
.reviews-section {
  $that: &;

  overflow: hidden;
  padding: 64px 0;

  .reviews-page & {
    padding: 0;
  }

  @include laptop {
    padding: 48px 0;
  }

  @include phone {
  }

  &__inner {
    @extend %container;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__title {
  }

  &__nav-swiper {
    flex: none;
    padding-left: 24px;
    display: flex;
    color: $color_primary;

    * {
      flex: none;
      cursor: pointer;
      transition: color 0.25s ease, transform 0.25s ease;

      &.swiper-button-disabled {
        color: $color_grey-15;
      }

      &:hover {
        transform: scale(1.2);
      }
    }

    *:first-child {
      margin-right: 56px;
    }

    @include phone {
      display: none;
    }
  }

  &__swiper {
    overflow: visible;
    margin: 0 -16px;

    @include desktop {
      margin: 0 -10px;
    }

    @include phone {
      margin: 0;
    }
  }

  &__slide {
    padding: 0 16px;
    opacity: 0;
    transition: opacity 0.25s 0.5s ease;

    &.swiper-slide-active {
      opacity: 1;
      transition: opacity 0.25s ease;

      & + #{$that}__slide {
        opacity: 1;
        transition: opacity 0.25s ease;

        @include phone {
          opacity: 0;
        }
      }

      & + #{$that}__slide + #{$that}__slide {
        opacity: 1;
        transition: opacity 0.25s ease;

        @include tablet {
          opacity: 0;
        }
      }
    }

    @include desktop {
      padding: 0 10px;
    }

    @include phone {
      padding: 0;
    }
  }

  &__pagination {
    padding: 12px 0;

    @media (min-width: 640px) {
      display: none;
    }
  }

  &__bottom {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 64px auto 0;

    @include laptop {
      margin: 30px auto 0;
    }

    @include phone {
      flex-direction: column;
    }
  }

  &__link {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @include phone {
      position: static;
      transform: none;
      display: flex;
      justify-content: end;
      margin-top: 30px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;

    @include phone {
      flex-direction: column;

      h2 {
        padding-bottom: 32px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px;

    @include tablet {
      margin: 0;
    }
  }

  &__list-item {
    flex: 0 0 33.333%;
    padding: 32px 14px 0;

    @include laptop {
      flex: 0 0 50%;
    }

    @include tablet {
      flex: 1 1 100%;
      padding: 26px 0 0;
    }
  }

  &__pages {
    position: relative;
    margin-top: 64px;

    @include flex-center;

    @include tablet {
      flex-direction: column;
      margin-top: 30px;
    }

    .page-pagination {
      position: absolute;
      right: 0;

      @include tablet {
        position: static;
        margin-top: 24px;
      }
    }
  }
}
</style>
