const getters = {
  minPriceValue: (state) => state.query.minPriceValue,
  maxPriceValue: (state) => state.query.maxPriceValue,
  minPriceRange: (state) => state.query.minPriceRange,
  maxPriceRange: (state) => state.query.maxPriceRange,

  minFloorValue: (state) => state.query.minFloorValue,
  maxFloorValue: (state) => state.query.maxFloorValue,
  minFloorRange: (state) => state.query.minFloorRange,
  maxFloorRange: (state) => state.query.maxFloorRange,

  notLastFloorCheck: (state) => !!state.query.notLastFloor,
  lastFloorCheck: (state) => !!state.query.lastFloor,
  notFirstFloorCheck: (state) => !!state.query.notFirstFloor,

  minSquareValue: (state) => state.query.minSquareValue,
  maxSquareValue: (state) => state.query.maxSquareValue,
  minSquareRange: (state) => state.query.minSquareRange,
  maxSquareRange: (state) => state.query.maxSquareRange,

  minKitchenValue: (state) => state.query.minKitchenValue,
  maxKitchenValue: (state) => state.query.maxKitchenValue,
  minKitchenRange: (state) => state.query.minKitchenRange,
  maxKitchenRange: (state) => state.query.maxKitchenRange,

  minAreaValue: (state) => state.query.minAreaValue,
  maxAreaValue: (state) => state.query.maxAreaValue,
  minAreaRange: (state) => state.query.minAreaRange,
  maxAreaRange: (state) => state.query.maxAreaRange,

  minRoadDistanceValue: (state) => state.query.minRoadDistanceValue,
  maxRoadDistanceValue: (state) => state.query.maxRoadDistanceValue,
  minRoadDistanceRange: (state) => state.query.minRoadDistanceRange,
  maxRoadDistanceRange: (state) => state.query.maxRoadDistanceRange,

  currentRoomListValues: (state) => state.query.currentRoomListValues,
  currentRoomListRange: (state) => state.query.currentRoomListRange.filter((room) => !room.disabled),

  studioIsAvailable: (state) => {
    const rooms = state.query.currentRoomListRange.filter((room) => !room.disabled).map((room, i, arr) => room.value);
    return rooms.includes(0);
  },

  currentCitiesRange: (state) => {
    const cityWithouSelectedCity = state.query.currentCitiesRange.filter((el) => {
      if (state.query.currentCity.length !== 0) {
        return el !== state.query.currentCity;
      } else return true;
    });
    return cityWithouSelectedCity;
  },
  currentSubLocalRange: (state) => state.query.currentSubLocalRange,
  //   currentSubLocalRangeWithoutSelected: (state) =>
  //     state.query.currentSubLocalRange.filter((el) => !state.query.currentSubLocal.includes(el)),
  currentSubLocalRangeWithoutSelected: (state) => state.query.currentSubLocalRange,

  currentCategory: (state) => state.query.currentCategory.title,
  estateTypesList: (state) => state.query.estateTypeArray.map((el) => el.name),

  //   currentZhkOptions: (state) => state.query.offerZhkList.filter((el) => !el.checked).map((el) => el.title),
  currentZhkOptions: (state) => state.query.offerZhkList.map((el) => el.title),
  selectedZhkItems: (state) => state.query.offerZhkList.filter((el) => el.checked).map((el) => el.title),
  currentCategoryOnMain: (state) => {
    try {
      const find = state.query.mainCategories.find((el) => el.checked);
      return find.title;
    } catch (e) {
      return "Не выбрано";
    }
  },
  currentCategoryOnLateralPage: (state) => {
    const current = state.query.mainCategories.find((el) => el.id === state.query.category[0]);
    if (current) {
      return current.title;
    } else {
      return "Не выбрано";
    }
  },
  flatChecked: (state) => !!state.query.newFlat,
  isSNTCheck: (state) => !!state.query.isSNT,
  isDNPCheck: (state) => !!state.query.isDNP,
  isIZHSCheck: (state) => !!state.query.isIZHS,
};

export default getters;
