<!-- <AppDate>2022-09-22T18:04:09.000000Z</AppDate> -->

<template>
  <div class="date">
    <app-svg id="calendar" />
    <span v-date><slot /></span>
  </div>
</template>

<script>
export default {
  name: "AppDate",
};
</script>

<style lang="scss" scoped>
.date {
  display: flex;
  align-items: center;
  height: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $color_dark-40;

  svg {
    width: 14px;
    height: 15px;
    margin-right: 9px;
  }
}
</style>
