<template>
  <section class="basement">
    <ul class="basement__basement-list">
      <li class="basement__basement-item">
        © <span>{{ currentYear }}</span>, Группа компаний «КВС»
      </li>

      <li class="basement__basement-item">
        Дизайн и разработка:
        <a
          href="https://rekastudio.ru/"
          target="_blank"
          class="basement__basement-go"
        >Reka Digital</a>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "TheBasement",

  data() {
    return {
      currentYear: 2022,
    };
  },

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  },
};
</script>

<style lang="scss" scoped>
.basement {
  $that: &;

  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: $color_dark-50;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &__basement-list {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;

    @include laptop {
      padding-bottom: 24px;
    }

    @include phone {
      flex-direction: column;
      padding-top: 16px;
      padding-bottom: 32px;
    }

    @include phone-s {
      align-items: flex-start;
    }
  }

  &__basement-item {
    position: relative;
    text-align: center;
    color: $color_dark-40;
    font-size: 14px;
    line-height: 18px;

    &:last-child {
      flex: none;
      padding-left: 48px;

      @include phone {
        padding-left: 0;
      }
    }

    @include phone {
      padding-top: 16px;
    }
  }

  &__basement-go {
    position: relative;
    overflow: hidden;
    background: linear-gradient(
      to right,
      $color_primary,
      //конечный цвет
      $white 50%,
      $color_dark-40 50% // изначальный цвет
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 475ms ease-in-out;

    &:hover {
      background-position: 0 100%;
    }
  }
}
</style>
