import gsap from "gsap";

export default {
  name: "magnet",

  mounted(element, binding) {
    element.classList.add("will-change");

    element.addEventListener("mousemove", (event) => {
      let movementRatio;

      if (binding.value) {
        movementRatio = binding.value;
      } else {
        movementRatio = 18;
      }

      gsap.to(element, {
        ease: "linear",
        duration: 0.12,
        x: (event.clientX / window.innerWidth) * movementRatio,
        y: (event.clientY / window.innerHeight) * movementRatio,
        z: 0,
      });
    });

    element.addEventListener("mouseleave", () => {
      gsap.to(element, {
        ease: "elastic.out(0.8, 0.4)",
        duration: 1,
        x: 0,
        y: 0,
        z: 0,
      });
    });
  },
};
