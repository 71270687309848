import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "curtain",

  mounted(element) {
    const tl = gsap.timeline({
      ease: "power4",
      scrollTrigger: {
        trigger: element.parentNode,
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
        force3D: true,
        onStart: () => {
          ScrollTrigger.refresh();
        },
        onToggle: (self) => {
          if (self.isActive) {
            element.classList.add("will-change");
          } else {
            element.classList.remove("will-change");
          }
        },
      },
    });

    tl.from(element, {
      yPercent: -56,
    });
  },
};
