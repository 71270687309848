export default {
  name: "currency",

  mounted(element, binding) {
    const price = Number(binding.value);

    const currency = binding.arg ? binding.arg : 'RUB';

    const formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency,
      currencyDisplay: "symbol",
      maximumFractionDigits: 0,
    });

    element.innerHTML = formatter.format(price);
  },

  updated(element, binding) {
    const price = Number(binding.value);

    const currency = binding.arg ? binding.arg : 'RUB';

    const formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency,
      currencyDisplay: "symbol",
      maximumFractionDigits: 0,
    });

    element.innerHTML = formatter.format(price);
  },
};
