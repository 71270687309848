<template>
  <div class="services-card">
    <div class="services-card__logo">
      <app-svg id="logo_transparent" />
    </div>
    <h3 class="services-card__title">
      <router-link
        v-clamp="2"
        :to="{ name: 'Детальная услуги', params: { alias: service.alias } }"
      >
        {{ service.title }}
      </router-link>
    </h3>
    <div
      v-clamp="4"
      class="services-card__description"
    >
      {{ service.lid_text }}
    </div>
    <AppLink
      class="services-card__link"
      :to="{ name: 'Детальная услуги', params: { alias: service.alias } }"
    >
      <span>Подробнее</span>
    </AppLink>
  </div>
</template>

<script>
import AppLink from "@/components/ui/AppLink.vue";

export default {
  name: "SevicesCard",
  components: {
    AppLink,
  },

  props: {
    service: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.services-card {
  $this: &;

  position: relative;
  padding: 40px 35px 16px;
  border: 1px solid $color_grey-10;
  box-shadow: 0px 10px 30px rgba(35, 41, 48, 0.05);
  transition: background-color 0.25s ease, color 0.25s ease, box-shadow 0.25s ease;
  overflow: hidden;

  @include media($max-width: 1920px) {
    padding-right: calc(35 / 1920 * 100vw);
    padding-left: calc(35 / 1920 * 100vw);
  }

  @include laptop {
    padding: 20px 48px 20px 20px;
  }

  @include phone {
    margin-bottom: 16px;
  }

  &:hover {
    background: $color_primary;
    color: $white;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  }

  &__title {
    position: relative;
    max-width: 230px;
    height: 82px;
    padding-bottom: 16px;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    @include laptop {
      height: 70px;
      margin-bottom: 0;
      font-size: 20px;
      line-height: 26px;
    }

    @include phone {
      max-width: 100%;
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 16px;
      background: transparent;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $color_primary;
      transition: background-color 0.25s ease;

      #{$this}:hover & {
        background: $white;
      }
    }
  }

  &__description {
    max-width: 348px;
    margin-bottom: 24px;
    height: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @include laptop {
      display: none !important;
    }
  }

  &__link::v-deep {
    position: relative;
    transition: color 0.25s ease;

    @include laptop {
      position: absolute;
      top: 24px;
      right: 11px;
      width: 24px;
      height: 24px;
      padding: 0;
      border: 1px solid $color_grey-15;
      border-radius: 50%;
      color: $color_dark-90;
    }

    #{$this}:hover & {
      color: $white;
    }

    span {
      @include laptop {
        display: none;
      }
    }

    svg {
      @include laptop {
        margin: 0;
        transform: none;
      }
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 266px;
    height: 323px;
    opacity: 0;
    transition: opacity 0.25s ease;

    @include laptop {
      width: 95px;
      height: 112px;
    }

    @include tablet {
      width: 110px;
      height: 129px;
    }

    #{$this}:hover & {
      opacity: 1;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
