<template>
  <div class="write-review-modal">
    <h3 class="write-review-modal__title">
      Написать отзыв
    </h3>

    <form
      ref="feedbackForm"
      class="write-review-modal__form"
      @submit.prevent="submitForm"
    >
      <FormInput
        :key="updateKey"
        v-model="form.name"
        class="write-review-modal__input"
        :class="{ _error: invalid && !form.name }"
        name="name"
        label="Ваше ФИО*"
        @blur="onInput"
      />
      <FormInput
        :key="updateKey"
        v-model="form.email"
        type="email"
        class="write-review-modal__input"
        name="email"
        label="Ваш email"
      />
      <FormInput
        :key="phoneKey"
        v-model="form.phone"
        class="write-review-modal__input"
        type="tel"
        name="phone"
        label="Контактный телефон"
        @blur="onPhoneInput"
      />
      <FormTextArea
        :key="updateKey"
        v-model="form.message"
        :class="{ _error: invalid && !form.message }"
        class="write-review-modal__input"
        name="message"
        label="Отзыв*"
        @blur="onMessageInput"
      />

      <div class="write-review-modal__star-title">
        Ваша оценка
      </div>
      <StarRating
        :key="updateKey"
        v-model="form.rating"
        :class="{ _error: invalid && !ratingIsValid }"
        @rate="onRate"
      />

      <AppButton
        class="write-review-modal__action"
        :to-send="true"
        :sending="sending"
      >
        Оставить отзыв
      </AppButton>
      <FormCheck
        v-model="formIsCheck"
        :class="{ _error: invalid && !checkIsValid }"
        class="write-review-modal__check"
        @click="onChange"
      >
        Даю согласие на обработку
        <a
          href="#"
          target="_blank"
        >персональных данных </a>
      </FormCheck>
      <!--   <div v-if="invalid" class="_invalid">Заполните все поля!</div> -->
    </form>
  </div>
</template>

<script>
import FormInput from "@/components/form/FormInput.vue";
import FormTextArea from "@/components/form/FormTextArea.vue";
import FormCheck from "@/components/form/FormCheck.vue";
import AppButton from "@/components/ui/AppButton.vue";
import StarRating from "./ui/StarRating.vue";

export default {
  name: "ModalWriteReview",
  components: { FormCheck, FormInput, FormTextArea, AppButton, StarRating },

  props: {
    nameIsValid: {
      type: String,
      default: null,
    },
    messageIsValid: {
      type: String,
      default: null,
    },
    ratingIsValid: {
      type: Boolean,
      default: false,
    },
    checkIsValid: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      form: {},
      rate: null,
      sending: false,
      invalid: false,
      errors: [],
      formIsCheck: false,
      updateKey: 0,
      phoneKey: 0,
    };
  },

  methods: {
    isValid() {
      const nameIsValid = !!this.form.name;
      const messageIsValid = !!this.form.message;
      const ratingIsValid = !!this.form.rating;

      const checkIsValid = !!this.formIsCheck;

      if (nameIsValid && messageIsValid && ratingIsValid && checkIsValid) return true;
    },

    onRate(rate) {
      this.form.rating = rate;
    },

    onChange() {
      this.formIsCheck = !this.formIsCheck;
    },

    onInput(event) {
      const inputValue = event.target.value;
      const inputLength = inputValue.length;

      if (inputLength < 1) {
        this.form.name = null;
      }
    },

    onPhoneInput(event) {
      const inputValue = event.target.value;
      const inputLength = inputValue.length;

      if (inputLength < 18) {
        this.phoneKey += 1;
        this.form.phone = null;
      }
    },

    onMessageInput(event) {
      const inputValue = event.target.value;
      const inputLength = inputValue.length;

      if (inputLength < 1) {
        this.form.message = null;
      }
    },

    submitForm() {
      if (this.isValid()) {
        this.errors = [];
        this.invalid = false;
        this.sending = true;
        this.$route.query.agent ? this.form.agent_id = this.$route.query.agent : this.form;
        this.axios
          .post("/api/reviews", this.form)
          .then((response) => {
            if (response.status) {
              let form = this.form;

              form = {};

              Object.keys(form).forEach(function (key) {
                form[key] = "";
              });

              this.sending = false;
              this.clearForm();
              this.updateKeyValue();
              this.formIsCheck = false;
              this.form.message = null;
              this.form.name = null;
              this.phoneKey += 1;
              this.form.name = null;
              this.$emit("form-success-sent");
            }
          })
          .catch((error) => {
            /* this.errors = error.response.data.errors; */
          });
      } else {
        this.invalid = true;
      }
    },

    clearForm() {
      const form = this.$refs.feedbackForm;
      const formInputs = form.querySelectorAll("._fixed-label");

      form.reset();

      formInputs.forEach((input) => {
        input.classList.remove("_fixed-label");
      });
    },

    updateKeyValue() {
      this.updateKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.write-review-modal {
  $that: &;

  padding: 68px 104px;

  @include tablet {
    padding: 58px;
  }

  @include phone {
    padding: 38px 28px;
  }

  @include phone {
    padding: 28px 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #141c1a;
    margin-bottom: 28px;
  }

  &__form {
  }

  &__input {
    & + & {
      margin-top: 8px;
    }
  }

  &__action {
    margin-top: 28px;
  }

  &__check {
    width: 100%;
    margin-top: 16px;
  }

  &__star-title {
    font-weight: 700;
    margin: 16px 0 10px;
  }

  ._invalid {
    color: red;
    padding-top: 8px;
  }
}
</style>
