<template>
  <section class="estate-list">
    <div class="estate-list__inner">
      <div class="estate-list__head">
        <!--   <pre>{{ meta.total }}</pre> -->
        <TransitionFade mode="out-in">
          <div
            v-if="meta.total === 0"
            class="estate-list__search-result"
          >
            По вашему запросу ничего не найдено
          </div>
          <div
            v-else-if="String(meta.total).slice(-1) === '1' && meta.total !== 11"
            class="estate-list__search-result"
          >
            Найден {{ meta.total }} объект
          </div>
          <div
            v-else-if="String(meta.total).slice(-1) === '2' && meta.total !== 12"
            class="estate-list__search-result"
          >
            Найдено {{ meta.total }} объекта
          </div>
          <div
            v-else-if="String(meta.total).slice(-1) === '3' && meta.total !== 13"
            class="estate-list__search-result"
          >
            Найдено {{ meta.total }} объекта
          </div>
          <div
            v-else-if="String(meta.total).slice(-1) === '4' && meta.total !== 14"
            class="estate-list__search-result"
          >
            Найдено {{ meta.total }} объекта
          </div>
          <div
            v-else-if="meta.total === 7"
            class="estate-list__search-result"
          >
            Найдено {{ meta.total }} объектов
          </div>

          <div
            v-else-if="!meta.total"
            class="estate-list__search-result"
          />

          <div
            v-else
            class="estate-list__search-result"
          >
            Найдено {{ meta.total }} объектов
          </div>
        </TransitionFade>
        <AppSorting
          :options="sortingList"
          class="estate-list__sorting"
          @sorting="onSorting"
        />

        <AppToggle
          class="estate-list__toggle-map"
          label="Показать на карте"
          @change="onMapToggle"
        />
      </div>
      <div class="estate-list__body">
        <ul class="estate-list__list">
          <li
            v-for="(estateItem, index) in estateList"
            :key="index"
            class="estate-list__item"
          >
            <EstateNewCard
              :key="cardKey"
              :is-small="isSmall"
              :card="estateItem"
            />
          </li>
        </ul>
      </div>
      <div
        v-if="Math.ceil(meta.total / meta.per_page) > 1"
        class="estate-list__bottom"
      >
        <AppButton
          v-if="meta.current_page < meta.last_page"
          :secondary="true"
          @click="onShowMore"
        >
          Показать еще
        </AppButton>
        <PagePagination
          v-if="Math.ceil(meta.total / meta.per_page) !== 1"
          class="estate-list__pagination"
          :total-pages="Math.ceil(meta.total / meta.per_page)"
          :total="+meta.total"
          :per-page="+meta.per_page"
          :current-page="+meta.current_page"
          @pagechanged="onPageChange"
        />
      </div>
    </div>
  </section>
</template>

<script>
import EstateNewCard from "./EstateNewCard.vue";
import PagePagination from "./ui/PagePagination.vue";
import AppButton from "./ui/AppButton.vue";
import AppToggle from "./ui/AppToggle.vue";
import AppSorting from "./ui/AppSorting.vue";
import { mapActions } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "EstateList",
  components: { EstateNewCard, PagePagination, AppButton, AppToggle, AppSorting },

  props: {
    estateList: {
      type: [Array, Object],
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => {},
    },
  },

  emits: ["page-changed", "map-toggle", "show-more", "sorting"],

  data() {
    return {
      sortingList: [
        "По умолчанию",
        "Цена по возрастанию",
        "Цена по убыванию",
        "Площадь по возрастанию",
        "Площадь по убыванию",
      ],
      searchText: {
        find: "Найдено",
        find2: "Найден",
        object: "Объектов",
        object2: "Объект",
      },
      cardKey: 0,
      isSmall: false,
    };
  },

  methods: {
    ...mapActions(useFilterStore, ["setSort", "returnRequiredParams"]),
    onPageChange(page) {
      this.$emit("page-changed", page);
      this.$nextTick(() => {
        this.cardKey += 1;
      });
    },

    onMapToggle(event) {
      this.$emit("map-toggle", event);
    },

    onShowMore() {
      this.$emit("show-more");
    },

    async onSorting(payload) {
      this.setSort(payload);
      await setParamsInUrl(this, this.$route.query, {
        sortBy: payload[0],
        orderBy: payload[1],
      });
      emitter.emit("load-with-params");
      this.cardKey += 1;
    },

    goSmall() {
      const media = window.matchMedia("(max-width:1023px)");
      const listener = (event) => (this.isSmall = event.matches);
      listener(media);

      media.addEventListener("change", listener);
    },
  },

  mounted() {
    this.goSmall();
  },
};
</script>

<style lang="scss" scoped>
.estate-list {
  $that: &;

  overflow: hidden;
  padding: 0 0 64px;
  position: relative;

  @include desktop {
    padding: 0 0 50px;
  }

  @include laptop {
    padding: 34px 0 0;
  }

  &__inner {
    @extend %container;
  }

  &__head {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    position: relative;
    // z-index: 99;

    @include laptop {
      justify-content: space-between;
      padding-bottom: 20px;
    }

    @include tablet {
      padding-bottom: 0;
    }

    @include phone {
      padding-bottom: 20px;
    }
  }

  &__toggle-map {
    margin-left: auto;
    position: relative;
    z-index: 99;

    ._show-map & {
      opacity: 0;
      pointer-events: none;
    }

    @include laptop {
      display: none;
    }
  }

  &__body {
    min-height: 240px;

    @include tablet {
      min-height: 362px;
    }

    @include phone {
      min-height: 385px;
    }

    @include phone-s {
      min-height: 372px;
    }
  }

  &__search-result {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    @include desktop {
      font-size: 26px;
      line-height: 37px;
    }

    @include tablet {
      font-size: 22px;
    }

    @include phone {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__sorting {
    position: relative;
    z-index: 99;
    flex: none;
    margin-left: 62px;

    @include laptop {
      position: absolute;
      right: 0;
    }
  }

  &__list {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      margin: 0 calc(-16 / 1920 * 100vw) 0;
    }

    @include phone {
      margin: 0;
    }
  }

  &__item {
    & + & {
      padding-top: 12px;

      @include tablet {
        padding: calc(32 / 1920 * 100vw) calc(16 / 1920 * 100vw) 0;
        margin-top: 0;
      }

      @include phone {
        padding: 0;
        margin-top: 12px;
      }
    }

    @include tablet {
      flex: 0 0 50%;
      padding: calc(32 / 1920 * 100vw) calc(16 / 1920 * 100vw) 0;
    }

    @include phone {
      padding: 0;
      flex: 1 1 100%;
    }
  }

  &__bottom {
    position: relative;
    margin-top: 62px;

    @include flex-center;

    @include laptop {
      margin-top: 32px;
    }

    @include tablet {
      flex-direction: column;
    }
  }

  &__pagination {
    position: absolute;
    right: 0;

    @include tablet {
      margin-top: 24px;
      position: static;
    }
  }
}
</style>
