<template>
  <div
    class="star-rating"
    :class="classes"
    :stars="stars"
  >
    <ul
      v-if="stars"
      class="star-rating__stars"
    >
      <li
        v-for="(item, index) of maxStars"
        :key="index"
        class="star-rating__star"
      >
        <div
          :data-star="index + 0.5"
          class="star-rating__half-star"
          @mouseenter="hoverOn"
          @mouseleave="hoverOff"
          @click="rate"
        />
        <svg
          class="star-rating__svg"
          :data-star="index + 1"
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @mouseenter="hoverOn"
          @mouseleave="hoverOff"
          @click="rate"
        >
          <path
            :style="stars >= index + 1 ? 'color: #F4691B' : 'color: none'"
            class="_right"
            d="M21.5463 13.6035L21.654 13.9583H22.0247H34.4173L24.3623 21.776L24.0909 21.987L24.1907 22.316L27.8926 34.5141L18.2444 27.0112L17.9375 26.7725L17.6306 27.0112L7.98239 34.5141L11.6843 22.316L11.7841 21.987L11.5127 21.776L1.45768 13.9583H13.8503H14.221L14.3287 13.6035L17.9375 1.72064L21.5463 13.6035Z"
            stroke="#F4691B"
            fill="currentColor"
          />
          <path
            :style="stars >= index + 0.5 ? 'color: #F4691B' : 'color: none'"
            class="_left"
            d="M14.609 13.8835L17.7178 3.64704V27.4414L8.26267 34.7941L11.9645 22.596L12.0644 22.267L11.793 22.056L1.73796 14.2382H14.1306H14.5013L14.609 13.8835Z"
            fill="currentColor"
            stroke="currentColor"
          />
        </svg>
      </li>
    </ul>

    <ul
      v-else
      class="star-rating__stars"
    >
      <li
        v-for="(item, index) of maxStars"
        :key="index"
        class="star-rating__star"
      >
        <div
          :data-star="index + 0.5"
          class="star-rating__half-star"
          @mouseenter="hoverOn"
          @mouseleave="hoverOff"
          @click="rate"
        />
        <svg
          class="star-rating__svg"
          :data-star="index + 1"
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @mouseenter="hoverOn"
          @mouseleave="hoverOff"
          @click="rate"
        >
          <path
            :style="pointing >= index + 1 ? 'color: #F4691B' : 'color: none'"
            class="_right"
            d="M21.5463 13.6035L21.654 13.9583H22.0247H34.4173L24.3623 21.776L24.0909 21.987L24.1907 22.316L27.8926 34.5141L18.2444 27.0112L17.9375 26.7725L17.6306 27.0112L7.98239 34.5141L11.6843 22.316L11.7841 21.987L11.5127 21.776L1.45768 13.9583H13.8503H14.221L14.3287 13.6035L17.9375 1.72064L21.5463 13.6035Z"
            stroke="#F4691B"
            fill="currentColor"
          />
          <path
            :style="pointing >= index + 0.5 ? 'color: #F4691B' : 'color: none'"
            class="_left"
            d="M14.609 13.8835L17.7178 3.64704V27.4414L8.26267 34.7941L11.9645 22.596L12.0644 22.267L11.793 22.056L1.73796 14.2382H14.1306H14.5013L14.609 13.8835Z"
            fill="currentColor"
            stroke="currentColor"
          />
        </svg>
      </li>
    </ul>

    <transition
      name="error"
      appear
    >
      <div
        v-if="!rating"
        class="star-rating__error"
      >
        Ваша оценка очень важна для нас :)
      </div>
    </transition>
  </div>
</template>
<script>
import { computed, reactive } from "vue";

export default {
  name: "StarRating",

  props: {
    isSmall: {
      type: Boolean,
      default: false,
    },

    stars: {
      type: Number,
      default: null,
    },
  },

  emits: ["rate"],

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_is-small": props.isSmall,
      })),
    };
  },

  data() {
    return {
      maxStars: 5,
      pointing: null,
      rating: null, // здесь хранится оценка
    };
  },

  methods: {
    rate(event) {
      this.rating = event.currentTarget.getAttribute("data-star");

      this.$emit("rate", this.rating);
    },

    hoverOn(event) {
      this.pointing = event.currentTarget.getAttribute("data-star");
    },

    hoverOff() {
      this.pointing = this.rating;
    },
  },
};
</script>
<style lang="scss" scoped>
.star-rating {
  $that: &;

  &._is-small {
    pointer-events: none;
  }

  &__stars {
    display: flex;
  }

  &__star {
    position: relative;
    cursor: pointer;

    & + & {
      padding-left: 10px;

      #{$that}__half-star {
        width: calc(50% + 6px);
      }
    }

    svg {
      &:hover {
        ._right {
          color: $color_primary;
        }
      }
    }
  }

  &__svg {
    ._is-small & {
      width: 22px;
      height: 22px;

      @include laptop {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__half-star {
    content: "";
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    &:hover {
      & + svg {
        ._left {
          color: $color_primary;
          stroke: $color_primary;
        }
      }
    }
  }

  ._left,
  ._right {
    color: transparent;
    transition: color 0.15s 0.05s ease-out, stroke 0.15s 0.05s ease-out;
  }

  &__error {
    position: absolute;
    color: $color_primary;
    margin-top: 6px;
    font-size: 12px;
    line-height: 16px;
    opacity: 0;
    visibility: hidden;

    ._error & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.error-enter-active {
  transition: opacity 0.5s, transform 0.5s;
}

.error-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}

.error-enter {
  opacity: 0;
  transform: translate(8px, 0);
}

.error-leave-to

  /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translate(0, 2px);
}
</style>
