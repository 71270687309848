<!-- <app-title :noIcon="true">Заголовок</app-title> -->

<template>
  <h1
    v-if="h1"
    class="title-section"
    :class="classes"
  >
    <slot />
  </h1>
  <h2
    v-else
    class="title-section"
    :class="classes"
  >
    <slot />
  </h2>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "AppTitle",
  props: {
    h1: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_with-icon": !props.noIcon,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
.title-section {
  $that: &;

  color: #232930;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;

  @include laptop {
    font-size: 26px;
    line-height: 37px;
  }

  @include phone {
    font-size: 22px;
    line-height: 24px;
  }

  &._with-icon {
    &:after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 18px solid $color_primary;
      border-bottom: 10px solid transparent;
      margin-left: 32px;

      @include phone {
        display: none;
      }
    }
  }
}
</style>
