<!-- <AppLink :secondary="true">Ссылка</AppLink> -->

<template>
  <router-link
    v-if="to"
    :to="to"
    class="link"
    :class="classes"
  >
    <slot />
    <app-svg id="arrow-right" />
  </router-link>

  <a
    v-else
    :href="href"
    target="_blank"
    class="link"
    :class="classes"
  >
    <slot />
    <app-svg id="arrow-right" />
  </a>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "AppLink",
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        _secondary: props.secondary,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
.link {
  $that: &;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 14px 8px 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  color: $color_primary;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include hover {
    svg {
      transform: translateX(14px);
    }
  }

  &:active {
    svg {
      transform: translateX(14px);
    }
  }

  &._secondary {
    color: rgba($color: $color_dark-90, $alpha: 0.5);
  }

  svg {
    width: 6px;
    height: 12px;
    margin-left: 16px;
    transition: transform 0.3s ease;
  }
}
</style>
