<template>
  <router-link
    v-if="cardLink"
    :to="{ name: 'Детальная новости', params: { alias: news.alias } }"
    class="news-card"
  >
    <div class="news-card__image">
      <img
        class="lazy"
        :data-src="news.image"
        alt=""
      >
    </div>
    <div class="news-card__info">
      <AppDate class="news-card__date">
        {{ news.date }}
      </AppDate>
      <h3
        v-clamp="3"
        class="news-card__title"
      >
        {{ news.title }}
      </h3>
    </div>
  </router-link>

  <div
    v-else
    class="news-card"
    :class="classes"
  >
    <router-link
      :to="{ name: 'Детальная новости', params: { alias: news.alias } }"
      class="news-card__image"
    >
      <img
        class="lazy"
        :data-src="news.image"
        alt=""
      >
    </router-link>
    <div
      v-if="isMain"
      class="news-card__info"
    >
      <AppDate class="news-card__date">
        {{ news.date }}
      </AppDate>
      <h3
        v-clamp="2"
        class="news-card__title"
      >
        {{ news.title }}
      </h3>
      <div
        v-clamp="2"
        class="news-card__description"
        v-html="news.lid_text"
      />
      <AppLink
        class="news-card__link"
        :to="{ name: 'Детальная новости', params: { alias: news.alias } }"
        :secondary="true"
      >
        <span>Подробнее</span>
      </AppLink>
    </div>
    <div
      v-else
      class="news-card__info"
    >
      <AppDate class="news-card__date">
        {{ news.date }}
      </AppDate>
      <router-link :to="{ name: 'Детальная новости', params: { alias: news.alias } }">
        <h3
          v-clamp="3"
          class="news-card__title"
        >
          {{ news.title }}
        </h3>
      </router-link>
      <AppLink
        class="news-card__link"
        :to="{
          name: 'Детальная новости',
          params: { alias: news.alias },
        }"
        :secondary="true"
      >
        <span>Подробнее</span>
      </AppLink>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import AppLink from "@/components/ui/AppLink.vue";
import AppDate from "@/components/ui/AppDate.vue";

export default {
  name: "NewsCard",
  components: {
    AppLink,
    AppDate,
  },

  props: {
    news: {
      type: Object,
      required: true,
    },
    isMain: {
      type: Boolean,
      default: false,
    },
    cardLink: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "news-card--main": props.isMain,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
.news-card {
  $this: &;

  display: block;
  width: 100%;
  height: 100%;
  background-color: $white;
  transition: box-shadow 0.3s ease;

  @include hover {
    box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.1);

    .news-detail__aside-item & {
      box-shadow: none;

      .news-card__image {
        box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &:not(.news-card--main) {
    display: flex;

    @include laptop {
      display: block;
      border: 1px solid #e8e8e8;
    }
  }

  &__image {
    display: block;
    width: 45%;
    height: 100%;
    flex-shrink: 0;
    transition: box-shadow 0.3s ease;

    @include laptop {
      width: 100%;
      height: auto;
    }

    #{$this}--main & {
      width: 100%;
      height: 514px;

      @include media($max-width: 1920px) {
        height: calc(514 / 1920 * 100vw);
      }

      @include laptop {
        height: auto;
      }
    }

    .news-detail & {
      width: 157px;
      height: 91px;
      margin-right: 24px;
    }

    img {
      width: 100%;
      height: 100%;

      @include object-fit(cover, center);
      @include lazy-loaded;
    }
  }

  &__info {
    padding: 20px 32px 12px;

    @include media($max-width: 1920px) {
      padding: 20px calc(32 / 1920 * 100vw) calc(12 / 1920 * 100vw);
    }

    @include desktop {
      padding-bottom: calc(12 / 1535 * 100vw);
    }

    @include laptop {
      padding: 16px 16px 32px;
    }

    .news-detail & {
      padding: 0 20px 0 0;
    }
  }

  &__date::v-deep {
    margin-bottom: 16px;

    #{$this}--main & {
      margin-bottom: 20px;
    }

    .news-detail & {
      svg {
        display: none;
      }
    }
  }

  &__title {
    height: calc(26px * 3);
    margin-bottom: 47px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: $color_dark-70;

    @include media($max-width: 1920px) {
      margin-bottom: calc(47 / 1920 * 100vw);
    }

    @include desktop {
      -webkit-line-clamp: 2;
      height: calc(26px * 2);
      margin-bottom: calc(47 / 1535 * 100vw);
    }

    @include laptop {
      height: calc(18px * 2);
      margin-bottom: 0;
      font-size: 14px;
      line-height: 18px;
    }

    #{$this}--main & {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 32px;
      height: calc(32px * 2);
    }

    .news-detail & {
      height: calc(16px * 3);
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__description {
    height: calc(24px * 2);
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color_dark-70;

    @include media($max-width: 1920px) {
      margin-bottom: calc(24 / 1920 * 100vw);
    }

    @include laptop {
      display: none !important;
    }
  }
}
</style>
