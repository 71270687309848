<template>
  <div class="mobile-menu-nav">
    <!--   <pre>{{ servicesList }}</pre> -->
    <div
      class="mobile-menu-nav__1st-level mobile-menu-nav__inner"
      :class="{ '_is-active': !subMenuIsActive }"
    >
      <ul class="mobile-menu-nav__list">
        <li
          v-for="(item, index) in navList"
          :key="index"
          class="mobile-menu-nav__item"
        >
          <router-link
            :to="item.link"
            class="mobile-menu-nav__go"
            @click="$emit('link-clicked', $event.target)"
          >
            {{ item.name }}
          </router-link>

          <div
            v-if="item.is_service"
            class="mobile-menu-nav__icon"
            @click="(subMenuIsActive = true), animateSubMenuListItems();"
          >
            <app-svg
              id="arrow-right"
              class="mobile-menu-nav__svg"
              width="6"
              height="12"
            />
          </div>
        </li>
      </ul>

      <ul class="mobile-menu-nav__bottom-list">
        <li
          v-for="(item, index) in burgerList"
          :key="index"
          class="mobile-menu-nav__bottom-list-item"
        >
          <a
            v-if="item.link.includes('http')"
            :href="item.link"
            class="mobile-menu-nav__go"
          >
            {{ item.name }}
          </a>
          <router-link
            :to="item.link"
            class="mobile-menu-nav__go"
            @click="$emit('link-clicked', $event.target)"
            v-else
          >
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div
      :class="{ '_is-active': subMenuIsActive }"
      class="mobile-menu-nav__second-level mobile-menu-nav__inner"
    >
      <ul class="mobile-menu-nav__list">
        <li
          class="mobile-menu-nav__item _go-back"
          @click="(subMenuIsActive = false), animateMenuListItems();"
        >
          <div class="mobile-menu-nav__icon">
            <app-svg
              id="arrow-left"
              class="mobile-menu-nav__svg"
              width="6"
              height="12"
            />
          </div>
          Услуги
        </li>
        <li
          v-for="(item, index) in servicesList"
          :key="index"
          class="mobile-menu-nav__item"
        >
          <router-link
            :to="`/services/${item.alias}`"
            class="mobile-menu-nav__go"
            @click="$emit('link-clicked', $event.target)"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "MobileMenuNav",

  props: {
    isOpenMenu: {
      type: Boolean,
      default: false,
    },
    navList: {
      type: Array,
      default: null,
    },
    burgerList: {
      type: Array,
      default: null,
    },
    servicesList: {
      type: Array,
      default: null,
    },
  },

  emits: ["link-clicked"],

  data() {
    return {
      subMenuIsActive: false,
    };
  },

  watch: {
    isOpenMenu: function () {
      this.animateMenuListItems();
    },
  },

  methods: {
    animateMenuListItems() {
      const elements = document.querySelectorAll(".mobile-menu-nav__1st-level li");

      gsap.from(elements, {
        xPercent: -20,
        autoAlpha: 0,
        duration: 0.3,
        stagger: 0.1,
        force3D: true,
      });
    },

    animateSubMenuListItems() {
      const elements = document.querySelectorAll(".mobile-menu-nav__second-level li");

      gsap.from(elements, {
        xPercent: 20,
        autoAlpha: 0,
        duration: 0.3,
        stagger: 0.1,
        force3D: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu-nav {
  $that: &;

  $header-height: 89px;
  $mobile-panel-height: 55px;

  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: $color_dark-90;
  color: $color_grey-15;
  padding-top: $header-height;
  padding-bottom: $mobile-panel-height;
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  transition: opacity 0.1s 0.5s ease, transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);

  &._is-open {
    transition: opacity 0.1s ease, transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &__inner {
    @extend %container;

    padding-bottom: 32px;
  }

  &__list {
    font-size: 16px;
    line-height: 24px;
    padding: 22px 0 78px;
  }

  &__item {
    padding: 16px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid $color_dark-70;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &._go-back {
      cursor: pointer;
      color: $color_primary;
      justify-content: flex-start;
    }
  }

  &__bottom-list {
    font-size: 14px;
    line-height: 18px;
  }

  &__bottom-list-item {
    padding: 10px;
  }

  &__go {
    &:hover {
      transition: color 0.25s ease-out;
      color: $color_primary;

      #{$that}__bottom-list-item & {
        color: $color_orange-90;
      }
    }
  }

  &__icon {
    cursor: pointer;
    padding: 8px 4px 8px 16px;

    ._go-back & {
      padding: 0;
      margin-right: 16px;
    }
  }

  &__1st-level {
    transition: transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
    transform: translate3d(-100%, 0, 0);

    &._is-active {
      transform: translate3d(0, 0, 0);
    }
  }

  &__second-level {
    display: none;
    padding-top: $header-height;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    transition: transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
    transform: translate3d(0, 0, 0);
    opacity: 0;

    &._is-active {
      display: block;
      opacity: 1;
      transform: translate3d(-100%, 0, 0);
    }
  }
}
</style>
