<template>
  <label class="action-type">
    <input
      v-model="query.type"
      type="radio"
      name="type"
      value="1"
    >
    <AppButton
      :class="{ '_is-active': query.type === 1 }"
      :radio="true"
      :third="true"
      @click="setButOrRentHandle(1)"
    >
      Купить
    </AppButton>
  </label>

  <label
    v-if="query.currentEstateType.name !== 'Новостройки'"
    class="action-type"
  >
    <input
      v-model="query.type"
      type="radio"
      name="type"
      value="0"
    >
    <AppButton
      :class="{ '_is-active': query.type === 0 }"
      :radio="true"
      :third="true"
      @click="setButOrRentHandle(0)"
    >
      Снять
    </AppButton>
  </label>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";

import AppButton from "@/components/ui/AppButton.vue";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FIlterEstateTypeReactive",
  components: {
    AppButton,
  },
  computed: {
    ...mapState(useFilterStore, ["query"]),
  },
  methods: {
    ...mapActions(useFilterStore, ["setTypeBuyOrRent", "query", "returnRequiredParams", "getRangeForCategory"]),
    async setButOrRentHandle(payload) {
      this.setTypeBuyOrRent(payload);
      await setParamsInUrl(
        this,
        this.$route.query,
        {
          type: payload,
        },
        "ONLY_FOR_CATEGORY_SET"
      );
      await this.getRangeForCategory(this.$route);
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style lang="scss" scoped>
.action-type {
  overflow: hidden;
  flex: 1;

  input {
    @include visually-hidden;
  }
}
</style>
