<template>
  <div ref="accordion" class="accordion" :class="[classes, { '_is-open': show }]">
    <div class="accordion__header" @click="toggle">
      <span>
        <slot name="header">Заголовок</slot>
      </span>
      <i v-if="arrow" class="accordion__arrow">
        <app-svg id="arrow-bottom" widrth="12" height="6" />
      </i>
    </div>
    <div v-if="isFilterHead" class="accordion__body">
      <div class="accordion__body-inner">
        <slot>Какой-то текст</slot>
      </div>
    </div>

    <transition
      v-else
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-show="show" class="accordion__body">
        <div class="accordion__body-inner">
          <slot>Какой-то текст</slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "AppAccordion",

  props: {
    arrow: {
      type: Boolean,
      default: true,
    },
    onlyMobile: {
      type: Boolean,
      default: false,
    },
    fromLaptop: {
      type: Boolean,
      default: false,
    },
    fromTablet: {
      type: Boolean,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    showInner: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        _mobile: props.onlyMobile,
        _tablet: props.fromTablet,
        _laptop: props.fromLaptop,
        "_is-absolute": props.isAbsolute,
        "_filter-head": props.isFilterHead,
        "_filter-map": props.isFilterMap,
      })),
    };
  },

  data() {
    return {
      isMobile: false,
      show: this.showInner,
    };
  },

  mounted() {
    window.addEventListener("click", (event) => {
      if (this.isFilterHead) {
        if (!event.target.closest(".accordion")) {
          this.$emit("accordeonLeave");
          this.show = false;
        } else {
          this.$emit("accordeonOpen");
        }
      }
    });
  },

  methods: {
    toggle: function () {
      this.show = !this.show;
    },

    beforeEnter: function (element) {
      element.style.height = "0";
    },

    enter: function (element) {
      element.style.height = element.scrollHeight + "px";
    },

    beforeLeave: function (element) {
      element.style.height = element.scrollHeight + "px";
    },

    leave: function (element) {
      element.style.height = "0";
    },

    closeAccordion() {
      if (this.$refs.accordion.closest("_only-one")) {
        this.show = false;
      }
    },

    transformTransition() {
      const media = window.matchMedia("(max-width:1280px)");
      const listener = (event) => (this.isMobile = event.matches);
      listener(media);

      media.addEventListener("change", listener);
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  $that: &;

  $base-color: #fff;
  $active-color: false;

  position: relative;

  &__header {
    @include laptop {
      padding: 16px 0;
    }

    @include phone {
      .mobile-filter-menu & {
        padding: 0;
      }
    }

    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $base-color;
    cursor: pointer;
    transition: color 0.25s 0.1s ease-out;

    span {
      padding-right: 28px;

      @include tablet {
        padding-right: 22px;
      }
    }

    ._map-filter & {
      font-weight: 400;
    }

    ._is-open & {
      color: $base-color;

      @if $active-color {
        color: $active-color;
      }
    }

    ._filter-head & {
      color: $white;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 7px 12px 13px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    ._laptop & {
      @media (min-width: 1280px) {
        pointer-events: none;
      }
    }

    ._tablet & {
      @media (min-width: 1024px) {
        pointer-events: none;
      }
    }

    ._mobile & {
      @media (min-width: 640px) {
        pointer-events: none;
      }
    }
  }

  &__arrow {
    transform: rotate(0deg);
    transition: transform 0.45s ease;

    ._filter-head & {
      transition: transform 0.25s ease;
    }

    svg {
      flex: none;
      max-width: 12px;
    }

    ._is-open & {
      transform: rotate(180deg);
    }

    ._laptop & {
      @media (min-width: 1280px) {
        display: none;
      }
    }

    ._tablet & {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    ._mobile & {
      @media (min-width: 640px) {
        display: none;
      }
    }
  }

  &__body {
    color: $color_dark-40;
    overflow: hidden;
    transition: height 0.45s ease;
    display: none;

    ._is-open & {
      display: block;
    }

    ._is-absolute & {
      position: absolute;
      width: 100%;
      z-index: 999;
    }

    ._filter-head & {
      color: $white;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      position: absolute;
      left: -22px;
      bottom: -30px;
      transform: translate3d(0, 100%, 0);
      width: 100%;
      min-width: 254px;
      max-width: 359px;
      z-index: 999;
      box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.2);

      @include desktop {
        bottom: -16px;
      }

      @include laptop {
        position: static;
        box-shadow: 0;
        transform: none;
      }
    }

    ._map-filter & {
      position: static;
      transform: none;
    }

    ._filter-head-336 & {
      min-width: 336px;

      @include phone {
        min-width: 100%;
      }
    }

    ._laptop & {
      @media (min-width: 1280px) {
        overflow: visible;
        display: block !important;
        height: auto;
      }
    }

    ._tablet & {
      @media (min-width: 1024px) {
        overflow: visible;
        display: block !important;
        height: auto;
      }
    }

    ._mobile & {
      @media (min-width: 640px) {
        overflow: visible;
        display: block !important;
        height: auto;
      }
    }
  }

  &__body-inner {
    @include laptop {
      padding-bottom: 24px;
    }
  }
}
</style>
