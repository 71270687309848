<!--  <AppToggle label="Текст справа" labelLeft="текст Слева" /> -->

<template>
  <label
    class="toggle"
    :class="classes"
  >
    <input
      v-bind="$attrs"
      type="checkbox"
      class="toggle__input"
    >
    <div class="toggle__body">
      <div
        v-if="labelLeft"
        class="toggle__label toggle__label--left"
      >
        {{ labelLeft }}
      </div>
      <span class="toggle__switcher" />
      <div
        v-if="label"
        class="toggle__label"
      >
        {{ label }}
      </div>
    </div>
  </label>
</template>
<script>
import { computed, reactive } from "vue";

export default {
  name: "AppToggle",
  props: {
    label: {
      type: String,
      default: "",
    },
    labelLeft: {
      type: String,
      default: "",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_is-small": props.isSmall,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  $that: &;

  position: relative;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + #{$that}__body #{$that}__switcher {
      background-color: #f4691b;
      // background-color: $color_primary;

      &::before {
        transform: translateX(22px);
      }
    }

    ._is-small & {
      &:checked + #{$that}__body #{$that}__switcher::before {
        transform: translateX(15px);
      }
    }

    &:checked + #{$that}__body #{$that}__label:not(#{$that}__label--left) {
      opacity: 1;
    }

    &:checked + #{$that}__body #{$that}__label--left {
      opacity: 0.7;
    }
  }

  &__switcher {
    position: relative;
    width: 48px;
    height: 24px;
    cursor: pointer;
    background-color: #dcdfe1;
    border-radius: 57px;
    transition: background-color 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;

      ._is-small & {
        width: 12px;
        height: 12px;
        left: 3px;
        bottom: 2px;
      }
    }

    ._is-small & {
      width: 33px;
      height: 16px;
    }
  }

  &__body {
    display: flex;
    align-items: center;
  }

  &__label {
    transition: opacity 0.4s ease;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    flex: none;
    padding-left: 8px;
    opacity: 0.7;

    &--left {
      padding-left: 0;
      padding-right: 8px;
      opacity: 1;
    }
  }
}
</style>
