<template>
  <VueMultiselect
    v-if="multiple"
    v-model="selected"
    :multiple="multiple"
    class="form-select"
    :options="options"
    :searchable="false"
    :close-on-select="true"
    :show-labels="true"
    :hide-selected="true"
    :max-height="396"
    :preselect-first="false"
    select-label=""
    :label="label"
  />
  <VueMultiselect
    v-else
    v-model="selected"
    class="form-select"
    :options="options"
    :searchable="false"
    :close-on-select="true"
    :show-labels="false"
    :hide-selected="false"
    :max-height="396"
    :preselect-first="true"
    select-label=""
  />
</template>

<script>
import VueMultiselect from "vue-multiselect";

export default {
  name: "FormSelect",
  components: { VueMultiselect },

  props: {
    options: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
    },
    label: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.multiselect {
  $that: &;
  color: $white !important;

  &--disabled {
    background: transparent !important;
  }

  &__tags {
    padding: 8px 40px 0 13px !important;
    border: 0 !important;
    border-radius: 0 !important;
    background: none !important;
  }

  &__tag {
    background-color: $color_primary !important;
  }

  &__single {
    background: none !important;
    margin: 0 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__placeholder {
    color: $white !important;
    font-size: 16px !important;
  }

  &__content-wrapper {
    min-width: 258px !important;
    border: none !important;
    border-radius: 0 !important;
    background-color: $color_dark-90 !important;
    padding: 22px !important;
    box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.2) !important;
  }

  &__select {
    height: 35px !important;

    &::before {
      margin: 0 !important;
      border: 0 !important;
      width: 1px !important;
      height: 6px !important;
      background-color: $white !important;
      display: block !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: rotate(-55deg) translate(-50%, -50%) !important;

      .multiselect--active & {
        transform: rotate(55deg) translate(-50%, -50%) !important;
      }
    }

    &::after {
      content: "";
      border: 0 !important;
      width: 1px !important;
      height: 6px !important;
      background-color: $white !important;
      display: block !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: rotate(55deg) translate(-50%, -50%) !important;

      .multiselect--active & {
        transform: rotate(-55deg) translate(-50%, -50%) !important;
      }
    }

    #{$that}--disabled & {
      background: transparent !important;
    }
  }

  &__option {
    &--highlight {
      background: $color_dark-70 !important;

      &::after {
        background: $color_dark-70 !important;
      }
    }

    &--selected {
      background: $color_dark-70 !important;
      color: $white !important;
    }

    .filter-zhk & {
      word-wrap: break-word;
    }
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
