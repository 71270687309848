import lazySizes from "lazysizes";

// import { ScrollTrigger } from 'gsap/ScrollTrigger'

//LAZY-SIZES
// lazySizes.cfg.preloadAfterLoad = true
lazySizes.cfg.loadMode = 1;
lazySizes.cfg.lazyClass = "lazy";
lazySizes.cfg.preloadClass = "_preload";
lazySizes.cfg.loadingClass = "_is-loading";
lazySizes.cfg.loadedClass = "_is-loaded";
lazySizes.cfg.customMedia = {
  mobile: "(max-width: 639px)",
};

/* document.addEventListener('lazyloaded', () => {
  ScrollTrigger.refresh()
}) */

/**
 * Example
<picture>
  <source data-srcset="" media="mobile" />
  <img
    class="lazy"
    data-src=""
    data-sizes="auto"
    alt=""
  />
</picture>
 */
