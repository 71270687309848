<template>
  <section ref="trigger" class="form-section" :class="{ 'form-section_overseas': overseas }">
    <div class="form-section__inner">
      <div class="form-section__text-block">
        <div class="form-section__title">
          {{ title }}
        </div>
        <div class="form-section__text">
          {{ text }}
        </div>
      </div>
      <form ref="feedbackForm" class="form-section__form" @submit.prevent="submitForm">
        <template v-if="!overseas">
          <FormRadio
            :key="updateKey"
            class="form-section__radio"
            :is-dark="true"
            name="form"
            value="sell"
            :checked="form.sell_or_rent === 'Продать'"
            @change="sell"
          >
            Продать
          </FormRadio>
          <FormRadio
            :key="updateKey"
            :checked="form.sell_or_rent === 'Сдать'"
            class="form-section__radio"
            :is-dark="true"
            name="form"
            value="rent"
            @change="rent"
          >
            Сдать
          </FormRadio>
        </template>
        <FormInput
          :key="updateKey"
          v-model="form.name"
          class="form-section__input"
          :class="{ _error: invalid && !form.name }"
          :is-dark="true"
          name="name"
          label="Ваше имя*"
          @blur="onInput"
        />
        <FormInput
          :key="phoneKey"
          ref="phoneInput"
          v-model="form.phone"
          class="form-section__input"
          :is-dark="true"
          type="tel"
          name="phone"
          label="Ваш телефон*"
          :class="{ _error: invalid && !form.phone }"
          @blur="onPhoneInput"
        />
        <FormCheck
          :key="updateKey"
          v-model="form.check"
          :class="{ _error: invalid && !checkIsValid }"
          class="form-section__check"
          :is-dark="true"
          @click="onChange"
        >
          Согласие на обработку
          <a href="#" target="_blank">персональных данных </a>
        </FormCheck>
        <AppButton :to-send="true" :sending="sending"> Отправить заявку </AppButton>
        <!--    <div
          v-if="invalid"
          class="_invalid"
        >
          Есть ошибки!
        </div> -->
      </form>
      <div class="form-section__background-image">
        <img v-if="image" ref="backgroundImage" class="lazy" :data-src="image" alt="" />
        <img
          v-else
          ref="backgroundImage"
          class="lazy"
          :data-src="require(`@/assets/images/content/form-section.jpg`)"
          alt=""
        />
      </div>
      <teleport v-if="isOpenPopupSuccessfully" to="body">
        <PopupSuccessfully @popup-close="isOpenPopupSuccessfully = false" />
      </teleport>
    </div>
  </section>
</template>

<script>
import FormRadio from "./form/FormRadio.vue";
import FormInput from "./form/FormInput.vue";
import FormCheck from "./form/FormCheck.vue";
import AppButton from "@/components/ui/AppButton.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import PopupSuccessfully from "@/components/PopupSuccessfully.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "FormSection",
  components: { FormRadio, FormInput, FormCheck, AppButton, PopupSuccessfully },

  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: null,
    },
    formSection: {
      type: Object,
      default: () => {},
    },
    nameIsValid: {
      type: String,
      default: null,
    },
    phoneIsValid: {
      type: String,
      default: null,
    },
    checkIsValid: {
      type: Boolean,
      default: null,
    },
    overseas: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        sell_or_rent: "Продать",
        page: window.location.href,
      },
      sending: false,
      invalid: false,
      errors: [],
      formIsCheck: false,
      phoneKey: 0,
      updateKey: 0,
      isOpenPopupSuccessfully: false,
    };
  },

  /*   mounted() {
      this.backgroundImageAnimation();
    }, */

  methods: {
    isValid() {
      const nameIsValid = !!this.form.name;
      const phoneIsValid = !!this.form.phone;
      const checkIsValid = !!this.formIsCheck;

      if (nameIsValid && phoneIsValid && checkIsValid) return true;
    },

    sell() {
      this.form.sell_or_rent = "Продать";
    },

    rent() {
      this.form.sell_or_rent = "Сдать";
    },

    onChange() {
      this.formIsCheck = !this.formIsCheck;
    },

    onInput(event) {
      const inputValue = event.target.value;
      const inputLength = inputValue.length;

      if (inputLength < 1) {
        this.form.name = null;
      }
    },

    onPhoneInput(event) {
      const inputValue = event.target.value;
      const inputLength = inputValue.length;

      if (inputLength < 18) {
        this.phoneKey += 1;
        this.form.phone = null;
      }
    },

    submitForm() {
      if (this.isValid()) {
        this.errors = [];
        this.invalid = false;
        this.sending = true;

        const url = this.overseas ? '/api/sendCountriesForm' : '/api/sendFeedback?type=sell_or_rent';

        if (this.overseas) {
          delete this.form.sell_or_rent;
        }

        this.axios
          .post(url, this.form)
          .then((response) => {
            if (response.status) {
              let form = this.form;

              form = {};

              Object.keys(form).forEach(function (key) {
                form[key] = "";
              });

              this.formIsCheck = false;
              this.sending = false;
              this.clearForm();
              this.updateKeyValue();
              this.phoneKey += 1;
              this.form.phone = null;
              this.form.name = null;
              this.isOpenPopupSuccessfully = true;
            }
          })
          .catch((error) => {
            this.sending = false;
            /* this.errors = error.response.data.errors; */
          });
      } else {
        this.invalid = true;
      }

      setTimeout(() => {
        this.isOpenPopupSuccessfully = false;
      }, 4000);
    },

    clearForm() {
      const form = this.$refs.feedbackForm;
      const formInputs = form.querySelectorAll("._fixed-label");

      form.reset();

      formInputs.forEach((input) => {
        input.classList.remove("_fixed-label");
      });
    },

    updateKeyValue() {
      this.updateKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-section {
  $that: &;

  position: relative;
  min-height: 442px;

  &_overseas {
    min-height: unset;

    .form-section__form {
      height: unset;
    }
  }

  &__inner {
    @extend %container;

    display: flex;
    max-width: 1192px;

    @include laptop {
      padding-right: 0;
    }

    @include tablet {
      flex-direction: column;
      padding-right: 32px;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @include phone-s {
      padding-right: 32px;
    }
  }

  &__text-block {
    color: $white;
    position: relative;
    z-index: 2;
    padding-right: calc(128 / 1920 * 100vw);
    padding-top: 48px;
    padding-bottom: 36px;

    @media (min-width: 1920px) {
      padding-right: 128px;
    }

    @include tablet {
      padding-top: 0;
      padding-right: 0;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 40px;

    @include laptop {
      padding-bottom: calc(40 / 1280 * 100vw);
    }

    @include tablet {
      padding-bottom: 24px;
    }

    @include phone {
      padding-bottom: 16px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__form {
    z-index: 2;
    flex: 0 0 500px;
    height: 408px;
    background-color: rgba(36, 42, 48, 0.9);
    padding: 56px 56px 40px;

    @include tablet {
      flex: none;
      height: auto;
    }

    @include phone {
      background-color: transparent;
      padding: 26px 0 0;
    }
  }

  &__radio {
    margin-bottom: 32px;

    & + & {
      margin-left: 20px;
    }

    @include phone {
      margin-bottom: 18px;
    }
  }

  &__check {
    width: 100%;
    margin: 10px 0 24px;

    a {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }

  &__input {
    & + & {
      margin-top: 6px;
    }
  }

  &__background-image {
    z-index: 1;
    position: relative;
    overflow: hidden;

    @include absolute-fill;

    img {
      width: 100%;
      height: 100%;

      @include object-fit(cover, center);
      @include lazy-loaded;
    }

    &::after {
      @include absolute-fill;

      content: "";
      background: linear-gradient(0deg, rgba(46, 48, 52, 0.3), rgba(46, 48, 52, 0.3));

      @include phone {
        background: linear-gradient(
          0deg,
          rgba(46, 48, 52, 0.95) 20%,
          rgba(46, 48, 52, 0.6) 50%,
          rgba(46, 48, 52, 0.3) 0%
        );
      }
    }
  }

  ._invalid {
    color: red;
    padding-top: 8px;
  }
}
</style>
