<template>
  <AppAccordion
    class="filter-ready"
    :arrow="true"
    :is-filter-head="isFilterHead"
    :is-filter-map="isFilterMap"
  >
    <template #header>
      {{ title }}
    </template>
    <div class="filter-ready__body">
      <ul class="filter-ready__ready-list">
        <li
          v-for="(ready, index) in query.offerDeadlines"
          :key="index"
          class="filter-ready__ready-item"
        >
          <FormCheck
            :value="ready.built_year + '_' + ready.ready_quarter"
            name="ready"
            :checked="ready.checked"
            @change.prevent=""
            @click.prevent="onChange(ready.built_year + '_' + ready.ready_quarter)"
          >
            {{ ready.ready_quarter + " кв " + ready.built_year }}
          </FormCheck>
        </li>
      </ul>
    </div>
  </AppAccordion>
</template>

<script>
import AppAccordion from "@/components/ui/AppAccordion.vue";
import FormCheck from "@/components/form/FormCheck.vue";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { mapActions, mapState } from "pinia";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterReady",
  components: { AppAccordion, FormCheck },
  props: {
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    isFilterMap: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Вид объекта",
    },
  },
  computed: {
    ...mapState(useFilterStore, ["query"]),
  },

  methods: {
    ...mapActions(useFilterStore, ["setQuartal", "returnRequiredParams"]),
    async onChange(val) {
      this.setQuartal(val);
      await setParamsInUrl(this, this.$route.query, {
        deadlines: this.query.currentDeadlines,
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-ready {
  $that: &;

  &__body {
    min-width: 224px;
    background-color: $color_dark-90;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    padding: 20px 22px;

    @include tablet {
      padding: 16px 16px 0;
    }
  }

  &__ready-list {
    // display: flex;
  }

  &__ready-item {
    & + & {
      margin-top: 14px;
    }
  }

  &__input {
    @include visually-hidden;
  }

  &__input:checked {
    & + #{$that}__button {
      background-color: $color-dark-50;
    }
  }

  &__button {
    cursor: pointer;
    width: 44px;
    height: 38px;
    border: 1px solid $color_dark-50;
    transition: background-color 0.25s ease;

    @include flex-center;

    &:hover {
      background-color: $color-dark-50;
    }
  }

  ._studio {
    margin-top: 20px;
  }

  &__option {
    width: 100%;
  }
}
</style>
