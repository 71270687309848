<template>
  <section class="filters-section" :class="{ '_show-map': query.showMap }">
    <!-- ФИЛЬТРЫ БАННЕР -->
    <div v-if="!query.showMap" ref="filters" class="filters" :class="{ '_show-all': query.showAllFilters }">
      <!-- КУПИТЬ/СНЯТЬ -->
      <div class="filters__head">
        <FIlterEstateTypeBuyOrRentReactive />
      </div>
      <!-- ОСНОВНЫЕ ФИЛЬТРЫ -->
      <div ref="upperFilters" class="filters__body" style="z-index: 110">
        <FilterAllCategoryReactive
          v-if="route.path === '/'"
          class="filters__filter"
          :is-filter-head="true"
          @click="closeAccordions"
        />
        <div v-if="query.currentEstateType.name === 'Вторичная недвижимость'" class="filters__wrapper">
          <FilterCategoryReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
          <FilterRoomReactive
            v-if="query.category[0] !== 8 && query.category[0] !== 5"
            class="filters__filter"
            :is-filter-head="true"
            @click="closeAccordions"
          />
          <FilterPrice class="filters__filter _price" />
          <FilterCityReactive class="filters__filter _select" />
          <FilterAreaReactive class="filters__filter _select" :disabled="query.subLocalitiesAreDisabled" />
        </div>
        <div v-if="query.currentEstateType.name === 'Новостройки'" class="filters__wrapper">
          <FilterReadyReactive
            class="filters__filter"
            :is-filter-head="true"
            title="Срок сдачи"
            @click="closeAccordions"
          />
          <FilterPrice class="filters__filter _price" />
          <FilterCityReactive class="filters__filter _select" :is-filter-head="true" @click="closeAccordions" />
          <FilterAreaReactive
            class="filters__filter _select"
            :is-filter-head="true"
            :disabled="query.subLocalitiesAreDisabled"
            @click="closeAccordions"
          />
          <FilterZHKReactive class="filters__filter _select" />
        </div>
        <div v-if="query.currentEstateType.name === 'Загородная'" class="filters__wrapper">
          <FilterCategoryReactive
            class="filters__filter"
            :category-list="query.offerCategories.resaleEstate"
            :is-filter-head="true"
            @click="closeAccordions"
          />
          <FilterPrice class="filters__filter _price" />
          <FilterCityReactive class="filters__filter _select" />
          <FilterAreaReactive class="filters__filter _select" :disabled="query.subLocalitiesAreDisabled" />
        </div>
        <div v-if="query.currentEstateType.name === 'Коммерческая'" class="filters__wrapper">
          <FilterPurposeReactive
            class="filters__filter"
            :is-filter-head="true"
            title="Вид объекта"
            @click="closeAccordions"
          />
          <FilterPrice :key="filterKey" class="filters__filter _price" />
          <FilterCityReactive class="filters__filter _select" />
          <FilterAreaReactive class="filters__filter _select" :disabled="query.subLocalitiesAreDisabled" />
          <FilterSquareAreaMeters class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
          <AppButton v-if="query.route === '/'" @click="goToPageAndSearch"> Поиск </AppButton>
          <div class="filters__action filters__action--clear reset" @click="clearFilters('FROM_MAIN')">СБРОСИТЬ</div>
        </div>
        <div v-if="query.currentEstateType.name !== 'Коммерческая'" class="filters__actions">
          <div class="filters__action filters__action--show-all" @click="toggleFilters">
            <span>Все фильтры</span>
            <app-svg id="arrow-bottom" width="12" height="6" />
          </div>
          <AppButton
            v-if="query.currentEstateType.name !== 'Коммерческая' && query.route === '/'"
            @click="goToPageAndSearch"
          >
            Поиск
          </AppButton>
        </div>
      </div>
      <!-- ДОП ФИЛЬТРЫ-->
      <div v-if="query.currentEstateType.name !== 'Коммерческая'" ref="bottomFilters" class="filters__bottom">
        <div v-if="query.currentEstateType.name === 'Вторичная недвижимость'" class="filters__wrapper">
          <FilterFloor class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
          <FilterSquareReactive
            v-if="query.minSquareRange"
            class="filters__filter"
            :is-filter-head="true"
            @click="closeAccordions"
          />
          <FilterKitchen class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
          <FilterNewFlat />
        </div>
        <div v-if="query.currentEstateType.name === 'Новостройки'" class="filters__wrapper">
          <FilterFurnish class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
        </div>
        <div v-if="query.currentEstateType.name === 'Загородная'" class="filters__wrapper">
          <FilterSquareReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
          <FilterSquareAreaReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
          <FilterRoomReactive
            v-if="query.category[0] !== 5"
            class="filters__filter"
            :is-filter-head="true"
            @click="closeAccordions"
          />
          <FilterRoadDistanceReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
          <div class="filters__filter _checks">
            <FilterCountryButtons />
          </div>
        </div>
        <div
          v-if="query.currentEstateType.name !== 'Коммерческая'"
          class="filters__action filters__action--clear"
          @click="clearFilters('FROM_MAIN')"
        >
          СБРОСИТЬ
        </div>
      </div>
    </div>

    <!-- ФИЛЬТРЫ КАРТА -->

    <Teleport :disabled="!query.showMobileMap" to="body">
      <div class="mobile-modal-map" :class="{ _active: query.showMobileMap }">
        <div class="mobile-actions" :class="{ _hide: curCard }">
          <div class="mobile-actions__inner">
            <AppButton :with-logo="true" @click="openMobileFilters"> Фильтры </AppButton>

            <AppButton :fourth="true" :class="{ _white: query.showMobileMap }" @click="showMobileMap()">
              {{ query.showMobileMap ? "Cписком" : "Карта" }}
            </AppButton>
          </div>
        </div>

        <!--        <TransitionFade>-->
        <section
          v-if="query.showMobileMap || query.showMap"
          class="map"
          :class="{ '_show-all-map': query.showAllFilters }"
        >
          <div class="map__inner">
            <div class="map__content">
              <AppToggle class="map__toggle" label="Показать на карте" checked @change="toggleMap" />
              <div ref="filters" class="map__filters filters _map-filter">
                <!--ФИЛЬТРЫ КАРТА - КУПИТЬ/СНЯТЬ -->
                <div class="filters__head">
                  <FIlterEstateTypeBuyOrRentReactive />
                </div>
                <!-- ФИЛЬТРЫ КАРТА - ОСНОВНЫЕ ФИЛЬТРЫ -->
                <div ref="upperFilters" class="filters__body" style="z-index: 109">
                  <FilterAllCategoryReactive
                    v-if="route.path === '/'"
                    class="filters__filter"
                    :is-filter-head="true"
                    @click="closeAccordions"
                  />
                  <div v-if="query.currentEstateType.name === 'Вторичная недвижимость'" class="filters__wrapper">
                    <FilterCategoryReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                    <FilterRoomReactive
                      v-if="query.category[0] !== 5"
                      class="filters__filter"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterPrice class="filters__filter _price" />
                    <FilterCityReactive class="filters__filter _select" />
                    <FilterAreaReactive class="filters__filter _select _select-map-filter" />
                  </div>

                  <div v-if="query.currentEstateType.name === 'Новостройки'" class="filters__wrapper">
                    <FilterReadyReactive
                      class="filters__filter"
                      :is-filter-head="true"
                      title="Срок сдачи"
                      @click="closeAccordions"
                    />
                    <FilterPrice class="filters__filter _price" />
                    <FilterCityReactive
                      class="filters__filter _select"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterAreaReactive
                      class="filters__filter _select _select-map-filter"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterZHKReactive class="filters__filter _select _select-map-filter" />
                  </div>

                  <div v-if="query.currentEstateType.name === 'Загородная'" class="filters__wrapper">
                    <FilterCategoryReactive
                      class="filters__filter"
                      :category-list="query.offerCategories.resaleEstate"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterPrice class="filters__filter _price" />
                    <FilterCityReactive class="filters__filter _select" />
                    <FilterAreaReactive class="filters__filter _select _select-map-filter" />
                  </div>

                  <div v-if="query.currentEstateType.name === 'Коммерческая'" class="filters__wrapper">
                    <FilterPurposeReactive
                      class="filters__filter"
                      :is-filter-head="true"
                      title="Вид объекта"
                      @click="closeAccordions"
                    />
                    <FilterPrice class="filters__filter _price" />
                    <FilterCityReactive class="filters__filter _select" />
                    <FilterAreaReactive class="filters__filter _select _select-map-filter" />
                    <FilterSquareAreaMeters
                      class="filters__filter _select-map-filter _area-metars-map-filter"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <!-- <AppButton v-if="query.route === '/'" @click="goToPageAndSearch"> Поиск </AppButton> -->
                  </div>

                  <!--ФИЛЬТРЫ КАРТА - ДОП ФИЛЬТРЫ-->
                  <!-- ДОП ФИЛЬТРЫ-->

                  <div
                    v-if="query.showAllFilters && query.currentEstateType.name === 'Вторичная недвижимость'"
                    class="filters__wrapper"
                  >
                    <FilterFloor
                      class="filters__filter _floor-filter-map"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterSquareReactive
                      v-if="query.minSquareRange"
                      class="filters__filter _square-filter-map"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterKitchen
                      class="filters__filter _kitchen-filter-map"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterNewFlat class="_flat-filter-map" />
                  </div>
                  <div
                    v-if="query.showAllFilters && query.currentEstateType.name === 'Новостройки'"
                    class="filters__wrapper"
                  >
                    <FilterFurnish
                      class="filters__filter _furnish-filter-map"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                  </div>
                  <div
                    v-if="query.showAllFilters && query.currentEstateType.name === 'Загородная'"
                    class="filters__wrapper"
                  >
                    <FilterSquareReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                    <FilterSquareAreaReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                    <FilterRoomReactive
                      v-if="query.category[0] !== 5"
                      class="filters__filter"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <FilterRoadDistanceReactive
                      class="filters__filter"
                      :is-filter-head="true"
                      @click="closeAccordions"
                    />
                    <div class="filters__filter _checks">
                      <FilterCountryButtons />
                    </div>
                  </div>

                  <div class="filters__actions-wrapper">
                    <div
                      style="color: #c8cccf"
                      :style="{
                        marginTop: query.currentEstateType.name === 'Коммерческая' ? '10px' : '',
                        marginBottom: query.currentEstateType.name === 'Коммерческая' ? '10px' : '',
                      }"
                      class="filters__action filters__action--clear"
                      @click="clearFilters('FROM_MAIN')"
                    >
                      СБРОСИТЬ
                    </div>
                    <div
                      v-if="query.currentEstateType.name !== 'Коммерческая'"
                      class="filters__action filters__action--show-all _hover-white"
                      @click="toggleFilters"
                    >
                      <span style="color: #ff823c">Все фильтры</span>
                      <app-svg :class="{ _active: query.showAllFilters }" id="arrow-bottom" width="12" height="6" />
                    </div>
                  </div>

                  <AppButton v-if="query.route === '/'" @click="goToPageAndSearch"> Поиск </AppButton>
                </div>
              </div>
              <MapMainFilterYandex
                v-if="query.showMap"
                ref="yandexmap"
                :show-map-mobile="query.showMobileMap"
                :show-mobile-map="showMobileMap"
                @changeCurCard="changeCurCard"
              />
            </div>
          </div>
        </section>
        <!--        </TransitionFade>-->
      </div>
    </Teleport>

    <!-- Мобайл фильтры -->
    <teleport to="body">
      <TransitionTranslateLeft>
        <div v-if="query.filterMobileMenuIsOpen" ref="filter-menu" class="mobile-filter-menu">
          <div class="mobile-filter-menu__head">
            <span @click="closeMobileFilters">
              <app-svg id="arrow-left" width="6" height="12" />
              назад
            </span>
            <span>Фильтр</span>
            <span @click="clearFilters('FROM_MAIN')">Сбросить</span>
          </div>

          <div
            ref="filters"
            class="mobile-filter-menu__body filters _map-filter"
            :class="{ '_show-all': query.showAllFilters }"
          >
            <!-- КУПИТЬ/СНЯТЬ -->
            <div class="filters__head">
              <FIlterEstateTypeBuyOrRentReactive />
            </div>

            <!-- ОСНОВНЫЕ ФИЛЬТРЫ -->
            <div ref="upperFilters" class="filters__body" style="z-index: 108">
              <FilterAllCategoryReactive
                v-if="route.path === '/'"
                class="filters__filter _select"
                :is-filter-head="true"
                @click="closeAccordions"
              />
              <div v-if="query.currentEstateType.name === 'Вторичная недвижимость'" class="filters__wrapper">
                <FilterCategoryReactive
                  class="filters__filter _select"
                  :is-filter-head="true"
                  @click="closeAccordions"
                />
                <FilterRoomReactive
                  v-if="query.category[0] !== 5"
                  class="filters__filter _select"
                  :is-filter-head="true"
                  @click="closeAccordions"
                />
                <FilterPrice class="filters__filter _price" />
                <FilterCityReactive class="filters__filter _select" />
                <FilterAreaReactive :disabled="query.subLocalitiesAreDisabled" class="filters__filter _select" />
              </div>
              <div v-if="query.currentEstateType.name === 'Новостройки'" class="filters__wrapper">
                <FilterReadyReactive
                  class="filters__filter"
                  :is-filter-head="true"
                  title="Срок сдачи"
                  @click="closeAccordions"
                />
                <FilterPrice class="filters__filter _price" />
                <FilterCityReactive :is-filter-head="true" class="filters__filter _select" @click="closeAccordions" />
                <FilterAreaReactive
                  :is-filter-head="true"
                  :disabled="query.subLocalitiesAreDisabled"
                  class="filters__filter _select"
                  @click="closeAccordions"
                />
                <FilterZHKReactive class="filters__filter _select" />
              </div>
              <div v-if="query.currentEstateType.name === 'Загородная'" class="filters__wrapper">
                <FilterCategoryReactive
                  :category-list="query.offerCategories.resaleEstate"
                  class="filters__filter"
                  :is-filter-head="true"
                  @click="closeAccordions"
                />
                <FilterPrice class="filters__filter _price" />
                <FilterCityReactive class="filters__filter _select" />
                <FilterAreaReactive :disabled="query.subLocalitiesAreDisabled" class="filters__filter _select" />
              </div>
              <div v-if="query.currentEstateType.name === 'Коммерческая'" class="filters__wrapper">
                <FilterPurposeReactive
                  class="filters__filter"
                  :is-filter-head="true"
                  title="Вид объекта"
                  @click="closeAccordions"
                />
                <FilterPrice :key="filterKey" class="filters__filter _price" />
                <FilterCityReactive class="filters__filter _select" />
                <FilterAreaReactive :disabled="query.subLocalitiesAreDisabled" class="filters__filter _select" />
                <FilterSquareAreaMeters :is-filter-head="true" class="filters__filter" @click="closeAccordions" />
                <!-- <AppButton style="margin-top: 10px" v-if="query.route === '/'" @click="goToPageAndSearch">
                  Поиск
                </AppButton> -->
              </div>
              <div class="filters__actions-wrapper">
                <div
                  v-if="query.currentEstateType.name !== 'Коммерческая'"
                  class="filters__action filters__action--show-all"
                  @click="toggleFilters"
                >
                  <span> Все фильтры</span>
                  <app-svg id="arrow-bottom" width="12" height="6" />
                </div>
              </div>
            </div>
            <!-- ДОП ФИЛЬТРЫ-->
            <div v-if="query.showAllFilters" ref="bottomFilters" class="filters__body" style="z-index: 107">
              <div v-if="query.currentEstateType.name === 'Вторичная недвижимость'" class="filters__wrapper">
                <FilterFloor class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                <FilterSquareReactive
                  v-if="query.minSquareRange"
                  class="filters__filter"
                  :is-filter-head="true"
                  @click="closeAccordions"
                />
                <FilterKitchen class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                <FilterNewFlat />
              </div>

              <div v-if="query.currentEstateType.name === 'Новостройки'" class="filters__wrapper">
                <FilterFurnish class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
              </div>

              <div v-if="query.currentEstateType.name === 'Загородная'" class="filters__wrapper">
                <FilterSquareReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                <FilterSquareAreaReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                <FilterRoomReactive
                  v-if="query.category[0] !== 5"
                  class="filters__filter"
                  :is-filter-head="true"
                  @click="closeAccordions"
                />
                <FilterRoadDistanceReactive class="filters__filter" :is-filter-head="true" @click="closeAccordions" />
                <div class="filters__filter _checks">
                  <FilterCountryButtons />
                </div>
              </div>
            </div>
            <AppButton style="width: 100%; margin-bottom: 56px" @click="closeMobileFiltersAndSearch">
              Показать
            </AppButton>
          </div>
        </div>
      </TransitionTranslateLeft>
    </teleport>
    <EstateList
      v-if="route.path !== '/' && query.currentEstateType.name !== 'Новостройки'"
      :estate-list="cardData"
      :meta="cardMeta"
      @page-changed="onPageChanged"
      @show-more="onShowMore"
      @map-toggle="toggleMap"
    />
    <EstateNewList
      v-if="route.path !== '/' && query.currentEstateType.name === 'Новостройки'"
      :estate-list="cardData"
      :meta="cardMeta"
      @show-more="onShowMore"
      @page-changed="onPageChanged"
      @map-toggle="toggleMap"
    />
  </section>
</template>
<script>
import EstateList from "@/components/EstateList.vue";
import AppButton from "@/components/ui/AppButton.vue";
import FilterFloor from "@/components/filters/FilterFloor.vue";
import FilterPrice from "@/components/filters/FilterPrice.vue";
import FilterCityReactive from "@/components/filters/FilterCityReactive.vue";
import FilterReadyReactive from "@/components/filters/FilterReadyReactive.vue";
import FilterAreaReactive from "@/components/filters/FilterAreaReactive.vue";
import FilterSquareReactive from "@/components/filters/FilterSquareReactive.vue";
import FilterKitchen from "@/components/filters/FilterKitchen.vue";
import FilterNewFlat from "@/components/filters/FilterNewFlat.vue";
import FilterRoomReactive from "@/components/filters/FilterRoomReactive.vue";
import FilterCategoryReactive from "@/components/filters/FilterCategoryReactive.vue";
import FilterAllCategoryReactive from "@/components/filters/FilterAllCategoryReactive.vue";
import FIlterEstateTypeBuyOrRentReactive from "@/components/filters/FIlterEstateTypeBuyOrRentReactive.vue";
import FilterSquareAreaMeters from "@/components/filters/FilterSquareAreaMeters.vue";
import FilterPurposeReactive from "@/components/filters/FilterPurposeReactive.vue";
import EstateNewList from "@/components/EstateNewList.vue";
import FilterSquareAreaReactive from "@/components/filters/FilterSquareAreaReactive.vue";
import FilterRoadDistanceReactive from "@/components/filters/FilterRoadDistanceReactive.vue";
import FilterCountryButtons from "@/components/filters/FilterCountryButtons.vue";
import FilterZHKReactive from "@/components/filters/FilterZHKReactive.vue";
import FilterFurnish from "@/components/filters/FilterFurnish.vue";
import MapMainFilterYandex from "@/components/MapMainFilterYandex.vue";
import AppToggle from "@/components/ui/AppToggle.vue";
import TransitionTranslateLeft from "@/components/transitions/TransitionTranslateLeft.vue";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import useEmitter from "@/plugins/useEmitter.js";
import { getQuery, queryIsEmpty, setParamsInUrl } from "@/plugins/helpers.js";

import axios from "axios";

export default {
  components: {
    EstateList,
    AppButton,
    FilterFloor,
    FilterPrice,
    FilterCityReactive,
    FilterReadyReactive,
    FilterAreaReactive,
    FilterSquareReactive,
    FilterKitchen,
    FilterNewFlat,
    FilterRoomReactive,
    FilterCategoryReactive,
    FilterAllCategoryReactive,
    FIlterEstateTypeBuyOrRentReactive,
    FilterSquareAreaMeters,
    FilterPurposeReactive,
    EstateNewList,
    FilterSquareAreaReactive,
    FilterRoadDistanceReactive,
    FilterCountryButtons,
    FilterZHKReactive,
    FilterFurnish,
    MapMainFilterYandex,
    AppToggle,
    TransitionTranslateLeft,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const emitter = useEmitter();
    const store = useFilterStore();
    const cardData = ref([]);
    const cardMeta = ref({});
    const { query, pageShowMore } = storeToRefs(store);
    const toggleFilters = store.toggleFilters;
    const yandexmap = ref(null);
    const curCard = ref(false);
    const changeCurCard = () => (curCard.value = !curCard.value);

    start();
    emitter.on("toggle-map", toggleMap);
    emitter.on("load-with-params", load);
    emitter.on("clear-filter", clearFilters);

    async function load(prop) {
      const res = await store.load(route, prop);
      emitter.emit("updateSlider");
      if (yandexmap.value !== null) {
        yandexmap.value.toggleObjects();
      }

      setCardAndMeta(res);
      await store.checkScroll();

      store.scrollY = 0;

      pageShowMore.value = false;
    }

    async function start(prop) {
      store.setRoute(route.path);
      /*store.query.showMap = false;*/
      store.query.showAllFilters = false;
      await store.getOffersFilters(route, prop);
      await store.setFirstSettings(route);
      await store.getRangeForCategory(route);
      await store.setInterfaceStateAction(route);
      await load();
    }

    async function clearFilters(path) {
      document.querySelector("body").style = "overflow: auto";
      const input = document.querySelector(".estate-list__toggle-map input");
      if (input) {
        input.checked = false;
      }
      store.query.showMap = false;
      store.query.showMobileMap = false;

      if (route.path === "/" && path !== "FROM_MAIN") {
        store.scrollY = 0;
        store.setCurrentEstateTypeByCategory(store.query.category[0]);
        start();
        // await setParamsInUrl(store, route.query, { page: 1 }, "ONLY_FOR_CATEGORY_SET");
      } else if (route.path === "/" && path === "FROM_MAIN") {
        await router.push({ query: {} });
        start();
      } else {
        store.setCurrentEstateType(route.path);
        store.setSettings();
        if (path === "FROM_MAIN") {
          await router.push({ query: {} });
          start();
        }
      }
    }

    function setCardAndMeta(res) {
      cardMeta.value = res.data.meta;
      cardData.value = [...res.data.data];
    }

    async function goToPageAndSearch() {
      const query = getQuery(null, route.query);
      queryIsEmpty(route) ? await pushStandartQueryAndSearch() : await pushCurrentQueryAndSearch(query);
    }

    async function pushStandartQueryAndSearch() {
      await router.push({ path: store.getPathDependingByCategory() });
    }

    async function pushCurrentQueryAndSearch(query) {
      const path = store.getPathDependingByCategory();
      await router.push({ path: path, query: query });
    }

    async function onPageChanged(page) {
      store.saveScrollTo();
      store.setPage(page);
      await setParamsInUrl(store, route.query, {
        page: page,
      });
      emitter.emit("load-with-params");
    }

    async function onShowMore() {
      pageShowMore.value = true;

      const params = store.getParams(route.query);
      params.page = cardMeta.value.current_page + 1;

      const res = await axios.get("/api/getOffersList", {
        params: params,
      });

      res.data.data.map((el) => {
        cardData.value.push(el);
      });
      cardMeta.value = res.data.meta;

      await setParamsInUrl(store, route.query, {
        page: params.page,
      });
    }

    function showMap() {
      store.query.showMap ? (store.query.showMap = !store.query.showMap) : (store.query.showMap = true);
    }

    function showMobileMap() {
      showMap();

      if (store.query.showMobileMap) {
        store.query.showMobileMap = !store.query.showMobileMap;
        //   document.querySelector("body").style = "overflow: auto"
      } else {
        store.query.showMobileMap = true;
        //   document.querySelector("body").style = "overflow: hidden"
      }
    }

    function toggleMap(event) {
      if (event.target.checked) {
        store.query.showMap = true;
      } else {
        store.query.showMap = false;
        document.querySelector(".estate-list__toggle-map input").checked = false;
      }
    }

    function openMobileFilters() {
      store.query.filterMobileMenuIsOpen = true;
      document.querySelector("body").style = "overflow: hidden";
    }

    function closeMobileFilters() {
      store.query.filterMobileMenuIsOpen = false;
      document.querySelector("body").style = "overflow: auto";
    }

    function closeMobileFiltersAndSearch() {
      store.query.filterMobileMenuIsOpen = false;
      document.querySelector("body").style = "overflow: auto";
      if (route.path === "/") {
        goToPageAndSearch();
      }
    }

    onMounted(() => {
      /*store.query.showMobileMap = false;*/
      /*store.query.showMap = false;*/
    });

    onBeforeUnmount(function () {
      emitter.all.clear();
    });

    return {
      query,
      onPageChanged,
      onShowMore,
      toggleFilters,
      clearFilters,
      toggleMap,
      openMobileFilters,
      closeMobileFilters,
      closeMobileFiltersAndSearch,
      cardData,
      cardMeta,
      showMap,
      showMobileMap,
      goToPageAndSearch,
      route,
      curCard,
      changeCurCard,
      yandexmap,
    };
  },

  methods: {
    closeAccordions(event) {
      const accordions = this.$refs.filters.querySelectorAll(".accordion._filter-head");
      accordions.forEach((accordion) => {
        if (event.currentTarget !== accordion) {
          accordion.classList.remove("_is-open");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  min-width: 88px;
}

.m {
  margin-left: 40px;
}

.dev-total {
  font-size: 44px;
}

.filters-section {
  position: relative;
  z-index: 10;
  padding-top: 119px;

  @include desktop {
    padding-top: 90px;
  }

  @include laptop {
    padding-top: 0;
  }

  .main-page & {
    padding-top: 72px;

    @include laptop {
      padding-top: 40px;
    }
  }
}

.filters {
  $that: &;

  position: absolute;
  top: -112px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);

  @include desktop {
    padding-bottom: 128px;
  }

  @include laptop {
    display: none;
    position: static;
  }

  .mobile-filter-menu & {
    transform: translate3d(0, 0, 0) !important;

    @include laptop {
      display: block;
    }
  }

  &._map-filter {
    max-width: 446px;
    max-height: 100%;
    margin: 0;
    padding: 20px 24px 28px;
    z-index: 99;

    .mobile-filter-menu & {
      max-width: 100%;
    }
  }

  .mobile-filter-menu & {
    /*height: 100%;*/
    height: -webkit-fill-available;
    overflow: auto;
  }

  &._show-all {
    transform: translate3d(0, calc((-56px - (30px * 2)) / 2), 0);
    z-index: 100;
  }

  &__wrapper {
    display: contents;
  }

  &__head {
    ._map-filter & {
      display: flex;
      border: 1px solid $color_dark-40;
      margin-bottom: 22px;

      label {
        flex: 0 0 50%;
      }
    }
  }

  &__body,
  &__bottom {
    /*    height: 700px; // убрать*/
    position: relative;
    z-index: 100;
    background-color: $color_dark-90;
    display: flex;
    align-items: center;
    padding: 30px 22px;

    ._map-filter & {
      display: block;
      padding: 0;

      *:not(.multiselect) {
        padding: 18px 0px 16px;
        border-bottom: 1px solid $color_dark-70;
      }

      *:last-child {
        border: 0;
      }

      .multiselect {
        margin: 12px -8px 20px;
        padding: 0 8px;
        position: relative;
        min-width: calc(100% + 16px);

        &::after {
          content: "";
          width: calc(100% - 16px);
          height: 1px;
          background-color: $color_dark-70;
          position: absolute;
          bottom: -4px;
          left: 8px;
        }
      }

      ._price {
        padding: 16px 16px 16px;
        border-bottom: 0;
      }
    }

    @include desktop {
      padding: 12px 22px 16px;
    }
  }

  &__bottom {
    border-top: 1px solid $color_dark-70;
    z-index: 1;
    transform: translate3d(0, -100%, 0);
    pointer-events: none;
    transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1), opacity 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;

    @include laptop {
      transform: translate3d(0, -17px, 0);
      pointer-events: all;
    }

    ._show-all & {
      transform: translate3d(0, 0, 0);
      pointer-events: all;
      opacity: 1;
    }

    ._map-filter & {
      transition: none;
      display: none;
      transform: none;
      opacity: 1;
    }

    ._show-all-map & {
      display: block;
      border-top: 0;
      margin-top: -8px;
      pointer-events: all;
    }
  }

  &__action-type {
    overflow: hidden;

    input {
      @include visually-hidden;
    }
  }

  &__filter {
    position: relative;
    min-width: 170px;
    max-width: 190px;

    &._checks {
      max-width: 292px;
      padding-right: 0;
      display: flex;

      * {
        border-bottom: 0 !important;
      }

      * + * {
        margin-left: 22px;

        @include phone {
          margin-left: 12px;
        }
      }
    }

    &._price {
      min-width: fit-content;
      max-width: 292px;
    }

    &._filter-head {
      padding: 0 12px;
    }

    ._map-filter & {
      max-width: 100%;
    }

    & + & {
      &::before {
        content: "";
        height: 50px;
        width: 1px;
        background-color: $color_dark-70;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        ._map-filter & {
          display: none;
        }
      }
    }
  }

  &__actions-wrapper {
    padding: 0 8px !important;
    border-bottom: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include laptop {
      margin-bottom: 22px;
    }
  }

  &__action {
    cursor: pointer;
    color: $white;
    padding: 0 !important;
    border-bottom: 0 !important;

    &::before {
      display: none;
    }

    &--show-all {
      margin-left: auto;
      display: flex;
      align-items: center;

      span {
        border-bottom: 0 !important;
      }

      svg {
        flex: none;
        margin-left: 16px;
        transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
        padding: 0 !important;

        ._show-all & {
          transform-origin: center;
          transform: rotate(180deg);
        }
      }

      @include hover {
        text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 50px rgba(255, 255, 255, 0.8),
          0 0 35px rgba(255, 255, 255, 0.6), 0 0 76px rgba(255, 255, 255, 0.4), 0 0 37px rgba(255, 255, 255, 0.5),
          0 0 78px rgba(255, 255, 255, 0.4), 0 0 39px rgba(255, 255, 255, 0.3), 0 0 80px rgba(255, 255, 255, 0.2),
          0 0 35px rgba(255, 255, 255, 0.1);
      }

      &._hover-white {
        @include hover {
          text-shadow: unset;
        }

        & span {
          transition: color ease 0.3s;
          @include hover {
            color: #fff !important;
          }
        }
      }
    }

    &--go {
      margin-left: 24px;

      .button {
        @include desktop {
          min-width: auto;
        }
      }
    }

    &--clear {
      margin-left: auto;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      color: #fff;

      ._map-filter & {
        margin-left: 0;
      }

      @include hover {
        color: #fff !important;

        text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 50px rgba(255, 255, 255, 0.8),
          0 0 35px rgba(255, 255, 255, 0.6), 0 0 76px rgba(255, 255, 255, 0.4), 0 0 37px rgba(255, 255, 255, 0.5),
          0 0 78px rgba(255, 255, 255, 0.4), 0 0 39px rgba(255, 255, 255, 0.3), 0 0 80px rgba(255, 255, 255, 0.2),
          0 0 35px rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__actions {
    display: flex;
    margin-left: auto;

    .button {
      margin-left: 24px;

      @media (max-width: 1920px) {
        margin-left: calc(24 / 1920 * 100vw);
        padding: calc(20 / 1920 * 100vw) calc(12 / 1920 * 100vw);
      }

      @include desktop {
        min-width: calc(64 / 1280 * 100vw);
        height: auto;
      }
    }
  }
}

.map {
  // margin-bottom: 68px;
  height: 800px;

  // @include desktop {
  //   margin-bottom: 48px;
  // }

  &__inner {
    @extend %container;
  }

  &__content {
    position: relative;
  }

  &__toggle {
    position: absolute;
    right: 0;
    top: -64px;
    max-width: 182px;
    z-index: 2;

    @include laptop {
      display: none;
    }
  }

  &__filters {
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    background: $color_dark-90;
    width: 386px;
    border-right: 4px solid transparent;
  }

  &__yandex-map {
    height: 628px;

    .ymaps-2-1-79-gotoymaps,
    .ymaps-2-1-79-gototaxi,
    .ymaps-2-1-79-gototech,
    .ymaps-2-1-79-copyright {
      display: none !important;
    }

    .map & {
      @include laptop {
        display: none;
      }
    }

    @include phone {
      height: 100%;
      overflow: hidden;
    }
  }

  &__estate-card {
    transition: opacity 0.25s ease-in-out;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 336px;
    min-height: 482px;
    height: auto;

    &._is-showing {
      opacity: 1;
      pointer-events: all;
    }

    @include laptop {
      top: 38px;
    }

    &--mobile {
      @include phone {
        width: 100%;
        top: auto;
        bottom: 0;
        transform: translate3d(0, 90%, 0);
        transition: opacity 0.25s 0.1s, transform 0.3s ease-out;

        &._is-showing {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.mobile-actions {
  padding-top: 34px;

  &__inner {
    @extend %container;

    display: flex;
    justify-content: space-between;
  }

  .button {
    flex: 0 0 48%;
  }

  @media (min-width: 1280px) {
    display: none;
  }

  @include phone {
    padding-top: 24px;
  }
}

.mobile-filters {
  position: relative;
  width: 90vw;
  height: 100%;
  overflow: hidden;

  &__actions {
    padding: 0 16px;
    position: absolute;
    width: 100%;
    max-width: 320px;
    z-index: 99;
    top: 16px;
    display: flex;
    justify-content: space-between;

    .button {
      flex: 0 0 48%;
    }

    @include phone {
      max-width: 100%;
    }
  }

  @include phone {
    width: 100vw;
    height: 100%;
  }
}

.mobile-filter-menu {
  background-color: $color_dark-90;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  min-width: 100vw;
  min-height: 100%;
  max-height: -webkit-fill-available;
  transition: opacity 0.1s ease, transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    padding: 16px 24px;
    flex: none;

    @include phone-s {
      padding: 16px 16px;
    }

    span:first-child {
      color: $color_primary;
      display: flex;
      align-items: center;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;

      svg {
        flex: none;
        margin-right: 12px;
      }
    }

    span:nth-child(2) {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }

    span:last-child {
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  &__body {
    height: 100%;
    flex: 1 1 0%;
  }
}

.mobile-modal-map {
  &._active {
    position: fixed;
    z-index: 9999;
    background: #f1f1f1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & .mobile-actions {
      position: absolute;
      z-index: 99;
      left: 0;
      top: 20px;
      width: 100%;
      padding-top: 0;

      opacity: 1;

      transition: opacity ease 400ms, top ease-in-out 500ms;

      &._hide {
        top: -100%;
        opacity: 0;
      }
    }

    //  & .map .map__inner .map__content section #mapBlock.map-detail {
    //    height: 100vh !important;
    //  }

    & .map {
      height: 100% !important;
      width: 100%;

      &__inner,
      &__content {
        padding: 0 !important;
        height: 100%;
      }

      & .ymaps {
        height: 100% !important;
      }
    }
  }
}
</style>
