<template>
  <div class="card-estate-slider">
    <swiper
      effect="fade"
      class="card-estate-slider__swiper"
      :observer="true"
      :pagination="{
        el: '._pagination',
        clickable: true,
      }"
      :speed="800"
      :modules="modules"
      @swiper="swiperInit"
    >
      <swiper-slide
        v-for="(image, index) in imageList"
        v-show="index < 4"
        :key="index"
        class="card-estate-slider__slide"
      >
        <div class="_hover-area _hover-area--1" />
        <div class="_hover-area _hover-area--2" />
        <div class="_hover-area _hover-area--3" />
        <div class="_hover-area _hover-area--4" />
        <img
          class="lazy"
          :data-src="image + '?' + random"
          alt=""
          @error="onError"
        >
        <div
          v-if="imageError"
          class="_image-error"
        >
          <svg
            width="184"
            height="156"
            viewBox="0 0 184 156"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M56.7695 0L86.1851 13.1727L86.1985 13.1799C86.4796 13.3309 86.75 13.4747 87.0104 13.6132C88.5556 14.4349 89.747 15.0684 90.7219 15.9041C91.8858 16.9017 92.731 18.1724 93.5936 20.3348C94.6582 23.0034 95.009 27.3263 95.1039 31.8045C95.1645 34.6654 95.1203 37.6361 95.0802 40.3244C95.0592 41.7367 95.0393 43.0711 95.0364 44.2707L175.951 74.9969V138.896L184 139.462V153.297L94.6188 156H35.9983L0 154.138V84.0079L3.67153 81.0343V74.1493L8.03539 68.7173L12.3515 69.9235L16.3807 65.2211V57.437L23.0107 49.4593L28.3827 51.0186L41.2749 36.1372L32.3517 33.3216L56.7695 0ZM41.8809 35.4378L52.6938 22.9566L59.7329 25.8803V15.6783H60.5802V26.2322L86.9869 37.2003V155.15H94.1888V44.5639C94.1888 43.2644 94.2104 41.8154 94.2334 40.2813C94.2734 37.6063 94.3172 34.6728 94.2568 31.8226C94.1616 27.3285 93.8064 23.1557 92.807 20.6503C91.975 18.5649 91.1963 17.4284 90.1714 16.5499C89.2731 15.78 88.1776 15.1968 86.6239 14.3697C86.3662 14.2325 86.0959 14.0886 85.8118 13.936L57.0478 1.05506L33.7358 32.8677L41.8809 35.4378ZM95.0361 155.137L183.153 152.473V140.254L175.507 139.716L175.498 139.715L95.0361 135.773V155.137ZM95.0361 134.922L175.104 138.846V75.5836L95.0361 45.179V134.922ZM86.1397 155.15V37.7679L53.2371 24.1018V155.15H86.1397ZM52.3899 155.15V24.6028L29.4541 51.0773H30.2554L36.4328 52.1349V155.15H52.3899ZM35.5856 155.128V144.814H30.6431V154.872L35.5856 155.128ZM29.7959 154.828V144.863L0.847275 148.027V153.331L29.7959 154.828ZM0.847275 147.172L29.7959 144.008V51.9269H28.7299L22.7352 59.5197V51.1178L17.2279 57.7445V65.5359L7.76669 76.5781V70.4061L4.5188 74.4489V81.4402L0.847275 84.4137V147.172ZM8.61397 69.761V74.2856L11.7418 70.635L8.61397 69.761ZM23.5825 50.5098V57.0778L27.8013 51.7343L23.5825 50.5098ZM30.6431 52.0056L35.5856 52.8517V143.964H33.1143H30.6431V52.0056Z"
              fill="#68717B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M82.1369 19.7397C80.5666 18.9524 61.117 10.0736 52.7852 6.43921L53.1231 5.66016C61.4544 9.29433 80.923 18.1812 82.5158 18.9798C82.6592 19.0517 82.8034 19.1231 82.9481 19.1948C84.7754 20.1005 86.6803 21.0445 88.0576 23.3922C88.8058 24.6676 89.1689 26.1494 89.3664 27.805C89.5345 29.2148 89.5851 30.7809 89.64 32.4789C89.6494 32.7698 89.6589 33.0646 89.6692 33.3631C89.7825 36.6582 89.7165 117.212 89.6694 155.576L88.8221 155.575C88.8692 117.193 88.935 36.6673 88.8224 33.3924C88.812 33.0896 88.8024 32.7922 88.7929 32.5C88.7378 30.7992 88.6884 29.276 88.5251 27.9059C88.3342 26.3048 87.9912 24.9547 87.3273 23.823C86.0822 21.7005 84.3909 20.8602 82.5509 19.946C82.4137 19.8778 82.2757 19.8092 82.1369 19.7397Z"
              fill="#68717B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M71.4053 46.4258L75.799 50.0054L71.6799 50.5561L71.4053 46.4258ZM71.7302 47.0554L71.9416 50.2354L75.113 49.8114L71.7302 47.0554Z"
              fill="#68717B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M57.332 32.7852L77.7155 50.4382L57.332 54.221V32.7852ZM57.6145 33.4039V53.8806L77.0858 50.267L57.6145 33.4039Z"
              fill="#68717B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M58.3047 35.2417L60.7697 37.1642L60.9572 39.9831L61.5254 37.7039L64.412 40.3352L65.2421 46.9947L65.8683 41.3442L70.738 45.4561V47.0123L69.4068 46.078L70.738 47.4127V50.762L67.0533 51.2898V48.567L68.5711 48.3495L67.062 47.0254L66.5941 51.2476L62.2425 52.0176L60.7743 48.0216V52.2428L58.3047 52.4957V35.2417ZM58.5871 35.8207V52.1906L60.4918 51.9596V47.2277L60.7656 47.1788L62.4259 51.6976L66.3368 51.0055L66.841 46.4553L69.2201 48.5426L67.3357 48.8126V50.9633L70.4555 50.5164V47.53L68.0667 45.1347L68.2476 44.9186L70.4555 46.4684V45.5878L66.0906 41.9021L65.3907 48.218L65.1103 48.22L64.1447 40.4743L61.6848 38.232L61.0131 40.9264L60.7352 40.9015L60.4964 37.3097L58.5871 35.8207ZM62.6432 42.3924L63.9313 49.8841L63.6588 49.9547L61.6951 44.2937L62.6432 42.3924ZM62.001 44.3135L63.3611 48.2344L62.511 43.2906L62.001 44.3135Z"
              fill="#68717B"
            />
          </svg>
        </div>
      </swiper-slide>

      <swiper-slide v-if="imageList.length === 0">
        <div class="card-estate-slider__slide">
          <img
            class="swiper-lazy"
            :data-src="require('@/assets/images/filler.png')"
            alt=""
          >
        </div>
      </swiper-slide>

      <div
        v-if="imageList.length > 1"
        class="card-estate-slider__pagination _pagination _pagination-static"
      />
    </swiper>
  </div>
</template>

<script>
import { Pagination, Lazy, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "CardEdtateSlider",
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    imageList: {
      type: Array,
      default: () => [],
    },
    random: {
      type: Number,
      String,
      default: 1,
    },
  },

  setup() {
    const swiperInit = (swiper) => {
      swiper.el.addEventListener("mouseover", (e) => {
        if (e.target.classList.contains("_hover-area--1")) {
          swiper.slideTo(0);
        } else if (e.target.classList.contains("_hover-area--2")) {
          swiper.slideTo(1);
        } else if (e.target.classList.contains("_hover-area--3")) {
          swiper.slideTo(2);
        } else if (e.target.classList.contains("_hover-area--4")) {
          swiper.slideTo(3);
        }
      });
    };

    return {
      swiperInit,
      modules: [Pagination, Lazy, EffectFade],
    };
  },

  data() {
    return {
      imageError: false,
    };
  },

  methods: {
    onError(event) {
      // this.imageError = true;
      // event.target.style.opacity = "0";
    },
  },
};
</script>

<style lang="scss" scoped>
.card-estate-slider {
  background-color: $white;

  &__swiper {
    position: relative;
    height: 100%;
    min-height: 100%;

    .swiper-wrapper {
      align-items: stretch;
    }

    ._is-small & {
      @include tablet {
        min-height: 256px;
      }
    }
  }

  &__slide {
    position: relative;
    height: auto;
    min-height: 100%;

    ._hover-area {
      position: absolute;
      width: 25%;
      height: 100%;
      top: 0;
      left: 0;

      &--2 {
        left: 25%;
      }

      &--3 {
        left: 50%;
      }

      &--4 {
        left: 75%;
      }
    }

    ._image-error {
      @include absolute-fill;
      @include flex-center;
    }

    ._no-image {
      display: none;

      &:only-child {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
      min-height: 212px;
      max-height: 278px;

      ._is-small & {
        min-height: 256px;
        max-height: 256px;

        @include phone {
          min-height: 212px;
        }
      }

      @include object-fit(cover, center);
      @include lazy-loaded;
    }
  }

  &__pagination {
    z-index: 2;
    position: absolute;
    bottom: 9px;
    opacity: 0;
    transition: opacity 0.25s 0.1s ease-out;

    .estate-card:hover & {
      opacity: 1;
    }

    .new-estate-card:hover & {
      opacity: 1;
    }

    @include tablet {
      opacity: 1;
    }
  }
}
</style>
