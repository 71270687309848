<!-- <AppButtonSocial href="https://t.me/uniquefabricru" social="telegram" /> -->

<template>
  <a
    :href="href"
    target="_blank"
    :class="classes"
    class="button-social"
  >
    <app-svg :id="social" />
  </a>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "ButtonSocial",
  props: {
    href: {
      type: [String, Array],
      default: null,
    },
    social: {
      type: [String, Array],
      default: null,
    },
    primary: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        _secondary: !props.primary,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
.button-social {
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #40464c;
  padding: 10px;
  color: #969ba0;
  transition: color 0.25s ease-out, transform 0.25s ease-out, border-color 0.25s ease-out;

  svg {
    max-height: 20px;
    max-width: 20px;
  }

  @include hover {
    color: $color_primary;
    border-color: $white;
    transform: translateY(-4px);
  }

  @include phone {
    border-color: $color_dark-40;
    color: $color_dark-40;
  }
}
</style>
