<template>
  <FormSelectReactive
    class="filter-city"
    :options="currentCitiesRange"
    :selected="query.currentCity"
    placeholder="Город"
    :preselect-first="false"
    @select="onSelect"
  />
</template>

<script>
import FormSelectReactive from "./../form/FormSelectReactive.vue";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { mapActions, mapState } from "pinia";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterCity",
  components: { FormSelectReactive },
  computed: {
    ...mapState(useFilterStore, ["currentCitiesRange", "query"]),
  },
  methods: {
    ...mapActions(useFilterStore, ["setCurrentCity", "returnRequiredParams"]),
    async onSelect(city) {
      await this.setCurrentCity(city, this.$route);
      await setParamsInUrl(this, this.$route.query, {
        city: city,
        subLocality: [],
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style lang="scss" scoped></style>
