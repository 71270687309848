<!-- <FormRadio> Радио баттон </FormRadio> -->

<template>
  <label
    class="form-radio"
    :class="classes"
  >
    <input
      v-bind="$attrs"
      :id="id"
      class="form-radio__input"
      type="radio"
      :name="name"
      :value="value"
    >
    <div
      :id="check1"
      class="form-radio__box"
    >
      <div
        :id="check2"
        class="form-radio__in-box"
      />
    </div>
    <div class="form-radio__text">
      <slot />
    </div>
  </label>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  props: {
    name: {
      type: String,
      default: "checkName",
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Array],
      default: null,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_is-dark": props.isDark,
      })),
    };
  },
  computed: {
    check1() {
      return this.checked ? "checked1" : "";
    },
    check2() {
      return this.checked ? "checked2" : "";
    },
  },
};
</script>

<style lang="scss">
#checked1 {
  border-color: #f4691b;
}

#checked2 {
  opacity: 1;
  background-color: #f4691b;
}

.form-radio {
  $that: &;

  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 19px;
  user-select: none;
  cursor: pointer;

  &__input {
    @include visually-hidden;

    /*    &:checked {
          &+#{$that}__box {
            border-color: #f4691b;

            #{$that}__in-box {
              opacity: 1;
              background-color: #f4691b;
            }
          }
        }*/
  }

  &__box {
    position: relative;
    flex: none;
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
    border: 1px solid;
    transition: border-color 0.15s ease;

    ._is-dark & {
      color: $white;
    }

    #{$that}:hover & {
      border-color: $color_primary;
    }
  }

  &__in-box {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: auto;
    opacity: 0;
    background: #f4691b;
    transition: opacity 0.25s 0.1s ease, background-color 0.25s 0.1s ease;
  }

  &__text {
    text-transform: uppercase;
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    transition: color 0.25s ease;

    ._is-dark & {
      color: $white;
    }

    #{$that}:hover & {
      color: $color_primary;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
