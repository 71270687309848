import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Global Components
import AppSvg from "@/components/ui/AppSvg.vue";
import AppTitle from "@/components/ui/AppTitle.vue";

// Plugins
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/images/sprite";
import "@/plugins/lazy-sizes";
import { createHead } from "@vueuse/head"; // head, meta
// import { createHead } from '@unhead/vue';
import { vfmPlugin } from "vue-final-modal"; // Модалка
import "swiper/css/bundle";
import "@vuepic/vue-datepicker/dist/main.css";

import { TippyDirective } from "tippy.vue";
import { createPinia } from "pinia";
import Vue3TouchEvents from "vue3-touch-events";
import mitt from "mitt";

const app = createApp(App);
app.config.unwrapInjectedRef = true; // Конфигураия для provide / inject

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
window.emitter = emitter;
// Директивы
import directives from "@/directives";

import Notifications from '@kyvg/vue3-notification';

directives.forEach((directive) => {
  app.directive(directive.name, directive);
});

app
  .component("AppSvg", AppSvg)
  .component("app-title", AppTitle)
  .directive("tippy", TippyDirective)
  .use(Notifications)
  .use(createHead())
  .use(router)
  .use(VueAxios, axios)
  .use(vfmPlugin)
  .use(Vue3TouchEvents)
  .use(createPinia())
  .mount("#app");
