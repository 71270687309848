<!-- <FormRange :min="13" :max="69" :tooltips="true" /> -->

<template>
  <Slider
    v-if="min"
    v-model="valueRange"
    class="form-range"
    :max="max"
    :min="min"
    :tooltips="tooltips"
    :step="step"
  />
  <Slider
    v-else
    v-model="valueMax"
    class="form-range"
    :max="max"
    :tooltips="tooltips"
    :step="step"
  />
</template>

<script>
import Slider from "@vueform/slider";

export default {
  components: {
    Slider,
  },

  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    tooltips: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      valueRange: [this.min, this.max],
      valueMax: this.max,
    };
  },
};
</script>

<style lang="scss">
.form-range {
  $that: &;

  --slider-bg: #68717b;
  --slider-connect-bg: #f4691b;
  --slider-connect-bg-disabled: #9ca3af;
  --slider-height: 2px;
  --slider-vertical-height: 300px;
  --slider-radius: 9999px;

  --slider-handle-bg: #f4691b;
  --slider-handle-border: 0;
  --slider-handle-width: 20px;
  --slider-handle-height: 20px;
  --slider-handle-radius: 9999px;
  --slider-handle-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0);
  --slider-handle-shadow-active: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.42);
  --slider-handle-ring-width: 1px;
  --slider-handle-ring-color: #f4691b;

  --slider-tooltip-font-size: 0.875rem;
  --slider-tooltip-line-height: 1.25rem;
  --slider-tooltip-font-weight: 600;
  --slider-tooltip-min-width: 20px;
  --slider-tooltip-bg: #f4691b;
  --slider-tooltip-bg-disabled: #9ca3af;
  --slider-tooltip-color: #fff;
  --slider-tooltip-radius: 5px;
  --slider-tooltip-py: 2px;
  --slider-tooltip-px: 6px;
  --slider-tooltip-arrow-size: 5px;
  --slider-tooltip-distance: 3px;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>
