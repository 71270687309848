<template>
  <a
    :href="href"
    class="button-phone"
  >
    <app-svg
      id="phone"
      width="20"
      height="20"
    />

    <div class="button-phone__number">
      <slot />
    </div>
  </a>
</template>

<script>
export default {
  name: "ButtonPhone",

  props: {
    href: {
      type: [String, Array],
      default: "tel: #!",
    },
  },
};
</script>

<style lang="scss" scoped>
.button-phone {
  color: $white;

  display: flex;
  align-self: center;
  transition: text-shadow 0.4s 0.1s cubic-bezier(0.22, 0.61, 0.36, 1);

  svg {
    transition: filter 0.4s 0.1s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover {
    text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 50px rgba(255, 255, 255, 0.8), 0 0 35px rgba(255, 255, 255, 0.6),
      0 0 76px rgba(255, 255, 255, 0.4), 0 0 37px rgba(255, 255, 255, 0.5), 0 0 78px rgba(255, 255, 255, 0.4),
      0 0 39px rgba(255, 255, 255, 0.3), 0 0 80px rgba(255, 255, 255, 0.2), 0 0 35px rgba(255, 255, 255, 0.1);

    svg {
      filter: drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.9));
    }
  }

  &__number {
    padding-left: 8px;
    padding-top: 2px;

    @include desktop-l {
      display: none;
    }
  }
}
</style>
