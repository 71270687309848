<template>
  <div class="estate-actions">
    <div
      v-if="isNewEstate"
      class="estate-actions__new-estate"
    >
      <AppButton
        :is-small="true"
        :secondary="secondary"
        @click="isOpenRequestModal = true"
      >
        Подать заявку
      </AppButton>

      <teleport
        v-if="isOpenRequestModal"
        to="body"
      >
        <AppModal v-model="isOpenRequestModal">
          <ModalRequest
            :card-link="cardLink"
            @form-success-sent="formSuccessSent"
          />
        </AppModal>
      </teleport>
    </div>

    <div
      v-else
      class="estate-actions__estate"
    >
      <AppButton
        :secondary="true"
        :is-small="true"
        :style="buttonExchange ? 'flex: 0 0 48.5%' : ''"
        @click="isOpenRequestModal = true"
      >
        Заявка
      </AppButton>

      <teleport
        v-if="isOpenRequestModal"
        to="body"
      >
        <AppModal v-model="isOpenRequestModal">
          <ModalRequest
            :card-link="cardLink"
            @form-success-sent="formSuccessSent"
          />
        </AppModal>
      </teleport>

      <AppButton
        v-if="buttonExchange"
        v-tippy
        :is-small="true"
        :secondary="true"
        style="flex: 0 0 48.5%"
        @click="isOpenExchangeModal = true"
      >
        Обменять
      </AppButton>

      <teleport
        v-if="isOpenExchangeModal"
        to="body"
      >
        <AppModal v-model="isOpenExchangeModal">
          <ModalExchange
            :card-link="cardLink"
            @form-success-sent="formSuccessSent"
          />
        </AppModal>
      </teleport>

      <!--       <AppTooltip placement="bottom">
        Поможем одновременно продать свою и купить новую
      </AppTooltip> -->

      <AppButton
        v-tippy
        :is-small="true"
        :secondary="true"
        @click="isOpenFindSimilarModal = true"
      >
        Подобрать похожую
      </AppButton>

      <teleport
        v-if="isOpenFindSimilarModal"
        to="body"
      >
        <AppModal v-model="isOpenFindSimilarModal">
          <ModalFindSimilar
            :card-link="cardLink"
            @form-success-sent="formSuccessSent"
          />
        </AppModal>
      </teleport>

      <!-- <AppTooltip placement="bottom">
        Оставить заявку на подбор похожего объекта
      </AppTooltip> -->
    </div>
    <teleport
      v-if="isOpenPopupSuccessfully"
      to="body"
    >
      <PopupSuccessfully @popup-close="isOpenPopupSuccessfully = false" />
    </teleport>
  </div>
</template>

<script>
import AppButton from "./ui/AppButton.vue";
// import AppTooltip from "./ui/AppTooltip.vue";
import AppModal from "./ui/AppModal.vue";
import ModalRequest from "./ModalRequest.vue";
import ModalExchange from "./ModalExchange.vue";
import ModalFindSimilar from "./ModalFindSimilar.vue";
import PopupSuccessfully from "@/components/PopupSuccessfully.vue";

export default {
  name: "EstateActions",
  components: {
    AppButton,
    // AppTooltip,
    AppModal,
    ModalRequest,
    ModalExchange,
    ModalFindSimilar,
    PopupSuccessfully,
  },

  inject: ["cardLink"],

  props: {
    buttonExchange: {
      type: Boolean,
      default: true,
    },
    isNewEstate: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isOpenRequestModal: false,
      isOpenExchangeModal: false,
      isOpenFindSimilarModal: false,
      isOpenPopupSuccessfully: false,
    };
  },

  methods: {
    formSuccessSent() {
      this.isOpenPopupSuccessfully = true;
      this.isOpenRequestModal = false;
      this.isOpenExchangeModal = false;
      this.isOpenFindSimilarModal = false;

      setTimeout(() => {
        this.isOpenPopupSuccessfully = false;
      }, 4000);
    },
  },
};
</script>

<style lang="scss" scoped>
.estate-actions {
  &__estate {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
      flex: 1 1 100%;
      margin-top: 8px;
    }

    button:first-child {
      background-color: $color_primary;
      border-color: $color_primary;
      color: $white;

      @include hover {
        background-color: $color_primary-dark;
      }
    }

    // button:not(:last-child) {
    //   flex: 0 0 48.5%;
    // }

    button:last-child {
      @include laptop {
        display: none;

        @media (hover: hover) {
          display: flex;
        }
      }
    }
  }

  &__new-estate {
    display: block;

    button {
      width: 100%;
    }
  }
}
</style>
