<!--   <ButtonLocation :withText="true" /> -->

<template>
  <div
    class="button-location"
    :class="classes"
  >
    <app-svg
      id="location"
      class="button-location__icon"
      width="20"
      height="24"
    />
    <div class="button-location__text">
      На карте
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "ButtonLocation",

  props: {
    withText: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_with-text": props.withText,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
.button-location {
  $that: &;

  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    color: $color_dark-40;
    transition: color 0.25s ease;

    ._with-text & {
      color: $color_orange-50;
    }

    #{$that}:hover & {
      color: $color_orange-50;
    }

    #{$that}._with-text:hover & {
      animation: bounce 1s ease-out 1 normal running 0s forwards;
    }

    #{$that}:active & {
      color: $color_orange-70;
    }

    @keyframes bounce {
      0% {
        transform: scale(1, 1) translateY(0);
      }

      10% {
        transform: scale(1.1, 0.9) translateY(0);
      }

      30% {
        transform: scale(0.9, 1.1) translateY(-8px);
      }

      50% {
        transform: scale(1.05, 0.95) translateY(0);
      }

      57% {
        transform: scale(1, 1) translateY(-2px);
      }

      64% {
        transform: scale(1, 1) translateY(0);
      }

      100% {
        transform: scale(1, 1) translateY(0);
      }
    }
  }

  &__text {
    display: none;

    ._with-text & {
      display: block;
      padding-left: 6px;
      color: $color_orange-50;
    }
  }
}
</style>
