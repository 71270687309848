<template>
  <section class="services">
    <div
      v-if="servicesAll"
      class="services__container"
    >
      <app-title
        class="services__title"
        :no-icon="true"
      >
        {{ title }}
      </app-title>
      <ServicesList :services-list="servicesList" />
    </div>
    <div
      v-else
      class="services__container"
    >
      <ul class="services__list">
        <li class="services__list-item">
          <app-title class="services__title">
            Услуги
          </app-title>
          <div
            v-clamp="9"
            class="services__description"
          >
            {{ description }}
          </div>
        </li>
        <li
          v-for="service in servicesList"
          :key="service.id"
          class="services__list-item"
        >
          <ServicesCard :service="service" />
        </li>
      </ul>
      <div class="services__link">
        <app-button
          to="/services"
          :secondary="true"
        >
          Все услуги
        </app-button>
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from "@/components/ui/AppButton.vue";
import ServicesCard from "@/components/ServicesCard.vue";
import ServicesList from "@/components/ServicesList.vue";

export default {
  name: "ServicesSection",
  components: {
    AppButton,
    ServicesCard,
    ServicesList,
  },

  props: {
    servicesAll: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      description: null,
      servicesList: [],
    };
  },

  mounted() {
    this.getSevices();
  },

  methods: {
    getSevices() {
      this.axios
        .get("/api/services", {
          params: {
            limit: this.servicesAll ? "" : 7,
          },
        })
        .then(({ data }) => {
          this.description = data.description;
          this.servicesList = data.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  $this: &;

  padding: 64px 0px;

  @include tablet {
    padding: 50px 0;
  }

  &__container {
    @extend %container;
  }

  &__title {
    @include laptop {
      font-size: 26px;
      line-height: 37px;
      padding-bottom: 20px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @include laptop {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    @include tablet {
      -webkit-line-clamp: 6 !important;
    }

    @include phone {
      display: block !important;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__list {
    display: grid;
    grid-template: 1fr / repeat(4, 1fr);
    grid-gap: 32px;
    margin-bottom: 64px;

    @include media($max-width: 1920px) {
      grid-gap: calc(32 / 1920 * 100vw);
    }

    @include laptop {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      margin-bottom: 30px;
    }

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: calc(20 / 1023 * 100vw);
    }

    @include phone {
      display: block;
    }
  }

  &__list-item {
    &:first-child {
      @include laptop {
        grid-row: 1 / 3;
      }

      @include tablet {
        grid-row: auto;
      }

      @include phone {
        margin-bottom: 20px;
      }
    }

    &:nth-child(n + 9) {
      display: none;
    }
  }

  &__link {
    @include flex-center;
  }
}
</style>
