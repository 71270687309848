<template>
  <VueNextSelect
    :model-value="query.currentSubLocal"
    multiple
    class="filter-area"
    :class="{
      _open: multiSelectOpen,
      'select-all': query.currentSubLocal.length === query.currentSubLocalRange.length - 1,
    }"
    :max-height="396"
    :options="query.currentSubLocalRange"
    @selected="onSelect"
    @removed="onRemove"
    @click="onMultiSelect(this)"
    @closed="onMultiSelectClosed"
  >
    <template #label="{ selected }">
      <template v-if="selected.length">
        <span>{{ selected[0] }}</span>
        <span>{{ `${selected.length > 1 ? `+ ${selected.length - 1}` : ""}` }}</span>
        <span class="select-icon" />
      </template>
      <template v-else-if="query.currentSubLocal.length">
        <span>{{ query.currentSubLocal[0] }}</span>
        <span>{{ `${query.currentSubLocal.length > 1 ? `+ ${query.currentSubLocal.length - 1}` : ""}` }}</span>
        <span class="select-icon" />
      </template>
      <template v-else> Выберите район <span class="select-icon" /> </template>
    </template>
    <template #dropdown-item="{ option }">
      <span :class="{ _active: query.currentSubLocal.includes(option) }">
        {{ option }}
      </span>
    </template>
  </VueNextSelect>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { setParamsInUrl } from "@/plugins/helpers.js";

import VueNextSelect from "vue-next-select";

export default {
  name: "FilterArea",
  components: { VueNextSelect },
  data() {
    return {
      multiSelectOpen: false,
    };
  },
  computed: {
    ...mapState(useFilterStore, ["currentSubLocalRange", "query", "currentSubLocalRangeWithoutSelected"]),
  },
  methods: {
    ...mapActions(useFilterStore, ["setCurrentSubLocal", "deleteCurrentSubLocal", "returnRequiredParams"]),

    onMultiSelectClosed() {
      this.multiSelectOpen = false;
    },

    onMultiSelect(e) {
      if (this.query.subLocalitiesAreDisabled) return;

      if (
        event.target.parentElement.parentElement.className === "vue-select-header" ||
        event.target.parentElement.className === "vue-select-header" ||
        event.target.className === "vue-select-header"
      ) {
        this.multiSelectOpen = this.multiSelectOpen ? false : true;
      }
    },

    async onSelect(subLocal) {
      const self = this;

      if (
        this.query.currentSubLocal.find((el) => el.toLowerCase().indexOf("все") != -1) &&
        this.query.currentSubLocal.length === this.query.currentSubLocalRange.length
      )
        return this.onRemove(subLocal);

      if (subLocal.toLowerCase().indexOf("все") != -1) {
        const array = this.query.currentSubLocalRange.filter(
          (x) => x != self.query.currentSubLocal.find((el) => el == x)
        );

        await array.forEach((el) => {
          this.setCurrentSubLocal(el);
        });

        await setParamsInUrl(this, this.$route.query, {
          subLocality: this.query.currentSubLocal,
        });
        this.emitter.emit("load-with-params");

        return;
      }

      this.setCurrentSubLocal(subLocal);
      await setParamsInUrl(this, this.$route.query, {
        subLocality: this.query.currentSubLocal,
      });
      this.emitter.emit("load-with-params");
    },
    async onRemove(subLocal) {
      if (
        this.query.currentSubLocal.find((el) => el.toLowerCase().indexOf("все") != -1) &&
        subLocal.toLowerCase().indexOf("все") != 0
      ) {
        this.deleteCurrentSubLocal(subLocal);
        this.deleteCurrentSubLocal("Все");

        await setParamsInUrl(this, this.$route.query, {
          subLocality: this.query.currentSubLocal,
        });
        this.emitter.emit("load-with-params");

        return;
      }

      if (subLocal.toLowerCase().indexOf("все") != -1) {
        this.query.currentSubLocal.forEach((el) => {
          this.deleteCurrentSubLocal(el);
        });

        await setParamsInUrl(this, this.$route.query, {
          subLocality: this.query.currentSubLocal,
        });
        this.emitter.emit("load-with-params");

        return;
      }

      this.deleteCurrentSubLocal(subLocal);
      await setParamsInUrl(this, this.$route.query, {
        subLocality: this.query.currentSubLocal,
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style lang="scss" scoped>
.select-icon {
  height: 35px;
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;

  position: absolute;
  width: 40px;
  right: -7px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;

  &::before {
    content: "";
    margin: 0;
    border: 0;
    width: 1px;
    height: 6px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-55deg) translate(-50%, -50%);
  }

  &::after {
    content: "";
    border: 0;
    width: 1px;
    height: 6px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(55deg) translate(-50%, -50%);
  }
}
</style>

<style lang="scss">
._select-map-filter {
  padding: 0 !important;
  margin: 0 !important;

  margin-top: 10px !important;
  margin-bottom: 20px !important;

  & .vue-input {
    padding-left: 13px !important;
  }
}

.vue-select {
  position: relative;
  min-width: 170px;
  max-width: 190px;
  cursor: pointer;

  @include phone {
    padding: 0 !important;
    margin: 12px 0 20px !important;
  }

  & .vue-input {
    background: transparent;
    border: none;
    padding-left: 5px;
    color: $white !important;
    font-size: 16px !important;
    white-space: nowrap;

    padding: 8px 47px 0 13px;

    position: relative;
    display: block;
    min-height: 40px;
    line-height: 20px;
    width: 100%;
    vertical-align: top;

    overflow: hidden;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include phone {
      padding-left: 8px;
    }
    & span {
      &:nth-child(1) {
        max-width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
      }

      &:last-child {
        margin: 0 !important;
      }
    }
  }

  &._open {
    z-index: 99999;
    & .vue-dropdown {
      opacity: 1 !important;
      visibility: visible !important;
    }

    & .vue-select-header .select-icon {
      transform: rotate(180deg) !important;
    }
  }

  &[data-is-focusing="false"] {
    & .vue-select-header .select-icon {
      transform: unset;
    }
  }

  &[data-disabled="true"] {
    opacity: 0.3;
  }

  &.select-all {
    & .vue-dropdown .vue-dropdown-item {
      background: #40464c !important;
    }
  }

  & .vue-dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    overflow-y: scroll;

    min-width: 258px;
    border: none;
    border-radius: 0;
    background-color: #232930;
    padding: 22px;
    box-shadow: 0px -5px 20px rgb(0 0 0 / 20%);
    opacity: 0;
    visibility: hidden;

    transition: all 0.15s ease;

    &[data-is-focusing="false"] {
      opacity: 0 !important;
      visibility: hidden !important;
    }

    &-item {
      & span {
        color: #fff;

        display: block;
        padding: 12px;
        min-height: 40px;
        line-height: 16px;
        text-decoration: none;
        text-transform: none;
        vertical-align: middle;
        position: relative;
        cursor: pointer;
        white-space: nowrap;

        overflow: hidden;
        text-overflow: ellipsis;

        &._active {
          background: #40464c !important;
        }
      }

      &.selected {
        background: #40464c;
      }

      @include hover {
        background: #40464c;
      }
    }
  }
}
</style>
