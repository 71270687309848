<template>
  <header class="header">
    <ul class="header__inner">
      <li class="header__burger">
        <BurgerMenu :burger-list="burgerList" />
      </li>
      <li class="header__logo">
        <AppLogo @click="clearFilters('/')" />
      </li>
      <li class="header__nav">
        <TheNav :nav-list="navList" />
      </li>
      <li class="header__buttons">
        <ButtonPhone
          v-if="phone"
          :href="['tel:' + phone]"
        >
          <span v-phone="phone" />
        </ButtonPhone>
        <ButtonRequest
          :in-favorites="favoritesCount"
          @call-request="isOpenCallRequestModal = true"
        >
          Заявка на звонок
        </ButtonRequest>

        <div class="user-group">
          <ButtonUser @click="authOpened = !authOpened" />
          <UserMenu
            v-if="isAuth"
          />
        </div>

        <teleport to="body">
          <AppModal v-model="isOpenCallRequestModal">
            <ModalCallRequest @form-success-sent="formSuccessSent" />
          </AppModal>
        </teleport>
        <teleport
          v-if="isOpenPopupSuccessfully"
          to="body"
        >
          <PopupSuccessfully @popup-close="isOpenPopupSuccessfully = false" />
        </teleport>
      </li>
    </ul>

    <modal-auth
      v-if="!isAuth && authOpened"
      @success="closeModal"
    /> <!-- !isAuth && -->
  </header>
</template>

<script>
import TheNav from "./TheNav.vue";
import AppLogo from "./ui/AppLogo.vue";
import ButtonPhone from "./ui/ButtonPhone.vue";
import ButtonRequest from "./ui/ButtonRequest.vue";
import ButtonUser from "./ui/ButtonUser.vue";
import BurgerMenu from "./BurgerMenu.vue";
import AppModal from "./ui/AppModal.vue";
import ModalCallRequest from "./ModalCallRequest.vue";
import PopupSuccessfully from "@/components/PopupSuccessfully.vue";
import ModalAuth from '@/components/ModalAuth.vue';
import UserMenu from '@/components/UserMenu.vue';

import router from "@/router/index.js";
import { useFavoritesStore } from "@/store/store.js";
import { computed, ref } from "vue";
import { mapActions } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { useUserStore } from '@/stores/user';

export default {
  components: {
    TheNav,
    AppLogo,
    ButtonPhone,
    ButtonRequest,
    ButtonUser,
    BurgerMenu,
    AppModal,
    ModalCallRequest,
    PopupSuccessfully,
    ModalAuth,
    UserMenu,
  },

  props: {
    navList: {
      type: Array,
      default: () => []
    },
    burgerList: {
      type: Array,
      default: () => []
    },
    servicesList: {
      type: Array,
      default: () => []
    },
    phone: {
      type: String,
      default: null
    }
  },

  setup() {
    const store = useFavoritesStore();
    const userStore = useUserStore();
    const favoritesCount = computed(() => store.getCount);

    const isAuth = computed(() => userStore.auth);

    const authOpened = ref(false);

    const closeModal = () => {
      authOpened.value = false;
    }

    return {
      favoritesCount,
      isAuth,
      authOpened,
      closeModal,
    };
  },

  data() {
    return {
      isOpenCallRequestModal: false,
      isOpenPopupSuccessfully: false
    };
  },

  methods: {
    ...mapActions(useFilterStore, ["setCloseMaps"]),
    async clearFilters(path) {
      await router.push({ path: path, query: {} });
      this.setCloseMaps();
      this.emitter.emit("clear-filter", "FROM_MAIN");
    },
    formSuccessSent() {
      this.isOpenCallRequestModal = false;
      this.isOpenPopupSuccessfully = true;

      setTimeout(() => {
        this.isOpenPopupSuccessfully = false;
      }, 4000);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  $that: &;

  display: flex;
  align-items: center;

  &__inner {
    @extend %container;

    display: flex;
    align-items: center;
    height: 89px;
    border-bottom: 1px solid rgba(150, 155, 160, 0.5);
    transition: border-color 0.25s ease-in-out;

    .page_scrolled & {
      transition: border-color 0.25s ease-in-out;
      border-color: transparent;
    }

    @include tablet {
      border: 0;
    }

    @include phone {
      height: 66px;
    }
  }

  &__burger {
    flex: none;
    margin-right: calc(32 / 1920 * 100vw);

    @media (min-width: 1920px) {
      margin-right: 32px;
    }

    @include laptop {
      margin-right: 18px;
    }

    @include tablet {
      margin: 0;
    }
  }

  &__logo {
  }

  &__nav {
    margin: 0 calc(56 / 1920 * 100vw);

    @media (min-width: 1920px) {
      margin: 0 44px 0 54px;
    }

    @include tablet {
      margin: 0;
      display: none;
    }
  }

  &__buttons {
    display: flex;
    margin-left: auto;

    .button-phone {
      margin-right: 24px;

      @include laptop {
        margin-right: 16px;
      }
    }
  }
}

.user-group {
  &:hover {
    :deep .user-menu {
      display: block!important;
    }
  }
}
</style>
