<template>
  <div class="footer-nav">
    <AppAccordion
      v-for="(accordion, index) in navFooterList"
      :key="index"
      :from-laptop="true"
      class="footer-nav__accordion"
    >
      <template #header>
        {{ accordion.title }}
      </template>
      <ul class="footer-nav__accordion-list">
        <li
          v-for="navItem in accordion.links"
          :key="navItem.name"
          class="footer-nav__accordion-item"
        >
          <router-link
            :to="navItem.link"
            class="footer-nav__accordion-go"
          >
            {{ navItem.name }}
          </router-link>
        </li>
      </ul>
    </AppAccordion>
  </div>
</template>

<script>
import AppAccordion from "./ui/AppAccordion.vue";

export default {
  name: "FooterNav",
  components: { AppAccordion },

  props: {
    navFooterList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-nav {
  $that: &;

  display: flex;

  @include laptop {
    display: block;
  }

  &__accordion {
    position: relative;
    flex: 0 0 25%;
    padding-right: 28px;

    @include laptop {
      padding-right: 0;
    }

    @include tablet {
      max-width: 284px;
    }

    @include phone {
      max-width: 100%;

      &::after {
        content: "";
        width: 100vw;
        height: 1px;
        background-color: $color_dark-70;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate3D(-50%, 0, 0);
      }
    }
  }

  &__accordion-list {
    padding-top: 20px;

    @include laptop {
      padding-top: 10px;
    }
  }

  &__accordion-item {
    font-size: 14px;
    line-height: 18px;
    max-width: 160px;

    & + & {
      padding-top: 10px;

      @include laptop {
        padding-top: 16px;
      }
    }

    @include laptop {
      padding-left: 14px;
    }
  }

  &__accordion-go {
  }
}
</style>
