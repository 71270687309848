<template>
  <div class="popup-successfully">
    <div class="popup-successfully__head">
      <img
        :data-src="require('@/assets/images/content/about-us/cover.jpeg')"
        alt=""
        class="lazy"
      >
      <app-svg
        id="telegram-check"
        width="73.5"
        height="66"
      />
    </div>
    <div class="popup-successfully__body">
      <div
        v-if="title"
        class="popup-successfully__title"
      >
        {{ title }}
      </div>
      <div
        v-if="text"
        class="popup-successfully__text"
      >
        {{ text }}<br>
        {{ textSecond }}
      </div>
    </div>
    <button
      class="popup-successfully__close"
      @click="$emit('popup-close')"
    >
      <app-svg id="close" />
    </button>
  </div>
</template>

<script>
export default {
  name: "PopupSuccessfully",

  props: {
    title: {
      type: String,
      default: "Заявка успешно отправлена!",
    },
    text: {
      type: String,
      default: "Наш менеджер скоро с Вами свяжется.",
    },
    textSecond: {
      type: String,
      default: '',
    }
  },
};
</script>

<style lang="scss" scoped>
.popup-successfully {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 537px;
  z-index: 1000;
  box-shadow: 0px 0px 25px 0px rgba(17, 26, 34, 0.2);
  background-color: $white;

  &__head {
    position: relative;
    padding: 57px 0 31px;

    @include flex-center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(35, 41, 48, 0.6), rgba(35, 41, 48, 0.6));
      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;

      @include lazy-loaded;
      @include object-fit(cover, top);
    }

    svg {
      position: relative;
      z-index: 2;
    }
  }

  &__body {
    padding: 26px 50px 74px;

    @include phone {
      padding: 26px 16px 45px;
    }

    div:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    @include phone {
      font-size: 21px;
      line-height: 32px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @include phone {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 28px;
    height: 28px;
    padding: 7px;
    color: $white;
    z-index: 3;
    transition: transform 0.25s ease-out, color 0.25s ease-out;
    cursor: pointer;

    @include flex-center;

    @include hover {
      transform: rotate(180deg);
      color: $color_primary;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
