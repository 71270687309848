<!-- <FormInput
      name="email"
      type="email"
      label="Ваш email"
      v-model="form.email"
    /> -->

<template>
  <div
    ref="formInput"
    class="form-input"
    :class="classes"
  >
    <div class="form-input__inner">
      <label
        v-if="label"
        class="form-input__label"
        :class="{
          '_fixed-label': isFixedLabel,
        }"
        :for="name"
      >{{ label }}</label>
      <input
        v-if="type === 'tel'"
        v-bind="$attrs"
        :id="name"
        v-model="inputValue"
        v-mask="'+7 (###)-###-##-##'"
        type="tel"
        :name="name"
        class="form-input__field"
        @focus="handleFocus"
        @blur="handleBlur"
      >
      <input
        v-else-if="type === 'password'"
        v-bind="$attrs"
        :id="name"
        v-model="inputValue"
        type="password"
        autocomplete
        :name="name"
        class="form-input__field"
        @focus="handleFocus"
        @blur="handleBlur"
      >

      <input
        v-else-if="type === 'email'"
        v-bind="$attrs"
        :id="name"
        v-model="inputValue"
        type="email"
        :name="name"
        class="form-input__field"
        @focus="handleFocus"
        @blur="handleBlur"
      >
      <input
        v-else
        v-bind="$attrs"
        :id="name"
        v-model="inputValue"
        :name="name"
        class="form-input__field"
        @focus="handleFocus"
        @blur="handleBlur"
      >
    </div>
    <transition
      name="error"
      appear
    >
      <div class="form-input__error">
        <span v-if="type == 'text'"> Это поле нам нужно </span>
        <span v-else-if="type == 'tel'">Телефон нам, все-таки, понадобиться</span>
        <span v-else-if="type == 'password'">Без пароля никак</span>
        <span v-else-if="type == 'email'">Вы точно указали свою почту?</span>
        <span v-else> Это поле нам нужно </span>
      </div>
    </transition>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { computed, reactive } from "vue";

export default {
  name: "FormInput",
  directives: { mask },

  props: {
    name: {
      type: String,
      default: "inputName",
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    type: {
      type: [String, Boolean],
      default: false,
    },
    isDark: {
      type: [String, Boolean],
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_is-dark": props.isDark,
      })),
    };
  },

  data() {
    return {
      inputValue: "",
      isFixedLabel: false,
    };
  },

  mounted() {
    if (this.value) {
      this.inputValue = this.value;
      this.isFixedLabel = true;
    }
  },

  methods: {
    handleFocus() {
      this.isFixedLabel = true;

      this.$refs.formInput.classList.remove("_error");
    },

    handleBlur(event) {
      const inputValue = event.currentTarget.value;

      if (!inputValue) {
        this.isFixedLabel = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  $that: &;

  position: relative;

  &__inner {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $color_dark-40;
      transition: background-color 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1);

      ._is-dark & {
        background-color: transparent;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: -100%;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #f36421;
      transition: transform 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1),
        background-color 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1);

      #{$that}:focus-within & {
        transform: translateX(100%);
      }

      ._is-dark & {
        background-color: $color-grey-15;
      }
    }

    ._error & {
      &::before,
      &::after {
        background-color: $color_primary;
      }
    }
  }

  &__label {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    padding-left: 16px;
    color: $color_dark-50;
    pointer-events: none;
    will-change: transform;
    transition: transform 0.3s ease, background-color 0.3s ease;
    transform-origin: top left;

    ._is-dark & {
      color: $color_dark-40;
    }

    &._fixed-label {
      transform: scale(0.75) translateY(-18px);
    }
  }

  &__field {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    transition: color 0.3s ease;
    background-color: $color-grey-2;

    ._is-dark & {
      background-color: $color_dark-70;
      color: $white;
    }

    #{$that}._error & {
      color: $color_primary;
    }
  }

  &__error {
    color: $color_primary;
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    opacity: 0;
    visibility: hidden;

    ._error & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.error-enter-active {
  transition: opacity 0.5s, transform 0.5s;
}

.error-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}

.error-enter {
  opacity: 0;
  transform: translate(8px, 0);
}

.error-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translate(0, 2px);
}
</style>
