<template>
  <Transition name="translate">
    <slot />
  </Transition>
</template>
<script>
export default {
  name: "TransitionTranslateLeft",
};
</script>

<style>
.translate-enter-active {
  transition: opacity 0.1s ease, transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
}

.translate-leave-active {
  transition: opacity 0.1s 0.5s ease, transform 0.6s cubic-bezier(0, 0.55, 0.45, 1) !important;
}

.translate-enter-from,
.translate-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
