<template>
  <div
    ref="filterPrice"
    class="filter-price"
  >
    <div class="filter-price__name">
      Цена
    </div>
    <div class="filter-price__value-list">
      <span
        ref="priceFrom"
        :class="{ '_handele-is-on-min': currentValueIsMin }"
      >{{ minPriceValue }} &#8381; </span>
      &ensp; &ensp; - &ensp; &ensp;
      <span ref="priceTo">{{ maxPriceValue }} &#8381; </span>
    </div>
    <FormRange
      ref="formRange"
      v-model="priceRange"
      :min="minPriceRange"
      :max="maxPriceRange"
      @update="onChange"
    />
  </div>
</template>

<script>
import FormRange from "../form/FormRange.vue";
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterPrice",
  components: { FormRange },
  emits: ["price-toggle"],

  data() {
    return {
      currentValueIsMin: true,
    };
  },
  computed: {
    ...mapState(useFilterStore, ["minPriceRange", "maxPriceRange", "minPriceValue", "maxPriceValue"]),
    priceRange: {
      async set(values) {
        await this.setMinMaxPriceValues(values);

        await setParamsInUrl(this, this.$route.query, {
          price: {
            min: values[0],
            max: values[1],
          },
        });
        this.emitter.emit("load-with-params");
      },
      get() {
        return [this.minPriceValue, this.maxPriceValue];
      },
    },
  },
  mounted() {
    this.formatCurrency();
    this.checkValueNow();
  },

  updated() {
    this.formatCurrency();
  },

  methods: {
    ...mapActions(useFilterStore, ["setMinMaxPriceValues", "setPriceInQuery", "returnRequiredParams"]),

    onChange() {
      this.formatCurrency();
      this.checkValueNow();
    },

    formatCurrency() {
      const priceFrom = this.$refs.priceFrom.textContent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      const priceTo = this.$refs.priceTo.textContent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      this.$refs.priceFrom.innerHTML = priceFrom;
      this.$refs.priceTo.innerHTML = priceTo;
    },

    checkValueNow() {
      const priceNow = this.$refs.filterPrice.querySelector("[aria-valuenow]");

      if (Number(priceNow.getAttribute("aria-valuenow")) === this.minPrice) {
        this.currentValueIsMin = true;
      } else {
        this.currentValueIsMin = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-price {
  $that: &;

  min-width: 282px;
  padding: 0 18px 0 24px;

  &__name {
    font-size: 14px;
    line-height: 18px;
    color: $color-dark-40;
    padding-bottom: 4px;
    transform: translate3d(-8px, 0, 0);
  }

  &__value-list {
    color: $white;
    padding-bottom: 16px;
    transform: translate3d(-8px, 0, 0);

    span._handele-is-on-min {
      color: $color_dark-40;
    }
  }
}
</style>
