const state = () => ({
  query: {
    sortBy: null,
    orderBy: null,
    page: 1,
    type: 1,
    isZhk: 1,

    currentEstateType: { name: "Вторичная недвижимость", type: "resaleEstate" },

    estateTypeArray: [
      { name: "Вторичная недвижимость", type: "resaleEstate", checked: true },
      { name: "Новостройки", type: "resaleEstate", checked: false },
      { name: "Загородная", type: "countryEstate", checked: false },
      { name: "Коммерческая", type: "commerseEstate", checked: false },
    ],

    category: [7],
    currentCategory: { id: 7, title: "Квартира", group: "resaleEstate", checked: false },
    categoriesList: [
      { id: 7, title: "Квартира", group: "resaleEstate", checked: false },
      {
        id: 8,
        title: "Комната",
        group: "resaleEstate",
        checked: false,
      },
    ],

    currentRoomListRange: [
      { name: "Студия", checked: false, disabled: false, value: 0 },
      { name: "1", checked: false, disabled: false, value: 1 },
      { name: "2", checked: false, disabled: false, value: 2 },
      { name: "3", checked: false, disabled: false, value: 3 },
      { name: "4+", checked: false, disabled: false, value: 4 },
    ],
    currentRoomListValues: [0, 1, 2, 3, 4],

    minPriceRange: 3400000,
    maxPriceRange: 37950000,
    minPriceValue: 3400000,
    maxPriceValue: 37950000,

    minFloorValue: 0,
    maxFloorValue: 999,
    minFloorRange: 0,
    maxFloorRange: 999,

    minSquareValue: 0,
    maxSquareValue: 9999,
    minSquareRange: 0,
    maxSquareRange: 9998,

    minKitchenValue: 0,
    maxKitchenValue: 999,
    minKitchenRange: 0,
    maxKitchenRange: 999,

    currentCity: [],
    currentCitiesRange: ["Ленинградская область", "Москва", "Санкт-Петербург"],
    currentSubLocal: [],
    currentSubLocalRange: [
      "Василеостровский",
      "Всеволожский",
      "Выборгский",
      "Калининский",
      "Колпинский",
      "Красногвардейский",
      "Красносельский",
      "Кронштадтский",
      "Курортный",
      "Московский",
      "Невский",
      "Петродворцовый",
      "Приморский",
      "Пушкинский",
      "Сосенское",
      "Фрунзенский",
      "Центральный",
    ],
    subLocalitiesAreDisabled: true,

    notLastFloor: 0,
    lastFloor: 0,
    notFirstFloor: 0,

    newFlat: 0,

    /* ДЛЯ НОВОСТРОЕК */
    offerDeadlines: [],
    currentFurnish: 3,
    currentQuartal: null,

    currentDeadlines: [],

    furnishList: [
      {
        value: 3,
        title: "Все варианты",
        checked: true,
      },
      {
        value: 0,
        title: "Без отделки",
        checked: false,
      },
      {
        value: 1,
        title: "Чистовая",
        checked: false,
      },
    ],
    currentZhk: [],
    offerZhkList: [],

    minFloor: 0,
    maxFloor: 999,

    minKitchen: 0,
    maxKitchen: 999,

    minPrice: null,
    maxPrice: null,

    /* ДЛЯ ЗАГОРОДНОЙ */
    minAreaRange: 0,
    maxAreaRange: 9999,
    minAreaValue: 0,
    maxAreaValue: 9999,

    minRoadDistanceValue: 0,
    maxRoadDistanceValue: 9999,
    minRoadDistanceRange: 0,
    maxRoadDistanceRange: 9999,

    isSNT: 0,
    isDNP: 0,
    isIZHS: 0,

    purposes: [],
    purposesParams: [],

    showMap: false,
    showMobileMap: false,

    isFilterBuy: true,
    showAllFilters: false,
    offerCategories: [],
    openMobileMap: false,
    filterMobileMenuIsOpen: false,

    mainCategories: [],

    mainCategoriesisSet: false,

    selectedZhkItems: [],

    options: {
      filtersList: {
        offerTypes: ["Аренда", "Продажа"],
        offerRooms: ["Студия", "1", "2", "3", "4+"],
        offerCities: ["Ленинградская область", "Москва", "Санкт-Петербург"],
        offerSubLocalities: [
          "Василеостровский",
          "Всеволожский",
          "Выборгский",
          "Калининский",
          "Колпинский",
          "Красногвардейский",
          "Красносельский",
          "Кронштадтский",
          "Курортный",
          "Московский",
          "Невский",
          "Петродворцовый",
          "Приморский",
          "Пушкинский",
          "Сосенское",
          "Фрунзенский",
          "Центральный",
        ],
        offerCategories: {
          main: [
            { id: 1, title: "Дача", group: "countryEstate" },
            { id: 3, title: "Дом", group: "countryEstate" },
            { id: 5, title: "Участок", group: "countryEstate" },
            { id: 7, title: "Квартира", group: "resaleEstate" },
            { id: 8, title: "Комната", group: "resaleEstate" },
            { id: 12, title: "Коммерческая", group: "commerseEstate" },
          ],
          countryEstate: [
            { id: 1, title: "Дача", group: "countryEstate" },
            { id: 3, title: "Дом", group: "countryEstate" },
            { id: 5, title: "Участок", group: "countryEstate" },
          ],
          resaleEstate: [
            { id: 7, title: "Квартира", group: "resaleEstate" },
            { id: 8, title: "Комната", group: "resaleEstate" },
          ],
          commerseEstate: [{ id: 12, title: "Коммерческая", group: "commerseEstate" }],
        },
        offerPurposes: ["Свободного назначения", "Бытовые услуги", "Торговое", "Общепит", "Салон красоты"],
        offerLotTypes: [
          { id: 1, title: "ДНП" },
          { id: 3, title: "ИЖС" },
          { id: 5, title: "Садоводство" },
        ],
        offerDeadlines: [{ built_year: 2020, ready_quarter: 3 }],
        offerFilters: {
          resaleEstate: [
            {
              minPrice: 15000,
              maxPrice: 37950000,
              minArea: 18.06,
              maxArea: 131.4,
              minKitchenArea: 0,
              maxKitchenArea: 30.77,
              minRingRoadDistance: 0,
              maxRingRoadDistance: 25,
              minFloor: 1,
              maxFloor: 27,
              newFlat: 1,
            },
          ],
          newEstate: [
            {
              minPrice: 4047210,
              maxPrice: 4047210,
              minArea: 24,
              maxArea: 24,
              minKitchenArea: 0,
              maxKitchenArea: 0,
              minRingRoadDistance: 0,
              maxRingRoadDistance: 0,
              minFloor: 6,
              maxFloor: 6,
            },
          ],
          countryEstate: [
            {
              minPrice: 6000000,
              maxPrice: 19999000,
              minArea: 0,
              maxArea: 255,
              minKitchenArea: 0,
              maxKitchenArea: 40,
              minLotArea: 4,
              maxLotArea: 20.1,
              minRingRoadDistance: 0,
              maxRingRoadDistance: 15,
              minFloor: 0,
              maxFloor: 0,
            },
          ],
          commerseEstate: [
            {
              minPrice: 100000,
              maxPrice: 13200000,
              minArea: 41.5,
              maxArea: 280.9,
              minKitchenArea: 0,
              maxKitchenArea: 0,
              minRingRoadDistance: 0,
              maxRingRoadDistance: 6,
              minFloor: 0,
              maxFloor: 1,
            },
          ],
        },
      },
    },
    route: null,
  },
  scrollY: 0,
  listScrollY: 0,
  backToTheList: false,
  pointZoom: 16,
  point: [60, 40],
  backToTheMap: false,
  isLoaded: false,
  pageShowMore: false,
  currentCardId: null,
});

export default state;
