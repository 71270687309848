<template>
  <button
    class="button-request"
    :class="classes"
  >
    <div
      class="button-request__request-button"
      @click="$emit('call-request')"
    >
      <span>
        <slot />
      </span>
    </div>
    <router-link to="/favorites">
      <div
        v-show="inFavorites"
        class="button-request__favorite-button"
      >
        <app-svg
          id="heart"
          width="24"
          height="22"
          @click="likeClick"
        />
        <sup class="button-request__total">{{ inFavorites }}</sup>
      </div>
    </router-link>
  </button>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "ButtonRequest",
  props: {
    inFavorites: {
      type: Number,
      default: null,
    },
  },

  emits: ["call-request"],

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_has-favorites": props.inFavorites,
      })),
    };
  },
  methods: {
    likeClick() {
      this.$emit("to-favorites");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-request {
  $that: &;

  display: flex;

  &:hover {
  }

  &:active {
  }

  &__request-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 20px 12px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    transition: border-color 0.8s 0.25s cubic-bezier(0.25, 1, 0.3, 1);
    color: white;
    border: 1px solid $color_dark-40;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-width: 162px;

    &::before {
      content: "";
      @include absolute-fill;
      background-color: rgba(255, 255, 255, 0.3);
      z-index: 2;
      clip-path: circle(0% at bottom left);
      transition: clip-path 0.8s 0.1s cubic-bezier(0.25, 1, 0.3, 1);

      @keyframes circle-in-top-left {
        from {
          clip-path: circle(0%);
        }

        to {
          clip-path: circle(150% at bottom left);
        }
      }
    }

    span {
      position: relative;
      z-index: 2;
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.3);

      &::before {
        clip-path: circle(150% at top left);
      }
    }

    @include laptop {
      display: none;
    }
  }

  &__favorite-button {
    @include laptop {
      border: 0;
      padding: 0;
      color: $white;
    }

    @include flex-center;

    padding-left: 16px;
    padding-right: 8px;
    position: relative;
    min-width: 58px;
    height: 56px;
    color: $color_dark-40;
    border: 1px solid $color_dark-40;
    border-left: 0;
    transition: color 0.25s ease-out;

    svg {
      flex: none;
    }

    &:hover {
      color: $color_primary;

      svg {
        animation: heartbeat 1s ease 3 normal running 0s forwards;
      }
    }
  }

  &__total {
    flex: none;
    color: $white;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    transform: translate3d(1px, -6px, 0);
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1.1);
    }
  }
}
</style>
