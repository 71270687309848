<template>
  <ul class="news-list">
    <li
      v-for="(newsItem, index) in newsList"
      :key="index"
      class="news-list__item"
      :class="{ 'news-list__item--main': index == 0 }"
    >
      <NewsCard
        v-if="index == 0"
        :news="newsItem"
        :is-main="true"
        :card-link="cardLink"
      />
      <NewsCard
        v-else
        :news="newsItem"
        :card-link="cardLink"
      />
    </li>
  </ul>
</template>

<script>
import NewsCard from "@/components/NewsCard.vue";

export default {
  name: "NewsList",
  components: {
    NewsCard,
  },

  props: {
    newsList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      cardLink: false,
    };
  },

  mounted() {
    this.onCardLink();
  },

  methods: {
    onCardLink() {
      const media = window.matchMedia("(max-width:1279px)");
      const listener = (event) => (this.cardLink = event.matches);
      listener(media);

      media.addEventListener("change", listener);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-list {
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(2, 1fr);
  grid-gap: 32px 15px;

  @include media($max-width: 1920px) {
    grid-row-gap: calc(32 / 1920 * 100vw);
  }

  @include laptop {
    grid-template: 1fr / repeat(4, 1fr);
    grid-column-gap: calc(21 / 1279 * 100vw);
    .news-page & {
      grid-template: 1fr / repeat(3, 1fr);
    }
  }

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: calc(21 / 1023 * 100vw);
    .news-page & {
      grid-template: 1fr / repeat(2, 1fr);
    }
  }

  @include phone {
    grid-template: 1fr / 1fr !important;
    grid-row-gap: 20px;
  }

  &__item {
    &--main {
      @include media($min-width: 1280px) {
        grid-row: 1 / 4;
      }
    }

    &:last-child {
      @include tablet {
        .news-section & {
          display: none;
        }
      }
    }
  }
}
</style>
