<template>
  <router-link
    :to="to"
    class="button-hero"
  >
    <div class="button-hero__inner">
      <span class="button-hero__text">
        <slot />
      </span>
    </div>
  </router-link>
</template>
<script>
export default {
  name: "ButtonHero",

  props: {
    to: {
      type: [String, Object],
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.button-hero {
  $that: &;

  @include laptop {
    min-width: 134px;
    height: 50px;
  }

  position: relative;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  border-bottom: 1px solid $color_dark-40;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  color: white;
  background: rgba(35, 41, 48, 0.45);
  min-width: 174px;
  backdrop-filter: blur(10px);

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: $color_dark-40;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0);
    transform-origin: right center;
    transition: transform 0.4s 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover {
    &::before {
      transform: scale(1);
      transform-origin: left center;
    }
  }

  &:active {
  }

  &__inner {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20px 12px;

    @include flex-center;

    &::before {
      content: "";
      width: 1px;
      height: 100%;
      background-color: $color_dark-40;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: top center;
      transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);

      #{$that}:hover & {
        transform: scale(1);
        transform-origin: bottom center;
      }
    }

    &::after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: $color_dark-40;
      position: absolute;
      top: 0;
      right: 0;
      transform: scale(0);
      transform-origin: bottom center;
      transition: transform 0.4s 0.35s cubic-bezier(0.22, 0.61, 0.36, 1);

      #{$that}:hover & {
        transform: scale(1);

        transform-origin: top center;
      }
    }
  }

  &__text {
    /*    transition: text-shadow 0.65s 0.15s cubic-bezier(0.22, 0.61, 0.36, 1); */

    /*   #{$that}:hover & {
      text-shadow: 0 0 10px rgba(255, 255, 255, 1),
        0 0 50px rgba(255, 255, 255, 0.8), 0 0 35px rgba(255, 255, 255, 0.6),
        0 0 76px rgba(255, 255, 255, 0.4), 0 0 37px rgba(255, 255, 255, 0.5),
        0 0 78px rgba(255, 255, 255, 0.4), 0 0 39px rgba(255, 255, 255, 0.3),
        0 0 80px rgba(255, 255, 255, 0.2), 0 0 35px rgba(255, 255, 255, 0.1);
    } */
  }
}
</style>
