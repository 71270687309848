<template>
  <div
    v-if="!loading"
    class="layout"
  >
    <!--        <pre>{{ servicesList }}</pre>-->
    <MobileMenu
      :nav-list="navList"
      :burger-list="burgerList"
      :services-list="servicesList"
    />
    <div
      class="layout__header"
      :class="{
        ismm: !isMainMainPage,
        ...classes
      }"
    >
      <TheHeader
        :nav-list="navList"
        :burger-list="burgerList"
        :phone="phone"
      />
    </div>

    <div class="layout__content">
      <suspense>
        <router-view
          v-slot="{ Component }"
          :key="$route.fullPath"
        >
          <TransitionFade>
            <component :is="Component" />
          </TransitionFade>
        </router-view>
      </suspense>
    </div>
    <div
      v-if="!isMainPage || !isMobile "
      class="layout__footer"
    >
      <TheFooter />
    </div>

    <notifications style="top: 10px" />
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, provide, watch, onMounted } from 'vue';
import { useHead, useSeoMeta } from "@vueuse/head";
// import { useHead, useSeoMeta } from "@unhead/vue";
import api from '@/plugins/api';

import { useUserStore } from '@/stores/user';

const route = useRoute();
const router = useRouter();

const h1Seo = ref(null);
const loading = ref(true);
const userStore = useUserStore();

provide("h1Seo", h1Seo);

const auth = (sessionId, dropQuery = false) => {
  api.defaults.headers['sessionid'] = sessionId;

  api.get('/api/clientProfile').then(({ data }) => {
    userStore.data = data.client;
    userStore.auth = true;

    if (dropQuery) {
      const buffer = { ...route.query };
      delete buffer.session;

      router.replace({ query: buffer })
    };
  });
}

if (localStorage.sessionId) {
  auth(localStorage.sessionId);
}

onMounted(async () => {
  await router.isReady();

  if (route.query.session) {
    auth(route.query.session, true);
  }
});

watch(
  () => route.name,
  () => {
    loading.value = true;

    const fullPath = route.name === "Главная" ? "mainpage" : route.fullPath.substring(1);
    h1Seo.value = null;

    axios.get("/api/getSeo?url=" + fullPath).then(({ data }) => {
      const seo = data.data.seo;
      if (seo !== null) {
        useHead({
          title: seo.title !== null ? seo.title : "КВС",
        });
        useSeoMeta({
          description: seo.meta_description !== null ? seo.meta_description : "",
          keywords: seo.meta_keywords !== null ? seo.meta_keywords : "",
          ogTitle: seo.meta_og_title !== null ? seo.meta_og_title : "",
          ogDescription: seo.meta_og_description !== null ? seo.meta_og_description : "",
        });
        h1Seo.value = seo.h1;
      }
      if (seo === null) {
        useHead({ title: "КВС" });
        useSeoMeta({
          description: "",
          keywords: "",
          ogTitle: "",
          ogDescription: "",
        });
      }
    }).finally(() => {
      loading.value = false;
    });
  }
);
</script>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import throttle from "lodash/throttle";
import MobileMenu from "@/components/MobileMenu.vue";
import axios from "axios";
import TransitionFade from "@/components/transitions/TransitionFade.vue";

export default {
  name: "LayoutDefault",
  components: {
    TheHeader,
    TheFooter,
    MobileMenu,
    TransitionFade,
  },

  data() {
    return {
      windowScroll: 0,
      windowLastScroll: 0,
      navList: [],
      burgerList: [],
      phone: null,
      servicesList: [],
      isMobile: false,
    };
  },

  computed: {
    classes() {
      return {
        _secondary:
          this.$route.name === "Новости" ||
          this.$route.name === "Детальная новости" ||
          this.$route.name === "Детальная вторички" ||
          this.$route.name === "Детальная новостройки" ||
          this.$route.name === "Детальная загородной" ||
          this.$route.name === "Детальная коммерческой" ||
          this.$route.name === "Детальная менеджера" ||
          this.$route.name === "Страница помещения заграничной недвижимости" ||
          this.$route.name === "Error-page" ||
          this.$route.name === "Контакты" ||
          this.$route.name === "Контентная страница" ||
          this.$route.name === "Избранное" ||
          this.$route.name === "Отзывы" ||
          this.$route.name === "Коллекция избранного" ||
          this.$route.name === "Мои документы" ||
          this.$route.name === "Карта лояльности" ||
          this.$route.name === "Картa лояльности" ||
          this.$route.name === "Приведи друга" ||
          this.$route.name === "Подарочный сертификат" ||
          this.$route.name === "Партнерские программы" ||
          this.$route.name === "Вопросы эксперту",
      };
    },
    isMainPage() {
      return this.$route.name === "Главная" || this.$route.name === "Детальная менеджера"
    },
    isMainMainPage() {
      return this.$route.name === "Главная";
    },
    test() {
      return this.$route;
    },
  },

  created() {
    this.axios.defaults.headers.common["X-CSRF-TOKEN"] = this.getCookieToken("XSRF-TOKEN");
    this.throttledHandleScroll = throttle(this.handleScroll, 200);
    this.include("link","https://cdn.envybox.io/widget/cbk.css","stylesheet");
    this.include("script","https://cdn.envybox.io/widget/cbk.js?wcb_code=e6c08d92546535dfb8480cb80cb67f86");
    this.include("script","https://kvsagency.ru/assets/js/metrika.js");

    const el = '<noscript><div><img src="https://mc.yandex.ru/watch/91238558" style="position:absolute; left:-9999px;" alt="" /></div></noscript>';
    this.includeBody("div",el);
  },

  beforeMount() {
    this.getData();
  },

  mounted() {
    this.handleScroll();

    window.addEventListener("scroll", this.throttledHandleScroll);

    window.addEventListener("load", () => {
      document.body.classList.add("page_loaded");
    });

    this.matchMedia();
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll);
  },

  unmounted() {
    this.throttledHandleScroll.cancel();
  },

  methods: {
    handleScroll() {
      this.windowScroll = window.scrollY;

      if (this.windowScroll > 0) {
        document.body.classList.add("page_scrolled");
      } else {
        document.body.classList.remove("page_scrolled");
      }
      if (this.windowScroll > 0) {
        if (this.windowScroll > this.windowLastScroll) {
          document.body.classList.add("page_scroll-down");
          document.body.classList.remove("page_scroll-up");
        } else if (this.windowScroll < this.windowLastScroll) {
          document.body.classList.remove("page_scroll-down");
          document.body.classList.add("page_scroll-up");
        }
      }
      this.windowLastScroll = this.windowScroll;
    },

    getData() {
      this.axios
        .all([this.axios.get("/api/getHeaderLinks"), this.axios.get("/api/services")])
        .then(([headerLinks, services]) => {
          // this.navList = headerLinks.data.header_links;
          
          this.navList = headerLinks.data.header_links.filter(link => {
            return link.link !== '/new-estate' && link.link !== '/overseas-estate'
          });
          this.burgerList = headerLinks.data.burger_links;
          this.phone = headerLinks.data.phone;
          this.servicesList = services.data.data;
        });
    },

    getCookieToken(name) {
      const matches = document.cookie.match(
        new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    include(type,url,rel = false) {
        var file = document.createElement(type);
        if(type=='link'){
          file.href = url;
          file.rel = rel;
        }else{
          file.src = url;
        }

        document.getElementsByTagName('head')[0].appendChild(file);
    },
    includeBody(type,html) {
        var el = document.createElement(type);
        el.innerHTML = html;
        document.body.appendChild(el);
    },
    matchMedia() {
      const mediaQuery = window.matchMedia("(max-width: 639px)");

      if (mediaQuery.matches) {
        this.isMobile = true;
      }
    }
  },
};
</script>

<style lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    transition: transform 0.25s ease-in-out, background-color 0.15s ease;
    will-change: transform;
    backface-visibility: hidden;
    padding-top: 16px;

    @include tablet {
      transform: translate3d(0, 0, 0);
      padding-top: 0;
    }

    &._secondary {
      padding-top: 0;
      background-color: $color_dark-90;
    }

    &:not(._secondary) {
      .page_scrolled & {
        transition: transform 0.25s ease-in-out, box-shadow 0.45s 0.25s ease, background-color 0.45s 0.25s ease;
        transform: translate3d(0, -16px, 0);
        background-color: $color_dark-90;
        box-shadow: 0px -7px 20px rgba(0, 0, 0, 0.35);

        @include laptop {
          padding-top: 20px;
          transform: translate3d(0, -20px, 0);
        }

        @include tablet {
          padding-top: 0;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__content {
    // overflow: hidden;
    position: relative;
    z-index: 2;
    flex: 1 1 0%;
    background-color: $white;
  }

  &__footer {
    overflow: hidden;
    position: relative;
    z-index: 1;

    @include tablet {
      padding-bottom: 55px;
    }
  }
}
</style>
