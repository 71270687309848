<template>
  <div class="map-modal">
    <yandex-map
      v-if="showMap"
      class="map-modal__map"
      :scroll-zoom="map.scrollZoom"
      :coords="coords"
      :zoom="map.zoom"
      :options="map.options"
      :controls="map.controls"
    >
      <ymap-marker
        :coords="coords"
        marker-id="123"
        :icon="map.markerIcon"
        :hint-content="title"
      />
    </yandex-map>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "ModalMap",
  components: { yandexMap, ymapMarker },

  props: {
    coords: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      showMap: false,
      map: {
        zoom: 10,
        // coords: [59.94145120412136, 30.329258831422102],
        controls: ["zoomControl"],
        detailedControls: {
          zoomControl: {
            position: {
              right: 10,
              top: 100,
            },
          },
        },
        // scrollZoom: false,
        options: {
          // scrollZoomSpeed: 0.1,
          // avoidFractionalZoom: true,
          minZoom: 8,
          maxZoom: 18,
        },

        markerIcon: {
          layout: "default#image",
          imageHref: require("../assets/images/map-marker.png"),
          imageSize: [20, 20],
          imageOffset: [0, 0],
        },
      },
    };
  },

  mounted() {
    this.showMap = true;
  },
};
</script>

<style lang="scss" scoped>
.map-modal {
  &__map {
    max-width: 1584px;
    height: 918px;
    min-width: 80vw;
  }
}
</style>
