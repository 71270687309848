<template>
  <section class="resale-estate">
    <div class="resale-estate__inner">
      <div class="resale-estate__head">
        <app-title class="resale-estate__title">
          {{ title }}
        </app-title>
        <AppToggle
          v-if="query.showMap === false && !overseas"
          class="resale-estate__toggle-map"
          label="Показать на карте"
          @change="toggleMap"
        />
      </div>

      <div
        v-if="!resaleList.length"
        class="resale-estate__loader"
      >
        <LoaderImage class="resale-estate__loader-item" />
        <LoaderImage class="resale-estate__loader-item" />
        <LoaderImage class="resale-estate__loader-item" />
        <LoaderImage class="resale-estate__loader-item" />
        <LoaderImage class="resale-estate__loader-item" />
        <LoaderImage class="resale-estate__loader-item" />
        <LoaderImage class="resale-estate__loader-item" />
        <LoaderImage class="resale-estate__loader-item" />
      </div>

      <div
        v-if="swiperIsShown"
        class="resale-estate__slider-wrapper"
      >
        <swiper
          class="resale-estate__swiper"
          :auto-height="false"
          :breakpoints="{
            320: { slidesPerView: 1, centerdSlides: true },
            640: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }"
          :pagination="{
            el: '.resale-estate__pagination',
            dynamicBullets: true,
          }"
          :speed="800"
          :modules="modules"
        >
          <swiper-slide
            v-for="(resaleItem, index) in resaleList"
            v-show="!isFavourite && index < 8"
            :key="index"
            class="resale-estate__slide"
          >
            <EstateCard
              :card="resaleItem"
              :is-small="true"
              :overseas="overseas"
            />
          </swiper-slide>
        </swiper>

        <div class="resale-estate__pagination _pagination" />
      </div>

      <ul
        v-else
        class="resale-estate__list"
      >
        <li
          v-for="(resaleItem, index) in resaleList"
          :key="index"
          class="resale-estate__list-item"
        >
          <EstateCard
            :card="resaleItem"
            :is-small="true"
            :do-update="doUpdate"
            :overseas="overseas"
          />
        </li>
      </ul>

      <AppButton
        v-if="!isFavourite && !overseas"
        class="resale-estate__action"
        :secondary="true"
        to="/resale-estate"
      >
        В каталог
      </AppButton>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import EstateCard from "./EstateCard.vue";
import AppButton from "@/components/ui/AppButton.vue";
import LoaderImage from "./ui/LoaderImage.vue";
import AppToggle from "./ui/AppToggle.vue";
import { mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";

export default {
  name: "EstateResaleSection",
  components: {
    Swiper,
    SwiperSlide,
    EstateCard,
    AppButton,
    LoaderImage,
    AppToggle
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    resaleList: {
      type: Array,
      default: () => []
    },
    isFavourite: {
      type: Boolean,
      default: false
    },
    doUpdate: {
      type: Boolean,
      default: true
    },
    overseas: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      modules: [Pagination]
    };
  },
  computed: {
    ...mapState(useFilterStore, ["query"])
  },

  data() {
    return {
      swiperIsShown: false
    };
  },

  mounted() {
    this.swiperInit();
  },

  methods: {
    swiperInit() {
      const media = window.matchMedia("(max-width:639px)");
      const listener = (event) => (this.swiperIsShown = event.matches);
      listener(media);

      media.addEventListener("change", listener);
    },
    toggleMap(e) {
      this.emitter.emit("toggle-map", e);
    }
  }
};
</script>

<style lang="scss" scoped>
.resale-estate {
  $that: &;

  &__head {
    display: flex;
  }

  &__toggle-map {
    margin-left: auto;
    position: relative;
    z-index: 12;

    ._show-map & {
      opacity: 0;
      pointer-events: none;
    }

    @include laptop {
      display: none;
    }
  }

  padding: calc(64 / 1920 * 100vw) 0 calc(96 / 1920 * 100vw);

  @media (min-width: 1920px) {
    padding: 64px 0 96px;
  }

  @include laptop {
    padding: 40px 0 50px;
  }

  &__inner {
    @extend %container;

    text-align: center;
  }

  /*   &__loader-item {
    height: 456px;

    @include laptop {
      height: 428px;
    }

    @include tablet {
      height: 384px;
    }

    @include phone {
      height: 364px;

      &+& {
        display: none !important;
      }
    }
  } */

  &__title {
    text-align: left;

    @include phone {
      padding-bottom: 20px;
    }
  }

  &__swiper {
    overflow: visible;
    z-index: 10;
    margin: 0 -32px;

    @include phone-s {
      margin: 0 -16px;
    }
  }

  &__slide {
    height: auto;
    padding: 0 32px;

    @include phone-s {
      padding: 0 16px;
    }
  }

  &__pagination {
    padding: 12px 0;

    @media (min-width: 640px) {
      display: none;
    }
  }

  &__list,
  &__loader {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-16 / 1920 * 100vw);

    @media (min-width: 1920px) {
      margin: 0 -16px;
    }

    @include phone {
      margin: 0;
    }
  }

  &__list-item,
  &__loader-item {
    flex: 0 0 25%;
    padding: calc(32 / 1920 * 100vw) calc(16 / 1920 * 100vw) 0;

    @media (min-width: 1920px) {
      padding: 32px 16px 0;
    }

    @include laptop {
      flex: 0 0 33.333%;

      &:nth-child(-n + 7) {
        display: none;
      }
    }

    @include tablet {
      flex: 0 0 50%;
    }

    @include phone {
      flex: 1 1 100%;
      padding: 0;

      &:nth-child(n + 7) {
        display: block;
      }
    }
  }

  &__action {
    display: inline-block;
    margin: 62px auto 0;

    @include laptop {
      margin: 30px auto 0;
    }
  }
}
</style>
