<template>
  <section class="about-us">
    <div class="about-us__inner">
      <div class="about-us__body">
        <div class="about-us__info">
          <div
            v-if="isMain"
            class="about-us__head"
          >
            <app-title>О нас</app-title>
            <h3
              v-if="aboutSection.title"
              class="about-us__title"
            >
              {{ aboutSection.title.source }}
            </h3>
          </div>
          <div
            v-else
            class="about-us__head"
          >
            <app-title v-if="aboutSection.title">
              {{ aboutSection.title.source }}
            </app-title>
          </div>
          <div
            v-if="aboutSection.description"
            v-clamp="8"
            class="about-us__description"
            v-html="aboutSection.description.source"
          />
          <div
            v-if="isMain"
            class="about-us__link about-us__link--desktop"
          >
            <AppButton
              to="/about"
              :secondary="true"
            >
              Подробнее
            </AppButton>
          </div>
        </div>
        <div class="about-us__image">
          <img
            class="lazy"
            :data-src="require('@/assets/images/content/about-us/cover.jpeg')"
            alt=""
          >
        </div>
        <div
          v-if="isMain"
          class="about-us__link about-us__link--mobile"
        >
          <AppButton
            to="/about"
            :secondary="true"
          >
            Подробнее
          </AppButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from "./ui/AppButton.vue";

export default {
  name: "AboutUs",
  components: {
    AppButton,
  },

  props: {
    isMain: {
      type: Boolean,
      default: true,
    },
    aboutSection: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.about-us {
  margin: 0 -56px;
  padding: 64px 56px;
  background: $color_grey-2;

  @include tablet {
    margin: 0 -40px;
    padding: 50px 40px;
  }

  @include phone {
    margin-right: -30px;
    margin-left: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  @include phone-s {
    margin-right: -16px;
    margin-left: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__inner {
    @extend %container;
  }

  &__body {
    display: flex;
    justify-content: space-between;

    @include tablet {
      display: block;
    }
  }

  &__info {
    width: calc(100% / 2 - 16px);
    padding: 64px 115px 11px;
    background: $white;

    @include media($max-width: 1920px) {
      padding-right: calc(115 / 1920 * 100vw);
      padding-left: calc(115 / 1920 * 100vw);
    }

    @include laptop {
      padding: 64px 40px 40px;
    }

    @include tablet {
      width: 100%;
      padding: 0;
      background: transparent;
    }
  }

  &__image {
    width: calc(100% / 2 - 16px);
    height: 538px;

    @include laptop {
      height: calc(538 / 1279 * 100vw);
      min-height: 475px;
    }

    @include tablet {
      width: 100%;
      margin-bottom: 30px;
    }

    @include phone {
      height: calc(400 / 639 * 100vw);
      min-height: 202px;
    }

    img {
      width: 100%;
      height: 100%;
      @include lazy-loaded;
      @include object-fit(cover, center);
    }
  }

  &__head {
    margin-bottom: 10px;
  }

  &__title {
    margin-top: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    @include laptop {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__description {
    margin-bottom: 64px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @include laptop {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__link {
    &--desktop {
      @include tablet {
        display: none;
      }
    }

    &--mobile {
      @include flex-center;

      @include media($min-width: 1024px) {
        display: none;
      }
    }
  }
}
</style>
