<template>
  <ul
    class="socials-list"
    :class="classes"
  >
    <li
      v-for="item in socialsList"
      :key="item"
      class="socials-list__item"
    >
      <ButtonSocial
        v-if="item.link && item.name"
        :social="item.name"
        :href="item.link"
      />
    </li>
  </ul>
</template>

<script>
import { reactive, computed } from "vue";
import ButtonSocial from "./ButtonSocial.vue";
export default {
  name: "SocialsList",
  components: { ButtonSocial },

  props: {
    secondary: {
      type: Boolean,
      default: null,
    },
    socialsList: {
      type: [Array, Object],
      default: null,
    },
  },

  // setup(props) {
  //   props = reactive(props);

  //   const socialsList = [
  //     {
  //       name: "telegram",
  //       link: `https://t.me/share?url=${encodeURIComponent(window.location.href)}`,
  //     },
  //     {
  //       name: "whatsapp",
  //       link: `https://api.whatsapp.com/send/?text=${encodeURIComponent(window.location.href)}`,
  //     },
  //     {
  //       name: "vk",
  //       link: `https://vk.com/share.php?url=${encodeURIComponent(window.location.href)}`,
  //     },
  //   ];

  //   return {
  //     classes: computed(() => ({
  //       "_is-secondary": props.secondary,
  //     })),
  //     socialsList,
  //   };
  // },
};
</script>

<style lang="scss" scoped>
.socials-list {
  $that: &;

  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  &__item {
    flex: none;
    padding: 4px;

    ._is-secondary & {
      .button-social {
        color: $color_dark-90;
        border-color: $color_dark-40;

        &:hover {
          color: $color_dark-70;
          border-color: $color_dark-40;
        }
      }
    }
  }
}
</style>
