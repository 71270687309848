<!-- <AppModal v-model="modal_1" -->
<!-- <div @click="modal_1 = true"> -->

<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="modal-container"
    :class="{ _full: modalFull }"
    content-class="modal-content"
    :esc-to-close="true"
  >
    <div
      ref="modalContent"
      class="modal__content"
    >
      <slot :params="params" />
    </div>

    <button
      class="modal__close"
      @click="closeModal(close)"
    >
      <app-svg
        id="close"
        width="12"
        height="12"
      />
    </button>
  </vue-final-modal>
</template>

<script>
export default {
  name: "AppModal",
  inheritAttrs: false,

  props: {
    modalFull: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeModal(close) {
      const openEstateCard = this.$refs.modalContent.querySelector(".estate-card._is-showing");

      if (openEstateCard) {
        openEstateCard.classList.remove("_is-showing");
      } else {
        close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
$this: &;

::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  border: 1px solid #e2e8f0;
  background: #fff;
  overflow: auto;

  @include phone {
    max-width: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    overflow: hidden;
  }

  #{$this}._full & {
    margin: 0;
    height: 100%;
    max-height: 100%;
    background-color: $color_dark-90;
  }
}

.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

.modal__content {
  flex-grow: 1;
  max-width: 1720px;
  min-width: 600px;
  min-height: 200px;
  // padding: 12px 0 38px;

  @include phone {
    min-width: 100%;
    overflow: auto;
  }

  #{$this}._full & {
    max-width: 100%;
    height: 100%;
  }
}

.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}

.modal__close {
  color: #999999;
  position: absolute;
  top: 16px;
  right: 16px;

  #{$this}._full & {
    top: 75px;
    right: 70px;
    z-index: 10;
    width: 56px;
    height: 56px;
    padding: 17px;
    transition: color 0.25s ease-out;

    @include hover {
      color: $color_primary;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
