<template>
  <div class="user-menu">
    <div class="user-menu__info">
      <p class="user-menu__name">
        {{ userStore.getFullName }}
      </p>
      <p class="user-menu__tel">
        {{ userStore.data.phone }}
      </p>
    </div>

    <div class="user-menu__items">
      <router-link
        to="/lk"
        class="user-menu__item user-menu__item_lk"
      >
        Мой кабинет
      </router-link>

      <a
        href="#"
        class="user-menu__item user-menu__item_logout"
        @click.prevent="userStore.logout()"
      >
        Выйти
      </a>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user';

export default {
  setup() {
    const userStore = useUserStore();

    return { userStore };
  },
};
</script>

<style lang="scss" scoped>
.user-menu {
  padding: 20px 40px 10px;
  border: 1px solid $color_dark-70;
  position: absolute;
  top: calc(100% - 1px);
  right: 56px;
  backdrop-filter: blur(4px);
  width: 300px;
  display: none;

  .page_scrolled &, ._secondary & {
    background-color: $color_dark-70;
  }

  &:before {
    content: '';
    position: absolute;
    top: -17px;
    left: 0;
    height: 17px;
    width: 300px;
  }

  &__info {
    padding-bottom: 10px;
  }

  &__name {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: $white;
  }

  &__tel {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #A0A2A6;
  }

  &__item {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $white;
    padding: 10px 0 10px 30px;
    border-top: 1px solid $color_dark-40;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-size: contain;
      position: absolute;
      top: calc(50% - 10px);
      left: 0;
    }

    &:hover {
      color: $color_orange-50;
    }

    &_lk {
      &:before {
        background-image: url('@/assets/images/user-menu/lk.svg');
      }
    }

    &_logout {
      &:before {
        background-image: url('@/assets/images/user-menu/logout.svg');
      }
    }
  }
}
</style>
