<template>
  <figure
    ref="burger"
    class="burger-menu-icon"
    :class="classes"
  >
    <div class="burger-menu-icon__inner">
      <span />
      <span />
      <span />
    </div>
  </figure>
</template>
<script>
import { computed, reactive } from "vue";

export default {
  name: "BurgerMenuIcon",

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },

    isClicked: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        "_is-mobile": props.isMobile,
        "_is-clicked": props.isClicked,
      })),
    };
  },

  updated() {
    this.$refs.burger.style = "pointer-events: none;";

    setTimeout(() => {
      this.$refs.burger.style = "pointer-events: all;";
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.burger-menu-icon {
  $that: &;

  cursor: pointer;
  display: inline-block;
  padding: 8px;
  z-index: 100;

  &._is-mobile {
    padding: 0;
    padding-top: 4px;
  }

  &__inner {
    position: relative;
    width: 33px;
    height: 16px;
    transition: transform 0.25s 0.25s ease-out;

    ._is-mobile & {
      width: 21px;
      height: 12px;
    }

    ._is-clicked & {
      transform: rotate(90deg);
    }

    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $white;
      border-radius: 14px;
      transition: top 0.25s 0.5s, transform 0.25s ease-out;
      transform-origin: center;

      ._is-mobile & {
        height: 1px;
        background-color: $color_primary;
        border-radius: 0;
      }

      ._is-clicked & {
        transition: top 0.25s, transform 0.25s 0.5s ease-out;
      }

      &:first-child {
        top: 0;

        ._is-clicked & {
          top: 7px;
          transform: rotate(45deg);
        }
      }

      &:nth-child(2) {
        top: 50%;
        transform: translate3D(0, -50%, 0);
        transition: transform 0.25s ease-out, opacity 0.15s 0.45s ease;

        ._is-clicked & {
          transition: transform 0.25s ease-out;
          transform: rotate(45deg);
          opacity: 0;
        }
      }

      &:last-child {
        bottom: 0;

        ._is-clicked & {
          top: 7px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
</style>
