<!-- https://github.com/egoist/vue-content-loader -->
<!-- <LoaderImage/> -->

<template>
  <div class="loader-image">
    <ContentLoader
      class="loader-image__svg"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
      viewBox="0 0 400 200"
      preserveAspectRatio="none"
    >
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width="100%"
        height="100%"
      />
    </ContentLoader>
  </div>
</template>
<script>
import { ContentLoader } from "vue-content-loader";
export default { components: { ContentLoader } };
</script>
<style lang="scss" scoped>
.loader-image {
  position: relative;
  display: flex;

  &__svg {
  }
}
</style>
