import { createRouter, createWebHistory } from "vue-router";

import { useFilterStore } from "@/store/filters/filterStore.js";

import MainPage from "../pages/MainPage.vue";
import axios from "axios";

const routes = [
  // dev pages start
  /*  {
    path: "/ui",
    name: "UI Page",
    component: () => import("@/pages/UIPage.vue"),
  },
  {
    path: "/test",
    name: "Dev Test Page",
    component: () => import("@/pages/TestPage.vue"),
  },
    {
    path: "/catalog",
    name: "Каталог",
    component: () => import("@/pages/CatalogPage.vue"),
  },
  */
  // dev pages end
  {
    path: "/",
    name: "Главная",
    component: MainPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error-page",
    component: () => import("@/pages/ErrorPage.vue"),
  },
  {
    path: "/resale-estate",
    name: "Вторичная недвижимость",
    component: () => import("@/pages/ResaleEstatePage.vue"),
  },
  {
    path: "/resale-estate/:alias",
    name: "Детальная вторички",
    component: () => import("@/pages/EstateDetailPage.vue"),
  },
  {
    path: "/v/:alias",
    name: "Детальная менеджера",
    component: () => import("@/pages/ManagerDetailPage.vue"),
  },
  // {
  //   path: "/new-estate",
  //   name: "Новостройки",
  //   component: () => import("@/pages/NewEstatePage.vue"),
  // },
  // {
  //   path: "/new-estate/:alias",
  //   name: "Детальная новостройки",
  //   component: () => import("@/pages/EstateDetailPage.vue"),
  // },
  {
    path: "/country-estate",
    name: "Загородная",
    component: () => import("@/pages/CountryEstatePage.vue"),
  },
  {
    path: "/country-estate/:alias",
    name: "Детальная загородной",
    component: () => import("@/pages/EstateDetailPage.vue"),
  },
  {
    path: "/commerse-estate",
    name: "Коммерческая",
    component: () => import("@/pages/CommerseEstatePage.vue"),
  },
  {
    path: "/commerse-estate/:alias",
    name: "Детальная коммерческой",
    component: () => import("@/pages/EstateDetailPage.vue"),
  },
  {
    path: "/contacts",
    name: "Контакты",
    component: () => import("@/pages/ContactsPage.vue"),
  },
  {
    path: "/services",
    name: "Услуги",
    component: () => import("@/pages/ServicesPage.vue"),
  },
  {
    path: "/services/:alias",
    name: "Детальная услуги",
    component: () => import("@/pages/ServiceDetailPage.vue"),
  },

  {
    path: "/news",
    name: "Новости",
    component: () => import("@/pages/NewsPage.vue"),
  },
  {
    path: "/news/:alias",
    name: "Детальная новости",
    component: () => import("@/pages/NewsDetailPage.vue"),
  },
  {
    path: "/estate-detail",
    name: "Детальная недвижимости",
    component: () => import("@/pages/EstateDetailPage.vue"),
  },
  {
    path: "/content/:alias",
    name: "Контентная страница",
    component: () => import("@/pages/ContentPage.vue"),
  },
  {
    path: "/about",
    name: "О компании",
    component: () => import("@/pages/CompanyPage.vue"),
  },
  {
    path: "/reviews",
    name: "Отзывы",
    component: () => import("@/pages/ReviewsPage.vue"),
  },
  {
    path: "/reviews/?agent=:alias",
    name: "Отзывы агента",
    component: () => import("@/pages/ReviewsPage.vue"),
  },
  {
    path: "/favorites",
    name: "Избранное",
    component: () => import("@/pages/Favorites.vue"),
  },
  {
    path: "/favorites-shared",
    name: "Коллекция избранного",
    component: () => import("@/pages/FavoritesShared.vue"),
  },
  // {
  //   path: "/overseas-estate",
  //   name: "Заграничная недвижимость",
  //   component: () => import("@/pages/OverseasEstatePage.vue"),
  // },
  // {
  //   path: "/overseas-estate/:alias",
  //   name: "Детальная заграничной недвижимости",
  //   component: () => import("@/pages/OverseasCountryPage.vue"),
  // },
  // {
  //   path: "/overseas-estate/:alias/:id",
  //   name: "Страница помещения заграничной недвижимости",
  //   component: () => import("@/pages/EstateDetailPage.vue"),
  // },

  // LK
  {
    path: '/lk',
    name: 'Личный кабинет',
    redirect: '/lk/loyalty',
  },
  // {
  //   path: '/lk/documents',
  //   name: 'Мои документы',
  //   component: () => import('@/pages/lk/LKDocuments.vue'),
  // },
  {
    path: '/lk/invite',
    name: 'Приведи друга',
    component: () => import('@/pages/lk/LKInvite.vue'),
  },
  {
    path: '/lk/loyalty',
    name: 'Карта лояльности',
    component: () => import('@/pages/lk/LKLoyaltyCard.vue'),
  },
  {
    path: '/lk/loyalty/:code',
    name: 'Картa лояльности',
    component: () => import('@/pages/lk/LKLoyaltyCardInner.vue'),
  },
  {
    path: '/lk/partners',
    name: 'Партнерские программы',
    component: () => import('@/pages/lk/LKPartners.vue'),
  },
  {
    path: '/lk/qna',
    name: 'Вопросы эксперту',
    component: () => import('@/pages/lk/LKQna.vue'),
  },
  {
    path: '/lk/certificates',
    name: 'Подарочный сертификат',
    component: () => import('@/pages/lk/LKCertificates.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    const store = useFilterStore();

    if (to.name === from.name) {
      window.scrollTo(0, store.scrollY);
    } else {
      window.scrollTo(0, 0);
    }
  },
});

router.beforeEach((to, from, next) => {
  let fullPath = null;
  routes.forEach((route) => {
    if (route.name === to.name) fullPath = to.fullPath.substring(1);
  });
  if (fullPath !== null) {
    axios.get("/api/getSeo?url=" + fullPath).then(({ data }) => {
      data.data.redirect !== null ? next(data.data.redirect.to) : next();
    });
  }
});

export default router;
