<template>
  <ul class="subway-list">
    <li
      v-for="(subwayItem, index) in subwayList"
      :key="index"
      class="subway-list__item"
    >
      <span
        class="subway-list__item-name"
        :class="{
          '_line-1': subwayItem.color === 'D6083B',
          '_line-2': subwayItem.color === '0078C9',
          '_line-3': subwayItem.color === '009A49',
          '_line-4': subwayItem.color === 'EA7125',
          '_line-5': subwayItem.color === '702785',
        }"
      >
        {{ subwayItem.name }}</span>
      <span
        v-if="subwayItem['time-on-transport']"
        class="subway-list__item-commute"
      >{{ subwayItem["time-on-transport"] }} мин</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SubwayList",

  props: {
    subwayList: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.subway-list {
  $that: &;

  $line_1: #d20a30;
  $line_2: #0280d7;
  $line_3: #079757;
  $line_4: #f29f21;
  $line_5: #760281;

  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;

  &__item {
    font-size: 14px;
    line-height: 18px;
    color: $color_dark-90;
    margin-left: 24px;
  }

  &__item-name {
    display: inline-block;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      background-color: $color_primary;
      margin-right: 8px;
    }

    &._line-1::before {
      background-color: $line_1;
    }

    &._line-2::before {
      background-color: $line_2;
    }

    &._line-3::before {
      background-color: $line_3;
    }

    &._line-4::before {
      background-color: $line_4;
    }

    &._line-5::before {
      background-color: $line_5;
    }
  }

  &__item-commute {
    color: $color_dark-50;
    padding-left: 6px;
  }
}
</style>
