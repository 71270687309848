<template>
  <ul class="services-list">
    <li
      v-for="(service, index) in servicesList"
      :key="index"
      class="services-list__item"
    >
      <ServicesCard :service="service" />
    </li>
  </ul>
</template>

<script>
import ServicesCard from "@/components/ServicesCard.vue";

export default {
  name: "ServicesList",
  components: {
    ServicesCard,
  },

  props: {
    servicesList: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.services-list {
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  grid-gap: 32px;
  margin-top: 24px;

  @include media($max-width: 1920px) {
    grid-gap: calc(32 / 1920 * 100vw);
  }

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include phone {
    grid-template: 1fr / 1fr;
  }
}
</style>
