<template>
  <vue-final-modal
    v-model="isShow"
    :z-index-auto="true"
    :hide-overlay="true"
    :lock-scroll="false"
    :classes="'modal-container'"
    :attach="reference"
  >
    <section class="cancel-modal">
      <div class="cancel-block">
        <div class="cancel-text">
          Вы недавно удалили этот обьект
        </div>
        <button
          class="cancel-button"
          @click="$emit('return-like')"
        >
          Вернуть
        </button>
      </div>
    </section>
  </vue-final-modal>
</template>

<script>
import { ref, inject } from "vue";

export default {
  name: "CancelDeleteCard",
  props: ["reference"],
  setup() {
    const showModal = ref(true);
    const isShow = inject("isShowCancelModal");
    return {
      showModal,
      isShow,
    };
  },
  methods: {
    closeModal(close) {
      const openEstateCard = this.$refs.modalContent.querySelector(".estate-card._is-showing");

      if (openEstateCard) {
        openEstateCard.classList.remove("_is-showing");
      } else {
        close();
      }
    },
  },
};
</script>

<style>
.cancel-modal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  background: #f9fafa;
  flex: none;
  order: 0;
  flex-grow: 0;
  transform: translate3d(0, 125%, 0);
}

.modal-container {
  display: flex;
  justify-content: center;
}

.cancel-block {
  display: flex;
  flex-flow: column;
}

.cancel-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.cancel-button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f4691b;
}

/**/
</style>
