import { parse, setNullNumber } from "@/plugins/helpers.js";

const setRanges = {
  checkClearRanges(obj) {
    const step1 = this.clearPropIfRange(obj, [
      ["Kitchen", "kitchen_space", "kitchenSpace"],
      ["Floor", "floor", "floor"],
      ["Price", "price", "price"],
      ["Square", "living_space", "area"],
      ["RoadDistance", "ring_road_distance", "ringRoadDistance"],
      ["Area", "lot_area", "lotArea"],
    ]);
    const step2 = this.clearEmptyArrays(step1, ["rooms", "subLocality", "ids", "deadlines", "furnish", "purposes"]);
    const step3 = this.clearIfNull(step2, [
      "notLastFloor",
      "lastFloor",
      "notFirstFloor",
      "newFlat",
      "isSNT",
      "isDNP",
      "isIZHS",
    ]);
    return step3;
  },
  clearIfNull(query, arr) {
    const result = { ...query };
    for (const prop in query) {
      if (arr.includes(prop) && this.query[prop] === 0) {
        delete result[prop];
      }
    }
    return result;
  },
  clearEmptyArrays(queryClearSliders, props) {
    const result = { ...queryClearSliders };
    props.forEach((prop) => {
      if (result.hasOwnProperty(prop)) {
        if (JSON.parse(result[prop]).length === 0) {
          delete result[prop];
        }
      }
    });
    return result;
  },
  clearPropIfRange(obj, slidersList) {
    function getKeys(slidersList) {
      return slidersList.map((slider) => slider[2]);
    }

    const result = {};
    const sliderQueryKeys = getKeys(slidersList);
    for (const prop in obj) {
      const val = obj[prop];
      if (sliderQueryKeys.includes(prop)) {
        if (this.check(prop, val, slidersList)) {
          result[prop] = val;
        }
      } else {
        result[prop] = val;
      }
    }
    return result;
  },
  check(prop, val, slidersList) {
    const sliderNames = slidersList.find((el) => el[2] === prop);
    const parsedVal = JSON.parse(val);
    const minEqualRange = parsedVal.min === this.query[`min${sliderNames[0]}Range`];
    const maxEqualRange = parsedVal.max === this.query[`max${sliderNames[0]}Range`];
    const add = !(minEqualRange && maxEqualRange);
    return add;
  },
  setRangesAndValues(data, route) {
    this.setSliderValuesAndRanges(data, route, [
      ["Kitchen", "kitchen_space", "kitchenSpace"],
      ["Floor", "floor", "floor"],
      ["Price", "price", "price"],
      ["Square", "living_space", "area"],
      ["RoadDistance", "ring_road_distance", "ringRoadDistance"],
      ["Area", "lot_area", "lotArea"],
    ]);
    this.setAreaIfCommerse(data, route);
    data.filters.city ? this.setCityRange(data.filters) : null;
    data.filters.rooms ? this.setRoomRange(data.filters, route) : null;
  },
  setAreaIfCommerse(data, route) {
    if (this.query.currentEstateType.type === "commerseEstate") {
      if (data.filters.area) {
        this.setSliderValuesAndRanges(data, route, [["Square", "lot_area", "area"]]);
      }
    }
  },
  setRangeForSlider(filters, route, stateName, filterName, queryName) {
    if (!route.query.hasOwnProperty(queryName)) {
      this.query[`min${stateName}Value`] = setNullNumber(filters[filterName].min);
      this.query[`max${stateName}Value`] = setNullNumber(filters[filterName].max);
    } else {
      const query = parse(route.query);
      this.query[`min${stateName}Value`] = setNullNumber(query[queryName].min);
      this.query[`max${stateName}Value`] = setNullNumber(query[queryName].max);
    }
    this.query[`min${stateName}Range`] = setNullNumber(filters[filterName].min);
    this.query[`max${stateName}Range`] = setNullNumber(filters[filterName].max);
  },

  setCityRange(filters) {
    if (filters.city) {
      this.query.currentCitiesRange = filters.city;
      this.query.currentCity = [];
      this.query.currentSubLocalRange = [];
      this.query.subLocalitiesAreDisabled = true;
    }
  },
  setRoomRange(filters) {
    this.query.currentRoomListRange.forEach((room, i) => {
      const disabled = !filters.rooms.includes(room.value);
      this.query.currentRoomListRange[i].disabled = disabled;
      if (room.value >= 4) {
        this.query.currentRoomListRange[4].disabled = false;
      }
    });
  },
  setSliderValuesAndRanges(data, route, sliders) {
    sliders.forEach((slider) =>
      data.filters[slider[1]] ? this.setRangeForSlider(data.filters, route, slider[0], slider[1], slider[2]) : null
    );
  },

  setListsOfferDealinesPurposesZhkList() {
    this.query.offerDeadlines = this.query.options.filtersList.offerDeadlines.map((el) => {
      return {
        ...el,
        checked: false,
      };
    });
    this.query.purposes = this.query.options.filtersList.offerPurposes.map((el) => {
      return {
        title: el,
        checked: false,
      };
    });

    this.query.offerZhkList = this.query.options.filtersList.offerZhkList.map((el) => {
      return {
        ...el,
        checked: false,
      };
    });
  },

  setMainCategoriesList() {
    if (!this.query.mainCategoriesisSet) {
      this.query.mainCategories = this.query.options.filtersList.offerCategories.main.map((el) => {
        return {
          ...el,
          checked: false,
        };
      });
      this.query.mainCategoriesisSet = true;
    }
  },
  setCategoryListDependingByCategory() {
    const categoryList = this.query.options.filtersList.offerCategories.main
      .filter((el) => el.group === this.query.currentEstateType.type)
      .map((el) => {
        return {
          ...el,
          checked: false,
        };
      });
    this.query.categoriesList = categoryList;
  },
  setCurrentEstateType(path) {
    if (path === "/resale-estate" || path === "/") {
      this.query.currentEstateType = { name: "Вторичная недвижимость", type: "resaleEstate" };
    } else if (path === "/new-estate") {
      this.query.currentEstateType = { name: "Новостройки", type: "newEstate" };
    } else if (path === "/country-estate") {
      this.query.currentEstateType = { name: "Загородная", type: "countryEstate" };
    } else if (path === "/commerse-estate") {
      this.query.currentEstateType = { name: "Коммерческая", type: "commerseEstate" };
    }
  },

  setCurrentEstateTypeByCategory(id) {
    if (id >= 1 && id < 7) {
      this.query.currentEstateType = { name: "Загородная", type: "countryEstate" };
    } else if (id === 7 || id === 8) {
      this.query.currentEstateType = { name: "Вторичная недвижимость", type: "resaleEstate" };
    } else if (id === 12) {
      this.query.currentEstateType = { name: "Коммерческая", type: "commerseEstate" };
    }
  },

  closeAdditionalFiltersIfCommerseSelected(id) {
    if (id === 12) {
      this.query.showAllFilters = false;
    }
  },
  setSettings() {
    const type = this.query.currentEstateType.type;
    if (type === "resaleEstate") {
      this.query.type = 1;
      this.query.isZhk = 0;
      this.query.category = [7];
      this.setCheckCategory();
    }
    if (type === "newEstate") {
      this.query.type = 1;
      this.query.isZhk = 1;
      this.query.category = [7, 8];
      this.setCheckCategory();
    }
    if (type === "countryEstate") {
      this.query.type = 1;
      this.query.isZhk = 0;
      this.query.category = [1];
      this.setCheckCategory();
    }
    if (type === "commerseEstate") {
      this.query.type = 1;
      this.query.isZhk = null;
      this.query.category = [12];
    }
  },
  async setInterfaceStateAction(route) {
    const queryIsEmpty = Object.entries(route.query).length === 0;
    queryIsEmpty ? null : await this.setInterfaceState(route);
  },
  async setFirstSettings(route) {
    const queryIsEmpty = Object.entries(route.query).length === 0;
    queryIsEmpty ? this.setSettings() : null;
  },
  setCheckCategory() {
    const categoryElement = this.query.category[0];
    this.query.categoriesList.forEach((el, i) => {
      if (el.id === categoryElement) {
        this.query.categoriesList[i].checked = true;
      } else {
        this.query.categoriesList[i].checked = false;
      }
    });
  },
};

export default setRanges;
