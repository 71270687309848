export default {
  name: "phone",

  mounted(element, binding) {
    const phone = binding.value.split("");

    if (binding.value.length == 11) {
      phone.splice(1, "", "(");
      phone.splice(5, "", ")");
      phone.splice(9, "", "-");
      phone.splice(12, "", "-");
    }
    if (binding.value.length == 12) {
      phone.splice(2, "", " (");
      phone.splice(6, "", ") ");
      phone.splice(10, "", "-");
      phone.splice(13, "", "-");
    }

    element.innerHTML = phone.join("");
  },

  updated(element, binding) {
    const phone = binding.value.split("");

    if (binding.value.length == 11) {
      phone.splice(1, "", "(");
      phone.splice(5, "", ")");
      phone.splice(9, "", "-");
      phone.splice(12, "", "-");
    }
    if (binding.value.length == 12) {
      phone.splice(2, "", " (");
      phone.splice(6, "", ") ");
      phone.splice(10, "", "-");
      phone.splice(13, "", "-");
    }

    element.innerHTML = phone.join("");
  },
};
