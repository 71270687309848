<template>
  <AppAccordion class="filter-room" :arrow="true" :is-filter-head="isFilterHead" :is-filter-map="isFilterMap">
    <template #header> Комнат </template>
    <div class="filter-room__body">
      <div v-if="query.category[0] !== 8">
        <ul class="filter-room__room-list">
          <li
            v-for="(room, index) in currentRoomListRange"
            v-show="room.value > 0"
            :key="index"
            class="filter-room__room-item"
          >
            <label>
              <input
                :disabled="true"
                class="filter-room__input"
                type="checkbox"
                name="room-amount"
                :checked="room.checked"
              />
              <button class="filter-room__button" :disabled="room.disabled" @click="toggleRoom(room.value)">
                {{ room.name }}
              </button>
            </label>
          </li>
        </ul>
        <FormCheck
          v-if="studioIsAvailable"
          name="room-amount"
          class="_studio"
          :checked="roomStudioChecked"
          @update:modelValue="toggleStudio"
        >
          Студия
        </FormCheck>
      </div>
      <div v-else>
        <FormCheck name="room-amount" class="_studio" checked="true"> Комната </FormCheck>
      </div>
    </div>
  </AppAccordion>
</template>

<script>
import AppAccordion from "@/components/ui/AppAccordion.vue";
import FormCheck from "../form/FormCheck.vue";
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterRoom",
  components: { AppAccordion, FormCheck },
  computed: {
    ...mapState(useFilterStore, ["currentRoomListValues", "currentRoomListRange", "studioIsAvailable", "query"]),
    roomStudioChecked: function () {
      return this.currentRoomListRange[0].checked;
    },
  },

  props: {
    isFilterHead: {
      type: Boolean,
      default: false,
    },
    isFilterMap: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapActions(useFilterStore, [
      "setCurrentRoomList",
      "setCurrentRoomListStudio",
      "returnRequiredParams",
      "checkedRooms",
    ]),
    async toggleRoom(value) {
      this.setCurrentRoomList(value);
      await setParamsInUrl(this, this.$route.query, {
        rooms: this.currentRoomListValues,
      });
      this.emitter.emit("load-with-params", "SET_PRICE_RANGE");
    },
    async toggleStudio() {
      this.setCurrentRoomListStudio();

      await setParamsInUrl(this, this.$route.query, {
        rooms: this.currentRoomListValues,
      });
      this.emitter.emit("load-with-params", "SET_PRICE_RANGE");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-room {
  $that: &;

  &__body {
    min-width: 224px;
    background-color: $color_dark-90;
    box-shadow: 0px 15px 64px rgba(35, 41, 48, 0.04), 0px 8px 16px rgba(35, 41, 48, 0.06),
      0px 6px 8px rgba(35, 41, 48, 0.07);
    padding: 20px 22px;

    @include tablet {
      padding: 16px 16px 0;
    }
  }

  &__room-list {
    display: flex;
  }

  &__room-item {
    & + & {
      padding-left: 4px;
    }
  }

  &__input {
    @include visually-hidden;

    &:hover {
      background-color: red;
    }
  }

  &__input:checked {
    & + #{$that}__button {
      background-color: $color-dark-50;
    }
  }

  &__button {
    cursor: pointer;
    width: 44px;
    height: 38px;
    border: 1px solid $color_dark-50;
    transition: background-color 0.25s ease;

    @include flex-center;

    @include hover {
      background-color: $color-dark-50;
    }

    &:disabled {
      background-color: #232930;
      border-color: #343434;
      color: #4c4c4c;
    }
  }

  ._studio {
    margin-top: 20px;
  }
}
</style>
