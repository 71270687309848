<template>
  <FormCheck
    :is-filter-head="true"
    class="filters__filter _filter-head"
    :is-dark="true"
    :digital="true"
    :checked="isIZHSCheck"
    @update:modelValue="setNewIsIZHSValueToggle"
  >
    ИЖС
  </FormCheck>
  <FormCheck
    :is-filter-head="true"
    class="filters__filter _filter-head"
    :is-dark="true"
    :digital="true"
    :checked="isSNTCheck"
    @update:modelValue="setNewIsSNTValueToggle"
  >
    СНТ
  </FormCheck>
  <FormCheck
    :is-filter-head="true"
    class="filters__filter _filter-head"
    :is-dark="true"
    :digital="true"
    :checked="isDNPCheck"
    @update:modelValue="setNewIsDNPValueToggle"
  >
    ДНП
  </FormCheck>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useFilterStore } from "@/store/filters/filterStore.js";
import FormCheck from "@/components/form/FormCheck.vue";
import { setParamsInUrl } from "@/plugins/helpers.js";

export default {
  name: "FilterNewFlat",
  components: { FormCheck },
  computed: {
    ...mapState(useFilterStore, ["isSNTCheck", "isDNPCheck", "isIZHSCheck", "query"]),
  },
  methods: {
    ...mapActions(useFilterStore, [
      "setNewIsSNTValue",
      "setNewIsIZHSValue",
      "setNewIsDNPValue",
      "returnRequiredParams",
    ]),
    async setNewIsSNTValueToggle() {
      this.setNewIsSNTValue();
      await setParamsInUrl(this, this.$route.query, {
        isSNT: this.query.isSNT,
      });
      this.emitter.emit("load-with-params");
    },
    async setNewIsIZHSValueToggle() {
      this.setNewIsIZHSValue();
      await setParamsInUrl(this, this.$route.query, {
        isIZHS: this.query.isIZHS,
      });
      this.emitter.emit("load-with-params");
    },
    async setNewIsDNPValueToggle() {
      this.setNewIsDNPValue();
      await setParamsInUrl(this, this.$route.query, {
        isDNP: this.query.isDNP,
      });
      this.emitter.emit("load-with-params");
    },
  },
};
</script>

<style scoped>
._filter-head {
  padding: 0 12px;
}
</style>
