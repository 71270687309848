const getPathDependingByCategory = {
  getPathDependingByCategory() {
    let path = "/";
    const category = this.query.category[0];
    switch (category) {
      case 1:
        path = "/country-estate";
        break;
      case 3:
        path = "/country-estate";
        break;
      case 4:
        path = "/country-estate";
        break;
      case 5:
        path = "/country-estate";
        break;
      case 7:
        path = "/resale-estate";
        break;
      case 8:
        path = "/resale-estate";
        break;
      case 12:
        path = "/commerse-estate";
        break;
    }
    return path;
  },
};
export default getPathDependingByCategory;
