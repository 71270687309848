<template>
  <Transition
    name="fade"
    appear
  >
    <slot />
  </Transition>
</template>
<script>
export default {
  name: "TransitionFade",
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
