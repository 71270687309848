<template>
  <router-link
    to="/"
    class="logo"
  >
    <svg
      v-if="footer"
      class="logo__svg _footer"
      width="256"
      height="56"
      viewBox="0 0 256 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="logo__flag"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M195.541 0L256 26.7142L195.541 53.5965V0Z"
        fill="#050504"
      />
      <path
        class="logo__flag-letters"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M214.921 42.2408L209.355 44.6785L204.842 32.5401V46.7025L198.332 49.5932V3.9248L204.842 6.81553V19.5966L208.662 8.50947L214.773 11.1931L218.36 33.882L221.509 14.1621L235.551 20.3794V23.2253L227.41 19.6751L227.115 21.7606L235.551 25.5178V33.0546L224.488 38.0083L225.518 31.6736L231.02 29.1966L224.488 26.3453L222.539 38.8583L215.108 42.157L210.595 19.0767L208.366 25.294L214.921 42.2408Z"
        fill="white"
      />
      <path
        class="logo__flag-arrow"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M237.859 21.4307L249.826 26.7141L237.859 32.0034V21.4307Z"
        fill="#FF8635"
      />
      <path
        d="M3.34697 35.7158V42.6551H10.4608V35.7158H12.5575V52.1865H10.4608V44.4629H3.34697V52.1865H1.25024V35.7158H3.34697Z"
        fill="white"
      />
      <path
        d="M24.876 44.4399H19.0592V50.4523H25.5984V52.1865H16.9624V35.7158H25.2489V37.4501H19.0592V42.7287H24.876V44.4399Z"
        fill="white"
      />
      <path
        d="M39.643 35.7158V50.5006L41.0408 50.5259L40.8907 56H39.2184L39.0941 52.1865H29.459L29.3112 56H27.6882L27.5638 50.5259L28.76 50.5006C29.412 49.2668 29.9391 47.9734 30.3338 46.6388C30.8621 44.3877 31.097 42.0799 31.0327 39.7709V35.7158H39.643ZM32.9795 40.3597C33.0339 42.6044 32.7903 44.8466 32.2548 47.0297C31.922 48.2154 31.4792 49.3687 30.9319 50.4752H37.5461V37.4501H32.9795V40.3597Z"
        fill="white"
      />
      <path
        d="M44.4229 35.9343C45.6147 35.7025 46.8271 35.5884 48.042 35.5939C49.5874 35.4518 51.1324 35.869 52.3858 36.7668C52.8188 37.1195 53.1633 37.5652 53.3927 38.0693C53.6221 38.5735 53.7302 39.1226 53.7086 39.6742C53.6941 40.4862 53.4185 41.273 52.9211 41.9222C52.4237 42.5715 51.7299 43.0502 50.9387 43.2899V43.3635C51.9037 43.5578 52.769 44.0773 53.3842 44.8319C53.9995 45.5864 54.3259 46.5284 54.3068 47.4944C54.3253 48.1042 54.2127 48.711 53.9763 49.2753C53.7398 49.8396 53.3849 50.3488 52.9345 50.7696C51.4144 51.9581 49.4778 52.511 47.5447 52.3084C46.5016 52.3106 45.4593 52.2538 44.4229 52.1382L44.4229 35.9343ZM46.5198 42.7057H48.1686C48.5863 42.7581 49.0105 42.7246 49.4143 42.6075C49.8181 42.4904 50.1926 42.2922 50.514 42.0255C50.8354 41.7587 51.0967 41.4293 51.2813 41.0582C51.4658 40.6871 51.5696 40.2823 51.586 39.8697C51.586 38.0849 50.3382 37.181 48.1921 37.181C47.6308 37.1672 47.0697 37.2166 46.5198 37.3282V42.7057ZM46.5198 50.5971C47.0402 50.677 47.567 50.7101 48.0936 50.6961C50.2631 50.6961 52.1114 49.7921 52.1114 47.4691C52.1114 45.2219 50.2397 44.2444 48.042 44.2444H46.5198V50.5971Z"
        fill="white"
      />
      <path
        d="M59.788 35.7158V42.3377C59.788 45.0264 59.7388 47.1517 59.5887 49.4747L59.6403 49.4977C60.4119 47.7635 61.4111 45.8568 62.4853 43.9753L67.2021 35.7158H69.2472V52.1865H67.3005V45.5646C67.3005 42.6321 67.3263 40.6517 67.4998 38.5495L67.4248 38.5265C66.6251 40.4359 65.6917 42.2888 64.6313 44.0719L60.0132 52.1865H57.8413V35.7158H59.788Z"
        fill="white"
      />
      <path
        d="M74.7544 35.7158L79.4945 43.0713H80.0692V35.7158H82.091V43.0713H82.6657L87.4059 35.7158H89.8028L84.3379 43.3152C86.8335 43.6096 87.8069 45.3438 88.5293 47.591C89.0288 49.132 89.3783 50.6961 90.028 52.1865H87.9053C87.3996 50.9102 86.975 49.6043 86.6341 48.2764C85.9586 46.1742 85.2104 44.6101 82.689 44.6101H82.091V52.1865H80.0692V44.6101H79.4712C76.924 44.6101 76.1523 46.1742 75.4768 48.2764C75.1448 49.6041 74.728 50.9101 74.229 52.1865H72.1323C72.715 50.6905 73.2153 49.1649 73.631 47.6163C74.3534 45.3691 75.3268 43.6326 77.7989 43.3152L72.3574 35.7158H74.7544Z"
        fill="white"
      />
      <path
        d="M94.8292 35.7158V42.3377C94.8292 45.0264 94.7799 47.1517 94.6298 49.4747L94.6814 49.4977C95.4531 47.7635 96.4523 45.8568 97.5265 43.9753L102.243 35.7158H104.288V52.1865H102.342V45.5646C102.342 42.6321 102.367 40.6517 102.541 38.5495L102.466 38.5265C101.666 40.4359 100.733 42.2888 99.6725 44.0719L95.0543 52.1865H92.8824V35.7158H94.8292Z"
        fill="white"
      />
      <path
        d="M121.699 45.0011C121.574 42.754 121.424 40.0652 121.45 38.0849H121.375C120.85 39.9686 120.226 42.0203 119.503 44.1961L116.783 52.0899H115.286L112.765 44.3916C112.04 42.1169 111.442 40.0169 111.018 38.0849H110.943C110.893 40.1135 110.792 42.7287 110.642 45.1736L110.244 52.1865H108.273L109.395 35.7158H111.965L114.562 43.559C115.211 45.636 115.734 47.3978 116.159 49.1551H116.208C116.633 47.4208 117.158 45.6866 117.831 43.559L120.5 35.7158H123.097L124.119 52.1865H122.074L121.699 45.0011Z"
        fill="white"
      />
      <path
        d="M133.841 52.4303C129.873 52.4303 127.152 49.1803 127.152 44.0489C127.152 38.7703 130.049 35.4697 134.092 35.4697C138.159 35.4697 140.756 38.7956 140.756 43.8051C140.756 49.5967 137.66 52.4303 133.867 52.4303H133.841ZM133.966 50.7191C137.036 50.7191 138.558 47.4691 138.558 43.8787C138.558 40.6771 137.16 37.181 133.966 37.181C130.771 37.181 129.35 40.5782 129.35 44.0006C129.35 47.3978 130.898 50.7191 133.942 50.7191H133.966Z"
        fill="white"
      />
      <path
        d="M154.669 51.7219C153.464 52.2259 152.159 52.4601 150.85 52.4073C146.533 52.4073 143.361 49.5713 143.361 44.1225C143.361 38.5495 146.83 35.495 151.273 35.495C152.448 35.4424 153.619 35.6598 154.692 36.1298L154.193 37.8157C153.297 37.4036 152.314 37.2036 151.324 37.2316C148.029 37.2316 145.559 39.4788 145.559 44.0006C145.559 48.35 147.78 50.6478 151.249 50.6478C152.286 50.6621 153.315 50.4622 154.268 50.0613L154.669 51.7219Z"
        fill="white"
      />
      <path
        d="M160.875 37.4754H156.358V35.7158H167.487V37.4754H162.969V52.1865H160.875V37.4754Z"
        fill="white"
      />
      <path
        d="M172.145 35.7158V42.3377C172.145 45.0264 172.096 47.1517 171.946 49.4747L171.997 49.4977C172.769 47.7635 173.768 45.8568 174.842 43.9753L179.559 35.7158H181.604V52.1865H179.657V45.5646C179.657 42.6321 179.683 40.6517 179.857 38.5495L179.782 38.5265C178.982 40.4359 178.049 42.2888 176.988 44.0719L172.37 52.1865H170.198V35.7158H172.145Z"
        fill="white"
      />
      <path
        class="logo__letter"
        d="M6.98712 20.0318L5.45084 26.0165H0L6.87688 1.86597H13.7162L20.5181 26.0165H14.9242L13.2776 20.0318L6.98712 20.0318ZM12.5458 16.0918L11.23 10.9673C10.8642 9.53435 10.4607 7.52861 10.1324 6.05893H10.0597C9.72895 7.52861 9.36317 9.57111 8.99716 11.004L7.68137 16.0918H12.5458Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M37.9636 1.86597V6.20157H29.4425V26.0165H24.1019V1.86597H37.9636Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M54.6374 15.6617H46.5926V21.6809H55.5897V26.0165H41.252V1.86597H55.0761V6.20157H46.5926V11.3974H54.6374V15.6617Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M65.0254 1.86597V11.2893H72.9624V1.86597H78.3007V26.0165H72.9624V15.9125H65.0254V26.0165H59.6848V1.86597H65.0254Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M87.882 6.38097H81.9198V1.86597H99.2574V6.38099H93.2225V26.0166H87.882V6.38097Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M118.31 25.444C116.546 26.0937 114.669 26.3977 112.786 26.3387C104.924 26.3387 100.937 21.1429 100.937 14.2633C100.937 5.77152 106.678 1.54402 113.335 1.54402C115.139 1.48077 116.935 1.79846 118.603 2.47555L117.543 6.66858C116.302 6.12043 114.951 5.85087 113.591 5.87957C109.716 5.87957 106.568 8.42344 106.568 13.9781C106.568 19.1003 109.275 21.8971 113.628 21.8971C114.972 21.8969 116.307 21.6785 117.578 21.2508L118.31 25.444Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M126.244 6.38097H120.282V1.86597H137.62V6.38099H131.585V26.0166H126.244V6.38097Z"
        fill="#FF8635"
      />
      <path
        d="M140.873 2.19035C143.085 1.8345 145.325 1.66597 147.567 1.68664C149.976 1.48868 152.391 1.98766 154.514 3.12186C155.335 3.5994 156.011 4.28242 156.474 5.1009C156.937 5.91937 157.17 6.84385 157.148 7.77951C157.138 8.94476 156.75 10.0766 156.04 11.0109C155.329 11.9451 154.334 12.6332 153.198 12.9752V13.0812C154.561 13.3717 155.779 14.1158 156.645 15.187C157.512 16.2582 157.974 17.5906 157.952 18.9578C157.975 19.9185 157.776 20.8718 157.371 21.7467C156.966 22.6217 156.364 23.3956 155.612 24.0111C153.892 25.4784 151.078 26.2674 146.432 26.2674C144.572 26.3011 142.712 26.1811 140.873 25.9086V2.19035ZM146.176 11.5401H147.75C150.346 11.5401 151.81 10.252 151.81 8.42349C151.81 6.59725 150.564 5.48632 148.223 5.48632C147.536 5.46121 146.848 5.52149 146.176 5.66572V11.5401ZM146.176 22.2537C146.767 22.3531 147.368 22.3894 147.968 22.3618C150.346 22.3618 152.356 21.3222 152.356 18.8151C152.356 16.3771 150.309 15.303 147.822 15.303H146.176V22.2537Z"
        fill="#FF8635"
      />
      <path
        d="M171.376 26.3754C164.609 26.3754 160.915 20.8576 160.915 14.0494C160.915 6.99055 165.048 1.50952 171.741 1.50952C178.836 1.50952 182.237 7.31256 182.237 13.6561C182.237 21.9317 177.811 26.3754 171.411 26.3754H171.376ZM171.631 22.1111C174.887 22.1111 176.606 18.5989 176.606 13.8332C176.606 9.53442 174.959 5.77152 171.594 5.77152C168.303 5.77152 166.511 9.46312 166.546 13.9413C166.511 18.5644 168.341 22.1111 171.594 22.1111H171.631Z"
        fill="#FF8635"
      />
    </svg>

    <svg
      v-else
      class="logo__svg"
      width="220"
      height="48"
      viewBox="0 0 256 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="logo__flag"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M195.541 0L256 26.7142L195.541 53.5965V0Z"
        fill="#050504"
      />
      <path
        class="logo__flag-letters"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M214.921 42.2408L209.355 44.6785L204.842 32.5401V46.7025L198.332 49.5932V3.9248L204.842 6.81553V19.5966L208.662 8.50947L214.773 11.1931L218.36 33.882L221.509 14.1621L235.551 20.3794V23.2253L227.41 19.6751L227.115 21.7606L235.551 25.5178V33.0546L224.488 38.0083L225.518 31.6736L231.02 29.1966L224.488 26.3453L222.539 38.8583L215.108 42.157L210.595 19.0767L208.366 25.294L214.921 42.2408Z"
        fill="white"
      />
      <path
        class="logo__flag-arrow"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M237.859 21.4307L249.826 26.7141L237.859 32.0034V21.4307Z"
        fill="#FF8635"
      />
      <path
        d="M3.34697 35.7158V42.6551H10.4608V35.7158H12.5575V52.1865H10.4608V44.4629H3.34697V52.1865H1.25024V35.7158H3.34697Z"
        fill="white"
      />
      <path
        d="M24.876 44.4399H19.0592V50.4523H25.5984V52.1865H16.9624V35.7158H25.2489V37.4501H19.0592V42.7287H24.876V44.4399Z"
        fill="white"
      />
      <path
        d="M39.643 35.7158V50.5006L41.0408 50.5259L40.8907 56H39.2184L39.0941 52.1865H29.459L29.3112 56H27.6882L27.5638 50.5259L28.76 50.5006C29.412 49.2668 29.9391 47.9734 30.3338 46.6388C30.8621 44.3877 31.097 42.0799 31.0327 39.7709V35.7158H39.643ZM32.9795 40.3597C33.0339 42.6044 32.7903 44.8466 32.2548 47.0297C31.922 48.2154 31.4792 49.3687 30.9319 50.4752H37.5461V37.4501H32.9795V40.3597Z"
        fill="white"
      />
      <path
        d="M44.4229 35.9343C45.6147 35.7025 46.8271 35.5884 48.042 35.5939C49.5874 35.4518 51.1324 35.869 52.3858 36.7668C52.8188 37.1195 53.1633 37.5652 53.3927 38.0693C53.6221 38.5735 53.7302 39.1226 53.7086 39.6742C53.6941 40.4862 53.4185 41.273 52.9211 41.9222C52.4237 42.5715 51.7299 43.0502 50.9387 43.2899V43.3635C51.9037 43.5578 52.769 44.0773 53.3842 44.8319C53.9995 45.5864 54.3259 46.5284 54.3068 47.4944C54.3253 48.1042 54.2127 48.711 53.9763 49.2753C53.7398 49.8396 53.3849 50.3488 52.9345 50.7696C51.4144 51.9581 49.4778 52.511 47.5447 52.3084C46.5016 52.3106 45.4593 52.2538 44.4229 52.1382L44.4229 35.9343ZM46.5198 42.7057H48.1686C48.5863 42.7581 49.0105 42.7246 49.4143 42.6075C49.8181 42.4904 50.1926 42.2922 50.514 42.0255C50.8354 41.7587 51.0967 41.4293 51.2813 41.0582C51.4658 40.6871 51.5696 40.2823 51.586 39.8697C51.586 38.0849 50.3382 37.181 48.1921 37.181C47.6308 37.1672 47.0697 37.2166 46.5198 37.3282V42.7057ZM46.5198 50.5971C47.0402 50.677 47.567 50.7101 48.0936 50.6961C50.2631 50.6961 52.1114 49.7921 52.1114 47.4691C52.1114 45.2219 50.2397 44.2444 48.042 44.2444H46.5198V50.5971Z"
        fill="white"
      />
      <path
        d="M59.788 35.7158V42.3377C59.788 45.0264 59.7388 47.1517 59.5887 49.4747L59.6403 49.4977C60.4119 47.7635 61.4111 45.8568 62.4853 43.9753L67.2021 35.7158H69.2472V52.1865H67.3005V45.5646C67.3005 42.6321 67.3263 40.6517 67.4998 38.5495L67.4248 38.5265C66.6251 40.4359 65.6917 42.2888 64.6313 44.0719L60.0132 52.1865H57.8413V35.7158H59.788Z"
        fill="white"
      />
      <path
        d="M74.7544 35.7158L79.4945 43.0713H80.0692V35.7158H82.091V43.0713H82.6657L87.4059 35.7158H89.8028L84.3379 43.3152C86.8335 43.6096 87.8069 45.3438 88.5293 47.591C89.0288 49.132 89.3783 50.6961 90.028 52.1865H87.9053C87.3996 50.9102 86.975 49.6043 86.6341 48.2764C85.9586 46.1742 85.2104 44.6101 82.689 44.6101H82.091V52.1865H80.0692V44.6101H79.4712C76.924 44.6101 76.1523 46.1742 75.4768 48.2764C75.1448 49.6041 74.728 50.9101 74.229 52.1865H72.1323C72.715 50.6905 73.2153 49.1649 73.631 47.6163C74.3534 45.3691 75.3268 43.6326 77.7989 43.3152L72.3574 35.7158H74.7544Z"
        fill="white"
      />
      <path
        d="M94.8292 35.7158V42.3377C94.8292 45.0264 94.7799 47.1517 94.6298 49.4747L94.6814 49.4977C95.4531 47.7635 96.4523 45.8568 97.5265 43.9753L102.243 35.7158H104.288V52.1865H102.342V45.5646C102.342 42.6321 102.367 40.6517 102.541 38.5495L102.466 38.5265C101.666 40.4359 100.733 42.2888 99.6725 44.0719L95.0543 52.1865H92.8824V35.7158H94.8292Z"
        fill="white"
      />
      <path
        d="M121.699 45.0011C121.574 42.754 121.424 40.0652 121.45 38.0849H121.375C120.85 39.9686 120.226 42.0203 119.503 44.1961L116.783 52.0899H115.286L112.765 44.3916C112.04 42.1169 111.442 40.0169 111.018 38.0849H110.943C110.893 40.1135 110.792 42.7287 110.642 45.1736L110.244 52.1865H108.273L109.395 35.7158H111.965L114.562 43.559C115.211 45.636 115.734 47.3978 116.159 49.1551H116.208C116.633 47.4208 117.158 45.6866 117.831 43.559L120.5 35.7158H123.097L124.119 52.1865H122.074L121.699 45.0011Z"
        fill="white"
      />
      <path
        d="M133.841 52.4303C129.873 52.4303 127.152 49.1803 127.152 44.0489C127.152 38.7703 130.049 35.4697 134.092 35.4697C138.159 35.4697 140.756 38.7956 140.756 43.8051C140.756 49.5967 137.66 52.4303 133.867 52.4303H133.841ZM133.966 50.7191C137.036 50.7191 138.558 47.4691 138.558 43.8787C138.558 40.6771 137.16 37.181 133.966 37.181C130.771 37.181 129.35 40.5782 129.35 44.0006C129.35 47.3978 130.898 50.7191 133.942 50.7191H133.966Z"
        fill="white"
      />
      <path
        d="M154.669 51.7219C153.464 52.2259 152.159 52.4601 150.85 52.4073C146.533 52.4073 143.361 49.5713 143.361 44.1225C143.361 38.5495 146.83 35.495 151.273 35.495C152.448 35.4424 153.619 35.6598 154.692 36.1298L154.193 37.8157C153.297 37.4036 152.314 37.2036 151.324 37.2316C148.029 37.2316 145.559 39.4788 145.559 44.0006C145.559 48.35 147.78 50.6478 151.249 50.6478C152.286 50.6621 153.315 50.4622 154.268 50.0613L154.669 51.7219Z"
        fill="white"
      />
      <path
        d="M160.875 37.4754H156.358V35.7158H167.487V37.4754H162.969V52.1865H160.875V37.4754Z"
        fill="white"
      />
      <path
        d="M172.145 35.7158V42.3377C172.145 45.0264 172.096 47.1517 171.946 49.4747L171.997 49.4977C172.769 47.7635 173.768 45.8568 174.842 43.9753L179.559 35.7158H181.604V52.1865H179.657V45.5646C179.657 42.6321 179.683 40.6517 179.857 38.5495L179.782 38.5265C178.982 40.4359 178.049 42.2888 176.988 44.0719L172.37 52.1865H170.198V35.7158H172.145Z"
        fill="white"
      />
      <path
        class="logo__letter"
        d="M6.98712 20.0318L5.45084 26.0165H0L6.87688 1.86597H13.7162L20.5181 26.0165H14.9242L13.2776 20.0318L6.98712 20.0318ZM12.5458 16.0918L11.23 10.9673C10.8642 9.53435 10.4607 7.52861 10.1324 6.05893H10.0597C9.72895 7.52861 9.36317 9.57111 8.99716 11.004L7.68137 16.0918H12.5458Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M37.9636 1.86597V6.20157H29.4425V26.0165H24.1019V1.86597H37.9636Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M54.6374 15.6617H46.5926V21.6809H55.5897V26.0165H41.252V1.86597H55.0761V6.20157H46.5926V11.3974H54.6374V15.6617Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M65.0254 1.86597V11.2893H72.9624V1.86597H78.3007V26.0165H72.9624V15.9125H65.0254V26.0165H59.6848V1.86597H65.0254Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M87.882 6.38097H81.9198V1.86597H99.2574V6.38099H93.2225V26.0166H87.882V6.38097Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M118.31 25.444C116.546 26.0937 114.669 26.3977 112.786 26.3387C104.924 26.3387 100.937 21.1429 100.937 14.2633C100.937 5.77152 106.678 1.54402 113.335 1.54402C115.139 1.48077 116.935 1.79846 118.603 2.47555L117.543 6.66858C116.302 6.12043 114.951 5.85087 113.591 5.87957C109.716 5.87957 106.568 8.42344 106.568 13.9781C106.568 19.1003 109.275 21.8971 113.628 21.8971C114.972 21.8969 116.307 21.6785 117.578 21.2508L118.31 25.444Z"
        fill="#FF8635"
      />
      <path
        class="logo__letter"
        d="M126.244 6.38097H120.282V1.86597H137.62V6.38099H131.585V26.0166H126.244V6.38097Z"
        fill="#FF8635"
      />
      <path
        d="M140.873 2.19035C143.085 1.8345 145.325 1.66597 147.567 1.68664C149.976 1.48868 152.391 1.98766 154.514 3.12186C155.335 3.5994 156.011 4.28242 156.474 5.1009C156.937 5.91937 157.17 6.84385 157.148 7.77951C157.138 8.94476 156.75 10.0766 156.04 11.0109C155.329 11.9451 154.334 12.6332 153.198 12.9752V13.0812C154.561 13.3717 155.779 14.1158 156.645 15.187C157.512 16.2582 157.974 17.5906 157.952 18.9578C157.975 19.9185 157.776 20.8718 157.371 21.7467C156.966 22.6217 156.364 23.3956 155.612 24.0111C153.892 25.4784 151.078 26.2674 146.432 26.2674C144.572 26.3011 142.712 26.1811 140.873 25.9086V2.19035ZM146.176 11.5401H147.75C150.346 11.5401 151.81 10.252 151.81 8.42349C151.81 6.59725 150.564 5.48632 148.223 5.48632C147.536 5.46121 146.848 5.52149 146.176 5.66572V11.5401ZM146.176 22.2537C146.767 22.3531 147.368 22.3894 147.968 22.3618C150.346 22.3618 152.356 21.3222 152.356 18.8151C152.356 16.3771 150.309 15.303 147.822 15.303H146.176V22.2537Z"
        fill="#FF8635"
      />
      <path
        d="M171.376 26.3754C164.609 26.3754 160.915 20.8576 160.915 14.0494C160.915 6.99055 165.048 1.50952 171.741 1.50952C178.836 1.50952 182.237 7.31256 182.237 13.6561C182.237 21.9317 177.811 26.3754 171.411 26.3754H171.376ZM171.631 22.1111C174.887 22.1111 176.606 18.5989 176.606 13.8332C176.606 9.53442 174.959 5.77152 171.594 5.77152C168.303 5.77152 166.511 9.46312 166.546 13.9413C166.511 18.5644 168.341 22.1111 171.594 22.1111H171.631Z"
        fill="#FF8635"
      />
    </svg>
  </router-link>
</template>
<script>
export default {
  name: "AppLogo",

  props: {
    footer: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.logo {
  $that: &;

  &__svg {
    overflow: visible;
    color: #ff8635;

    &:not(._footer) {
      width: calc(220 / 1920 * 100vw);
      min-width: 162px;
      min-height: 36px;

      @media (min-width: 1920px) {
        width: 220px;
      }

      @include tablet {
        width: calc(162 / 1024 * 100vw);
        height: calc(36 / 1024 * 100vw);
        min-width: 152px;
        min-height: 32px;
      }

      @include phone {
        width: 152px;
        height: 32px;
      }
    }

    &._footer {
      @include laptop {
        width: calc(256 / 1280 * 100vw);
        min-width: 162px;
        min-height: 36px;
      }
    }
  }

  &__flag {
    transition: transform 0.25s 0.1s ease-in-out;

    // #{$that}:hover & {
    //   transform: translate3d(6px, 0, 0);
    // }
  }

  &__flag-letters {
    transition: transform 0.25s 0.05s ease-in-out;

    // #{$that}:hover & {
    //   transform: translate3d(6px, 0, 0);
    // }
  }

  &__flag-arrow {
    transition: transform 0.25s ease-in;

    // #{$that}:hover & {
    //   transform: translate3d(6px, 0, 0);
    // }
  }
}
</style>
