import { defineStore } from 'pinia'
import api from '@/plugins/api';
import { notify } from "@kyvg/vue3-notification";

export const useUserStore = defineStore('user', {
  state: () => ({
    auth: false,
    data: {},
  }),
  getters: {
    getFullName: (state) => `${state.data.name} ${state.data.family}`,
    getUserId: (state) => state.data.id,
  },
  actions: {
    logout() {
      api.defaults.headers['sessionid'] = null;
      localStorage.sessionId = null;

      this.data = {};
      this.auth = false;

      notify({
        title: 'Успех',
        text: 'Вы успешно вышли с аккаунта',
        type: 'success',
      });
    },
  },
});
