<template>
  <form
    action="#"
    class="modal-auth__form"
    @submit.prevent="send"
  >
    <div class="modal-auth__form-grid">
      <form-input
        v-model="data.phone"
        label="Телефон"
        type="tel"
      />
      <form-input
        v-model="data.password"
        label="Пароль"
        type="password"
      />
    </div>

    <app-button
      type="submit"
      :sending="loading"
    >
      Войти
    </app-button>
  </form>
</template>

<script>
import { ref, reactive } from 'vue';
import { useNotification } from "@kyvg/vue3-notification";

import api from '@/plugins/api';

import { useUserStore } from '@/stores/user';

import FormInput from '@/components/form/FormInput.vue';
import AppButton from '@/components/ui/AppButton.vue';

export default {
  components: {
    FormInput,
    AppButton,
  },
  emits: ['success'],
  setup(props, { emit }) {
    const loading = ref(false);
    const userStore = useUserStore();
    const { notify } = useNotification();

    const data = reactive({
      phone: '',
      password: '',
    });

    const send = () => {
      loading.value = true;

      api.post('/api/clientAuthorize', data)
        .then(({ data }) => {
          if (data.error) {
            notify({
              title: 'Ошибка!',
              text: data.error,
              type: 'error',
            });
          } else {
            api.defaults.headers['sessionid'] = data.client.sessionid;
            localStorage.sessionId = data.client.sessionid;

            userStore.data = data.client;
            userStore.auth = true;

            notify({
              title: 'Успех',
              text: 'Вы успешно авторизовались!',
              type: 'success',
            });

            emit('success');
          }
        })
        .catch(() => {})
        .finally(() => {
          loading.value = false;
        });
    };

    return { loading, data, send };
  },
};
</script>
