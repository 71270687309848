import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export const useFavoritesStore = defineStore({
  id: "favState",
  state: () => ({
    favorites: useStorage("favorites", []),
    history: useStorage("history", []),
    isShowHistory: useStorage("isShowHistory", false),
  }),
  getters: {
    getAllFavorites() {
      return this.favorites;
    },
    getCount() {
      return this.favorites.length;
    },
    getCountNotNull() {
      const notEmpty = this.favorites.length !== 0;
      return notEmpty ? this.length : "";
    },
    getIsLiked: (state) => (alias) => !!state.favorites.find((el) => el.alias === alias),
  },
  actions: {
    addFavorites(alias) {
      const findIndex = this.favorites.findIndex((el) => el.alias === alias);
      if (findIndex !== -1) {
        this.favorites = this.favorites.filter((el, i) => i !== findIndex);
      } else {
        this.favorites.push({
          whenAdd: new Date(),
          alias,
        });
      }
    },
    addToHistory(newHistEl) {
      const history = [...this.history];
      const histAliases = history.map((histEl) => histEl.alias);
      let newHist = [];
      newHist = [...history];
      const index = histAliases.findIndex((el) => el === newHistEl.alias);
      if (index === -1) {
        newHist.unshift(newHistEl);
      } else {
        newHist.splice(index, 1);
        newHist.unshift(newHistEl);
      }
      newHist.splice(8, newHist.length);
      this.history = newHist;
      this.isShowHistory = true;
    },
  },
});
