import axios from "axios";
import { saveOnlySelectedParams, parse, setNullNumber } from "@/plugins/helpers.js";

const refactorActions = {
  getParams(notParsedQuery) {
    let query = parse(notParsedQuery);
    const queryEmpty = Object.keys(query).length === 0;
    queryEmpty ? (query = this.addRequiredParams(query)) : null;
    query.limit = 10;
    return query;
  },
  addRequiredParams(query) {
    query.page = this.query.page;
    query.isZhk = this.query.isZhk;
    query.type = this.query.type;
    query.category = this.query.category;
    return query;
  },
  returnRequiredParams(query) {
    query.page = JSON.stringify(this.query.page);
    query.isZhk = JSON.stringify(this.query.isZhk);
    query.type = JSON.stringify(this.query.type);
    query.category = JSON.stringify(this.query.category);
    return query;
  },
  async load(route, prop) {
    const params = this.getParams(route.query);

    if (prop === "WITH_CITY") {
      params.city = this.query.currentCity;
    }
    if (prop === "FOR_MAP") {
      delete params.page;
      delete params.limit;
    }
    delete params.map;

    const res = await axios.get("/api/getOffersList", {
      params: params,
    });

    if (params.hasOwnProperty("rooms")) {
      prop = "SET_PRICE_RANGE";
    }

    if (prop === "SET_PRICE_RANGE") {
      this.query[`minPriceValue`] = setNullNumber(res.data.filters.price.min);
      this.query[`maxPriceValue`] = setNullNumber(res.data.filters.price.max);
    }
    return res;
  },
  async getRangeForCategory(route) {
    const params = this.getParams(route.query);
    const requiredParams = saveOnlySelectedParams(["type", "isZhk", "category", "page", "limit"], params);
    const res = await axios.get("/api/getOffersList", {
      params: requiredParams,
    });
    this.setInitState();
    this.setRangesAndValues(res.data, route);
  },
  setInitState() {
    this.setClearRoomsNewFlatIsSNTIsDNPIsIZHS();
    this.setClearFurnish();
    this.setMainCategoriesList();
    this.setCategoryListDependingByCategory();

    if (this.query.category) {
      this.setCategoryState(this.query.category[0]);
    }

    this.setListsOfferDealinesPurposesZhkList();
  },
  async getOffersFilters(route, prop) {
    const res = await axios.get("/api/getOffersFilters");
    this.query.options.filtersList = res.data;
    this.query.offerCategories = res.data.offerCategories;
    if (prop !== "NOT_SET_ESTATE_TYPE") {
      this.setCurrentEstateType(route.path);
    }
  },
};

export default refactorActions;
