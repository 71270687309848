<template>
  <div class="review-card">
    <div class="review-card__inner">
      <div class="review-card__head">
        <div class="review-card__user">
          <div class="review-card__user-avatar">
            {{ firstLetter }}
          </div>
          <div class="review-card__user-name">
            <span
              ref="userName"
              v-clamp="2"
            > {{ review.name }}</span>
          </div>
        </div>
        <div class="review-card__info">
          <div
            v-if="review.created_at"
            v-date
            class="review-card__info-date"
          >
            {{ review.created_at }}
          </div>
          <StarRating
            :stars="review.rating"
            class="review-card__star-rating"
            :is-small="true"
          />
        </div>
      </div>

      <div class="review-card__body">
        <div
          v-clamp="7"
          class="review-card__text"
          v-html="review.message"
        />
        <span
          class="review-card__read-more _read-more"
          @click="isOpenFullReview = true"
        >Читать полностью</span>
      </div>
    </div>
    <teleport
      v-if="isOpenFullReview"
      to="body"
    >
      <AppModal
        v-model="isOpenFullReview"
        class="review-card__full-review"
      >
        <div class="review-card__inner">
          <div class="review-card__head">
            <div class="review-card__user">
              <div class="review-card__user-avatar">
                {{ firstLetter }}
              </div>
              <div class="review-card__user-name">
                <span
                  ref="userName"
                  v-clamp="2"
                >{{ review.name }}</span>
              </div>
            </div>
            <div class="review-card__info">
              <div
                v-if="review.created_at"
                v-date
                class="review-card__info-date"
              >
                {{ review.created_at }}
              </div>
              <StarRating
                class="review-card__star-rating"
                :is-small="true"
                :stars="review.rating"
              />
            </div>
          </div>

          <div class="review-card__body">
            <div class="review-card__modal-text">
              {{ review.message }}
            </div>
          </div>
        </div>
      </AppModal>
    </teleport>
  </div>
</template>

<script>
import StarRating from "./ui/StarRating.vue";
import AppModal from "./ui/AppModal.vue";

export default {
  name: "ReviewsSectionCard",
  components: { StarRating, AppModal },

  props: {
    review: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      firstLetter: "",
      isOpenFullReview: false,
    };
  },

  mounted() {
    this.getFirstLetter();
  },

  methods: {
    getFirstLetter() {
      const userName = this.$refs.userName.innerHTML.trim();
      this.firstLetter = userName.substring(0, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.review-card {
  $that: &;

  box-shadow: 0 10px 100px rgba(35, 41, 48, 0.05);
  min-height: 375px;

  @include desktop {
    min-height: 285px;
  }

  &__inner {
    max-width: 776px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px calc(40 / 1920 * 100vw);
    border-bottom: 1px solid $color_grey-10;

    @media (min-width: 1920px) {
      padding: 16px 40px;
    }

    @include desktop {
      flex-direction: column-reverse;
      padding: 10px 16px;
    }

    #{$that}__full-review & {
      flex-direction: column;
    }
  }

  &__user {
    display: flex;

    @include desktop {
      align-self: flex-start;
    }

    #{$that}__full-review & {
      align-self: flex-start;
    }
  }

  &__user-avatar {
    flex: none;
    margin-right: 16px;
    background-color: $color_grey-15;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: $white;
    font-size: 40px;
    line-height: 56px;

    @include flex-center;

    @include desktop {
      width: 40px;
      height: 40px;
      font-size: 26px;
      line-height: 36px;
      margin-right: 8px;
    }
  }

  &__user-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    @include flex-center;

    @include desktop {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__info {
    max-width: 172px;
    flex: none;
    padding-left: 22px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include desktop {
      padding-left: 0;
      min-width: 100%;
      flex-direction: row-reverse;
      align-items: center;
      min-height: auto;
      padding-bottom: 8px;
    }

    #{$that}__full-review & {
      flex-direction: row-reverse;
      align-items: center;
      min-width: 100%;
      padding-left: 0;

      @include desktop {
        padding-top: 8px;
        padding-bottom: 0;
      }
    }
  }

  &__info-date {
    font-family: "Montserrat", sans-serif;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  &__star-rating {
  }

  &__body {
    padding: 30px 40px 60px;
    text-align: right;

    @include desktop {
      padding: 20px 16px 24px;
    }
  }

  &__text {
    text-align: left;
    line-height: 24px;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @include desktop {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__modal-text {
    text-align: left;
    line-height: 24px;
    overflow: hidden;

    @include desktop {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
