<template>
  <div class="burger-menu">
    <BurgerMenuIcon
      :is-clicked="isClicked"
      @click="onClick"
    />
    <BurgerMenuNav
      :burger-list="burgerList"
      :class="{ '_is-open': isClicked }"
      @link-clicked="isClicked = false"
    />
  </div>
</template>

<script>
import BurgerMenuIcon from "./BurgerMenuIcon.vue";
import BurgerMenuNav from "./BurgerMenuNav.vue";

export default {
  name: "BurgerMenu",
  components: { BurgerMenuIcon, BurgerMenuNav },

  props: {
    burgerList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isClicked: false,
    };
  },

  mounted() {
    this.closeMenu();
  },

  methods: {
    onClick(event) {
      event.currentTarget.classList.toggle("_is-clicked");
      this.isClicked = !this.isClicked;
    },

    closeMenu() {
      window.addEventListener("click", (event) => {
        if (!event.target.closest(".burger-menu")) {
          this.isClicked = false;
        }
      });

      window.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          this.isClicked = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.burger-menu {
  @include tablet {
    display: none;
  }
}
</style>
