import router from "@/router/index.js";
import { useFilterStore } from "@/store/filters/filterStore.js";

export function getQuery(context, routeQuery) {
  return Object.assign({}, routeQuery);
}

function stringify(query, obj) {
  for (const prop in obj) {
    query[prop] = JSON.stringify(obj[prop]);
  }
  return query;
}

export async function setParamsInUrl(context, routeQuery, obj, prop) {
  const store = useFilterStore();
  store.saveScrollTo();
  const query = context.returnRequiredParams(getQuery(context, routeQuery));
  let stringifyedQuery = stringify(query, obj);
  if (prop === "ONLY_FOR_CATEGORY_SET") {
    stringifyedQuery = saveOnlySelectedParams(["type", "isZhk", "category", "page", "limit"], stringifyedQuery);
  }
  const objClear = store.checkClearRanges(stringifyedQuery);
  await router.push({ query: objClear });
}

export function parse(query) {
  const result = {};
  for (const prop in query) {
    try {
      result[prop] = JSON.parse(query[prop]);
    } catch (e) {
      result[prop] = query[prop];
    }
  }
  return result;
}

export function saveOnlySelectedParams(savedParams, query) {
  const result = {};
  for (const prop in query) {
    if (savedParams.includes(prop)) {
      result[prop] = query[prop];
    }
  }
  return result;
}

export const queryIsEmpty = (route) => Object.entries(route.query).length === 0;

export const setNullNumber = (payload) => (payload ? payload : 0);

export const wait = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};
